import React from "react";
import noPrescriptionImg from "../../../assets/images/nodata/prescription-nodata.png";
import Tables from "../general/Tables";
// const data = {
//   heading: ["S/N", "ECN No.", "Provider", "Drug ", "Drug Dosage", "Cost"],
//   list: [
//     {
//       num: "1",
//       ecn: "299282",
//       provider: "ST LUKES",
//       drug: "Artesunate",
//       dd: "3 times daily",
//       cost: "₦1,980",
//     },
//   ],
// };
const data = null;
function PrescriptionTable() {
  return (
    <div className="prescription-table">
      {data ? (
        <Tables data={data} type="prescriptions" />
      ) : (
        <div className="no-data">
          <img src={noPrescriptionImg} alt="" style={{ width: "20%" }} />
          <h1 className="no-data-text">No Prescription</h1>
        </div>
      )}
    </div>
  );
}

export default PrescriptionTable;
