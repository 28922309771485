import { useState } from "react";
const UsersDetailsCard = ({ type }) => {
  const [edit1, setEdit1] = useState(true);
  const [edit, setEdit] = useState(true);
  const handleUpdate = (e) => {
    e.preventDefault();
    setEdit1(!edit1);
  };
  const handleUpdateInfo = (e) => {
    e.preventDefault();
    setEdit(!edit);
  };
  return (
    <form className="userdetails-card">
      <div className="userdetails-Col">
        <div className="userdetail">
          <label className="userdetails-heading">First Name</label>
          <input
            type="text"
            disabled
            value="Mary"
            className={edit ? "userdetails-text disabled" : "userdetails-text"}
          />
        </div>
        {type !== "provider" && (
          <div className="userdetail">
            <label className="userdetails-heading">Last Name</label>
            <input
              type="text"
              disabled
              value="John"
              className={
                edit ? "userdetails-text disabled" : "userdetails-text"
              }
            />
          </div>
        )}
      </div>
      <div className="userdetails-Col">
        <div className="userdetail">
          <label className="userdetails-heading">Sex</label>
          <input
            type="text"
            disabled={edit ? true : false}
            value="Male"
            className={edit ? "userdetails-text disabled" : "userdetails-text"}
          />
        </div>
        <div className="userdetail">
          <label className="userdetails-heading">Date of Birth</label>
          <input
            type="text"
            disabled
            value="01/03/1990"
            className={edit ? "userdetails-text disabled" : "userdetails-text"}
          />
        </div>
      </div>
      <div className="userdetails-Col">
        <div className="userdetail">
          <label className="userdetails-heading">Address 1</label>
          <input
            type="text"
            disabled
            value="First address, Lekki"
            className={edit ? "userdetails-text disabled" : "userdetails-text"}
          />
        </div>
        <div className="userdetail">
          <label className="userdetails-heading">Address 2</label>
          <input
            type="text"
            disabled
            value="Second address, VI"
            className={edit ? "userdetails-text disabled" : "userdetails-text"}
          />
        </div>
        <div className="userdetail">
          <label className="userdetails-heading">State</label>
          <input
            type="text"
            disabled
            value="First address, Lekki"
            className={edit ? "userdetails-text disabled" : "userdetails-text"}
          />
        </div>
      </div>
      <button className="btn-update" onClick={handleUpdateInfo}>
        Update
      </button>
      {type === "subscriber" && (
        <>
          <div className="horizontal-line"></div>
          <h3 className="header-text">Emergency Contact</h3>
          <div className="userdetails-Col">
            <div className="userdetail">
              <label className="userdetails-heading">Primary Provider</label>
              <input
                type="text"
                disabled
                value="Lekki"
                className={
                  edit1 ? "userdetails-text disabled" : "userdetails-text"
                }
              />
            </div>
            <div className="userdetail">
              <label className="userdetails-heading">Name</label>
              <input
                type="text"
                disabled
                value="Lekki"
                className={
                  edit1 ? "userdetails-text disabled" : "userdetails-text"
                }
              />
            </div>
            <div className="userdetail">
              <label className="userdetails-heading">Phone</label>
              <input
                type="tel"
                disabled
                value="09045667878"
                className={
                  edit1 ? "userdetails-text disabled" : "userdetails-text"
                }
              />
            </div>
          </div>
          <button className="btn-update" onClick={handleUpdate}>
            Update
          </button>
        </>
      )}
    </form>
  );
};

export default UsersDetailsCard;
