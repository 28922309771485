import "./admin_dashboard.scss";
import React, { useState } from "react";
import logo from "../../assets/images/logo1.svg";
import { BiSupport, BiLogOut } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import { TbFirstAidKit } from "react-icons/tb";
import { RiHomeLine } from "react-icons/ri";
import { ReactComponent as Subscribe } from "../../assets/images/dashboard/subscribe.svg";
import { HiOutlineUserGroup } from "react-icons/hi";
import { useDispatch } from "react-redux";
import DashboardAdminHeader from "../../components/admin/AdminSubscription/DashboardAdminHeader";
import DashboardAdminComponent from "../../components/admin/AdminHome/DashboardAdminComponent";
import DashboardHeaderTop from "../../components/subscriber/general/DashboardHeaderTop";
import Claims from "../../components/admin/AdminClaim/Claim";
import AdminSubscription from "../../components/admin/AdminSubscription/AdminSubscription";
import AdminProviders from "../../components/admin/AdminProvider/AdminProviders";
import AdminSettings from "../../components/admin/AdminSetting/AdminSettings";
import AdminSalsesRep from "../../components/admin/AdminSalesReps/AdminSalsesRep";
import { logout } from "../../redux/authentication/reducer";
const data = [
  {
    name: "Dashboard",
    icon: RiHomeLine,
  },
  {
    name: "Subscribers",
    icon: TbFirstAidKit,
  },
  {
    name: "Providers",
    icon: HiOutlineUserGroup,
  },
  {
    name: "Claims",
    icon: Subscribe,
  },
  {
    name: "Sales Rep",
    icon: BiSupport,
  },
  {
    name: "Setting",
    icon: FiSettings,
  },
];

function AdminDashboard() {
  const [toggle, setToggle] = useState("Dashboard");
  const dispatch = useDispatch();
  function handleClick(e, i) {
    const text = e.target.innerText;
    setToggle(text);
  }

  const handlLogout = () => {
    dispatch(logout());
  };
  return (
    <div className="dashboard-container">
      <div className="dashboard-left">
        <img src={logo} alt="" />
        {data.map((item, i) => (
          <button
            key={i}
            className={
              toggle === item.name
                ? "dashboard-items dashboard-focus"
                : "dashboard-items"
            }
            onClick={(e) => handleClick(e, i)}
          >
            <item.icon className="icon" />
            <span>{item.name}</span>
          </button>
        ))}
        <button className="btn-logout" onClick={handlLogout}>
          <span>Log Out </span>
          <BiLogOut />
        </button>
      </div>
      <div className="dashboard-right">
        <DashboardHeaderTop />
        {toggle !== "Dashboard" ? <></> : <DashboardAdminHeader />}
        {toggle === "Dashboard" && <DashboardAdminComponent />}
        {toggle === "Claims" && <Claims />}
        {toggle === "Subscribers" && <AdminSubscription />}
        {toggle === "Providers" && <AdminProviders />}
        {toggle === "Sales Rep" && <AdminSalsesRep />}
        {toggle === "Setting" && <AdminSettings />}
      </div>
    </div>
  );
}

export default AdminDashboard;
