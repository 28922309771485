import { useEffect, useState } from "react";
import { MdKeyboardBackspace } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import {
  getProvidersList,
  updateSubscriberProvider,
  getSubscription,
  getGroupMembers,
} from "../../../../redux/subscriber/reducer";
export const states = [
  "Select state",
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
  "Abuja (FCT)",
];
function HealthProvider({ show, groupmemberId }) {
  const dispatch = useDispatch();
  const state = useRef();
  const [facility, setProvider] = useState("");
  useEffect(() => {
    dispatch(getProvidersList());
  }, [dispatch]);
  const { facilities, subscription, group } = useSelector(
    (state) => state.subscriber
  );
  const [filteredData, setFilteredData] = useState(facilities);
  const subscriberId = window.localStorage.getItem("subscriberId");
  const handleChange = (e) => {
    const { value } = e.target;
    setProvider(value);
  };
  const handleFilter = (e) => {
    if (!state?.current.value) {
      return setFilteredData(facilities);
    } else {
      const filterData = facilities?.filter((item) =>
        item.state.toLowerCase().includes(state?.current.value.toLowerCase())
      );
      setFilteredData(filterData);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateSubscriberProvider({
        payload: { providerName: facility },
        subscriberId: groupmemberId ? groupmemberId : subscriberId,
      })
    );
    setProvider("");
    groupmemberId && dispatch(getGroupMembers());
    dispatch(getSubscription(subscriberId));
  };
  return (
    <div className="personal">
      <div className="mobile" onClick={() => show(true)}>
        <span>
          <MdKeyboardBackspace size="24px" />
        </span>
        <h2>Health Provider</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-container personal-info">
          <div className="full-width">
            <h4>Primary Provider</h4>
            {subscription?.subscriber?.primaryCareProvider ||
            group?.subscriber?.primaryCareProvider ? (
              <div>
                <h3>
                  {groupmemberId
                    ? group?.subscriber?.primaryCareProvider?.name
                    : subscription?.subscriber?.primaryCareProvider?.name}
                </h3>
                <p>
                  {groupmemberId
                    ? group?.subscriber?.primaryCareProvider?.address01
                    : subscription?.subscriber?.primaryCareProvider?.address01}
                </p>
              </div>
            ) : (
              <h3>You have not selected a provider.</h3>
            )}
            <br />
          </div>
          {subscription.planName !== "Starter-Sachet®" ? (
            <>
              <h4>Change Provider ?</h4>
              <div className="form-body">
                <div className="bottom-end">
                  <label>Select State</label>
                  <select name="state" ref={state} onChange={handleFilter}>
                    {states.map((state, i) => (
                      <option value={state} key={i}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="bottom-end">
                  <label>Select available Provider</label>
                  <select name="provider" onChange={handleChange}>
                    {facilities &&
                      filteredData?.map((facility, i) => (
                        <option value={facility.name} key={i}>
                          {facility.name} {facility.address01}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <h4 className="select-provider-text">
                Change of provider is required at least 1 week before intended
                visits.
              </h4>
              <button type="submit">Update</button>
            </>
          ) : (
            <></>
          )}
        </div>
      </form>
    </div>
  );
}

export default HealthProvider;
