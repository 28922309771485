import { message } from "antd";
export const token = window.localStorage.getItem("token");
export const local = process.env.REACT_APP_API_URL;

export const header = () => {
  return {
    headers: {
      Authorization: "Bearer " + token, //the token is a variable which holds the token
    },
  };
};
export const handleError = (error) => {
  if (
    error.response.status === 401 &&
    error.response.statusText === "Unauthorized"
  ) {
    window.localStorage.setItem("token", null);
    window.localStorage.setItem("user", null);
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("user");
    message.error(
      "Your login session has expired please enter your details to login"
    );
    window.location.href = "/";
  }
  let err =
    error.response.data.error || error.response.data.message || error.response;
  return err;
};
