import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { local, header, handleError } from "../../services/helper";
import { message } from "antd";
import { logout } from "../authentication/reducer";

export const sendOtpPhone = createAsyncThunk(
  "users/sendOtpPhone",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(local + "/verify-phone", payload);
      if (response.data) {
        window.localStorage.setItem("token", response.data.token);
        window.localStorage.setItem(
          "user",
          JSON.stringify({
            userId: response.data.user,
            otpId: response.data.otpId,
          })
        );
        return response.data;
      }
    } catch (error) {
      const e = handleError(error);
      return rejectWithValue(e);
    }
  }
);
export const sendOtpEmail = createAsyncThunk(
  "users/sendOtpEmail",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(local + "/verify-email", payload);
      if (response.data) {
        window.localStorage.setItem("token", response.data.token);
        window.localStorage.setItem(
          "user",
          JSON.stringify({
            userId: response.data.user,
            otpId: response.data.otpId,
          })
        );
        return response.data;
      }
    } catch (error) {
      const e = handleError(error);
      return rejectWithValue(e);
    }
  }
);
export const verifyOtp = createAsyncThunk(
  "users/verifyOtp",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(local + "/otp", payload, header());
      return response.data;
    } catch (error) {
      const e = handleError(error);
      return rejectWithValue(e);
    }
  }
);

export const updatePhoneNumber = createAsyncThunk(
  "users/updatePhoneNumber",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        local + "/phoneNumber",
        payload,
        header()
      );
      return response.data;
    } catch (error) {
      const e = handleError(error);
      return rejectWithValue(e);
    }
  }
);
export const updateEmail = createAsyncThunk(
  "user/updateEmail",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.put(local + "/email", payload, header());
      return response.data;
    } catch (error) {
      const e = handleError(error);
      return rejectWithValue(e);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "user/updatePassword",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(local + "/password", payload, header());
      if (response.data.success) {
        window.localStorage.removeItem("otp");
        message.info("Password Updated Sucessfully");
        dispatch(logout());
      }
      return response.data;
    } catch (error) {
      const e = handleError(error);
      return rejectWithValue(e);
    }
  }
);

export const updatePersonalInfo = createAsyncThunk(
  "user/updatePersonalInfo",
  async (action, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${local}/subscribers/${action.subscriberId}`,
        action.payload,
        header()
      );
      if (response.data.success) {
        message.info("Personnal Info Updated Sucessfully");
      }
      return response.data;
    } catch (error) {
      const e = handleError(error);
      return rejectWithValue(e);
    }
  }
);

const initialState = {
  loading: false,
  success: false,
  error: null,
};
export const settingSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(verifyOtp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyOtp.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true; // registration successful
        if (state.success) {
          window.localStorage.removeItem("otp");
          message.info("OTP verified");
        }
      })
      .addCase(verifyOtp.rejected, (state, { payload }) => {
        state.loading = false;
        state.success = false;
        state.error = payload;
      })
      .addCase(sendOtpPhone.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendOtpPhone.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true; // registration successful
        if (state.success) {
          window.localStorage.setItem("token", payload["token"]);
          window.localStorage.setItem("user", JSON.stringify(payload["user"]));
        }
      })
      .addCase(sendOtpPhone.rejected, (state, { payload }) => {
        state.loading = false;
        state.success = false;
        state.error = payload;
      })
      .addCase(sendOtpEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendOtpEmail.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true; // registration successful
        if (state.success) {
          window.localStorage.setItem("token", payload["token"]);
          window.localStorage.setItem(
            "user",
            JSON.stringify({
              userId: payload.user,
              otpId: payload.otpId,
            })
          );
          message.info("successful");
        }
      })
      .addCase(sendOtpEmail.rejected, (state, { payload }) => {
        state.loading = false;
        state.success = false;
        state.error = payload;
      })
      .addCase(updateEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateEmail.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true; // registration successful
        if (state.success) {
          window.localStorage.removeItem("otp");
          message.info("Email Updated Sucessfully");
        }
      })
      .addCase(updateEmail.rejected, (state, { payload }) => {
        state.loading = false;
        state.success = false;
        state.error = payload;
      })
      .addCase(updatePhoneNumber.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePhoneNumber.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true; // registration successful
        if (state.success) {
          window.localStorage.removeItem("otp");
          message.info("Phone number Updated Sucessfully");
        }
      })
      .addCase(updatePhoneNumber.rejected, (state, { payload }) => {
        state.loading = false;
        state.success = false;
        state.error = payload;
      })
      .addCase(updatePassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePassword.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true; // registration successful
        if (state.success) {
          window.localStorage.removeItem("otp");
        }
      })
      .addCase(updatePassword.rejected, (state, { payload }) => {
        state.loading = false;
        state.success = false;
        state.error = payload;
      });
  },
});
export default settingSlice.reducer;
