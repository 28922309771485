import { ReactComponent as Printer } from "../../assets/images/dashboard/print.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment";
import "../../pages/Subscribers/subscriber.scss";
import PreViewImage from "./PreViewImage";
import { getSubscription } from "../../redux/subscriber/reducer";
import PrintEcard from "../subscriber/general/PrintEcard";
function SecurityProfileCard({ update, type, groupmemberId }) {
  const { subscription, group } = useSelector((state) => state.subscriber);
  const dispatch = useDispatch();
  const subscriberId = window.localStorage.getItem("subscriberId");
  const groupmember = group?.filter(
    (item) => item.subscriber._id === groupmemberId
  );
  const subscriber = groupmemberId
    ? groupmember[0]?.subscriber
    : subscription?.subscriber;
  const date = moment(subscriber.dateOfBirth).format("DD-MMM-YYYY");
  const [print, setPrint] = useState(false);

  useEffect(() => {
    type === "subscriber" && dispatch(getSubscription(subscriberId));
  }, [dispatch, subscriberId, type]);
  return (
    <div className="info-container">
      {print && (
        <>
          <div className="overlay"></div>
          <PrintEcard setPrint={setPrint} print={print} />
        </>
      )}
      {(type === "subscriber" || type === "Admin-setting") && (
        <div className="card-header">
          <h2>Personal Information</h2>
          <div className="non-mobile">
            <Printer
              style={{ fontSize: "22px", marginTop: "5px" }}
              onClick={() => setPrint(true)}
            />
          </div>
        </div>
      )}
      <div className="card-header-right mobile">
        <button onClick={() => update(true)}>Update</button>
        <Printer
          style={{ fontSize: "22px", marginTop: "5px" }}
          onClick={() => setPrint(true)}
        />
      </div>
      <div className="info">
        <div className="card-body">
          <PreViewImage profileUrl={subscriber.profileUrl} />
          <div className="card-section">
            <div className="card-top">
              <span>
                <b>User ID</b>
                {subscriber.policyNumber
                  ? " " + subscriber.policyNumber
                  : " --"}
              </span>
              <h5 className="username">
                <span>
                  <b>First Name:</b>
                  {subscriber.firstName ? " " + subscriber.firstName : " "}
                </span>
                <span>
                  <b>Last Name:</b>
                  {subscriber.lastName ? " " + subscriber.lastName : " "}
                </span>
              </h5>
            </div>
            <div className="card-content">
              <h5>Bio</h5>
              <span>
                <b>DOB</b>: {subscriber.dateOfBirth ? " " + date : " --"}
              </span>
              <span>
                <b>Sex</b>: {subscriber.sex ? " " + subscriber.sex : "--"}
              </span>
            </div>
            <div className="card-content">
              <h5>Contact</h5>
              <span>
                <b>Email</b>:{" "}
                {subscriber.user.email ? " " + subscriber.user.email : " --"}
              </span>
              <span>
                <b>Phone</b>:{" "}
                {subscriber.user.phone ? " 0" + subscriber.user?.phone : " --"}
              </span>
              <span>
                <b>Address</b>:{" "}
                {subscriber?.address01 ? subscriber?.address01 : " --"}
                {subscriber?.address02 ? "," + subscriber?.address02 : ""}
                {subscriber?.state ? "," + subscriber?.state : ""}
              </span>
            </div>
            <div className="card-content">
              <h5>Plan</h5>
              <span>
                <b>Package</b>:
                {subscription.planName ? " " + subscription.planName : " --"}
              </span>
              <span>
                <b>Provider</b>:
                {subscriber?.primaryCareProvider
                  ? " " + subscriber?.primaryCareProvider?.name
                  : " --"}
              </span>
            </div>
            <div className="card-content">
              <h5>Emergency Contact</h5>
              <span>
                <b>Name</b>:{" "}
                {subscriber?.emergencyContactName
                  ? subscriber?.emergencyContactName
                  : " --"}
              </span>
              <span>
                <b>Phone number</b>:{" "}
                {subscriber?.emergencyContactPhone
                  ? subscriber?.emergencyContactPhone
                  : " --"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecurityProfileCard;
