import React from "react";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
function BackNav({ setDetailsPage, text }) {
  return (
    <div className="profileTop" onClick={() => setDetailsPage(false)}>
      <div>
        <MdOutlineArrowBackIosNew />
      </div>
      <h4>{text}</h4>
    </div>
  );
}

export default BackNav;
