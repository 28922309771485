import React from "react";
import CardHeader from "./CardHeader";
import { useState } from "react";
import "./CardHeader.scss";
import moment from "moment";

function CardModal({
  popUp,
  setShowCard,
  group,
  viewer,
  handlePay,
  planType,
  setShowdetails,
}) {
  const [focus, setFocus] = useState("benefits");
  return (
    <div className="card-modal-container">
      {popUp && (
        <div className="close" onClick={() => setShowCard(false)}>
          X
        </div>
      )}

      {group ? (
        <>
          <div style={{ marginBottom: "1rem" }}>
            <CardHeader
              name={
                group?.subscriber?.lastName + " " + group?.subscriber?.firstName
              }
              id={
                group?.subscriber.isPrincipal
                  ? group?.subscriber.policyNumber + "-admin"
                  : group?.subscriber.policyNumber
              }
              subtitle="Total Paid"
              subtext={"₦ " + group?.totalPaid?.toLocaleString("en-US") || "-"}
            />
          </div>

          <div style={{ marginBottom: "1rem" }}>
            <CardHeader
              name="Phone"
              id={"0" + group?.subscriber?.user?.phone}
              subtitle="Sex"
              subtext={group?.subscriber?.sex || "-"}
            />
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <CardHeader
              name="Email"
              id={group?.subscriber?.user?.email}
              subtitle="Spent"
              subtext={group?.totalExpenses.toLocaleString("en-US") || "-"}
            />
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <CardHeader
              name="Plan Status"
              id={group?.planName === "Starter-Sachet®" ? "Free" : "Activated"}
              subtitle="Payment"
              subtext={
                group?.durationCovered >=
                moment().month() - moment(group?.payment?.startDate).month()
                  ? "Up to date"
                  : "delayed"
              }
            />
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <CardHeader
              name="Is Active"
              id={group?.active ? "Yes" : "No"}
              subtitle=""
              subtext={
                group?.id !== viewer.id && (
                  <span
                    style={{ color: "red" }}
                    className="member-pay"
                    onClick={() => {
                      setShowCard(false);
                      handlePay(group);
                    }}
                  >
                    {group?.subscriber?.hasSubscription
                      ? "Sponsor"
                      : "Activate"}
                  </span>
                )
              }
            />
          </div>
          {planType === "Basic" && (
            <div style={{ marginBottom: "1rem" }}>
              <CardHeader
                name=""
                id=""
                subtitle=""
                subtext={
                  <span
                    style={{ color: "red" }}
                    className="member-pay"
                    onClick={() => {
                      setShowdetails(group?.subscriber?._id);
                      setShowCard(false);
                    }}
                  >
                    Update
                  </span>
                }
              />
            </div>
          )}
        </>
      ) : (
        <>
          <div style={{ marginBottom: "1rem" }}>
            <CardHeader
              name="St. Lukes"
              id="006522"
              subtitle="Period"
              subtext="Jun 7 - Jun 13"
            />
          </div>

          <div
            style={{ width: "100%", background: "#f9f9f9", height: ".5rem" }}
          ></div>
          <nav>
            <ul className="card-nav">
              <li
                onClick={() => setFocus("benefits")}
                className={`${focus === "benefits" ? "focus" : ""}`}
              >
                Benefit used
              </li>
              <li
                onClick={() => setFocus("investigation")}
                className={`${focus === "investigation" ? "focus" : ""}`}
              >
                Investigation
              </li>
            </ul>
          </nav>
          {focus === "benefits" && (
            <ul className="card-list">
              <li>Malaria</li>
              <li>Malaria</li>
              <li>Malaria</li>
              <li>Malaria</li>
            </ul>
          )}
          {focus === "investigation" && (
            <ul className="card-list">
              <li>investigation</li>
              <li>investigation</li>
              <li>investigation</li>
              <li>investigation</li>
            </ul>
          )}
        </>
      )}
    </div>
  );
}

export default CardModal;
