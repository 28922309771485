import React, { useState } from "react";
import OTPInput from "otp-input-react";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import {
  ResendOtp,
  forgotPasswordOtp,
  verification,
} from "../../redux/authentication/reducer";

function Otp({ verify, loading }) {
  const [OTP, setOTP] = useState("");
  const dispatch = useDispatch();
  const params = JSON.parse(localStorage.getItem("user"));
  const { error } = useSelector((state) => state.auth);
  // Submit Otp
  const handleClick = (e) => {
    e.preventDefault();
    verify === "signUP" &&
      dispatch(
        verification({
          otpId: params.otpId,
          initiatedOnId: params.userId,
          otp: OTP,
        })
      );
    verify === "forgotPassword" &&
      dispatch(
        forgotPasswordOtp({
          otpId: params.otpId,
          initiatedOnId: params.userId,
          otp: OTP,
        })
      );
    setOTP("");
  };

  // Resend Otp
  const handleResendOtp = () => {
    dispatch(
      ResendOtp({
        initiatedOnId: params?.userId,
      })
    );
  };

  return (
    <>
      {error && <span className="err-message">{error}</span>}
      <form className="form">
        <div className="form-otp">
          <OTPInput
            className="otp-input-outline"
            inputStyles={{
              width: "60px",
              height: "60px",
              borderRadius: "8px",
              background: "#F1F4FA",
              outline: "#1083A9",
            }}
            value={OTP}
            onChange={setOTP}
            autoFocus
            OTPLength={4}
            otpType="number"
            disabled={false}
            secure
          />
        </div>
        <button className="btn-custom" onClick={handleClick}>
          {loading ? <Spin /> : "Verify"}
        </button>
        {/* <div className="back-arrow">
          <Link
            to={verify === "forgotPassword" ? "/reset-password" : "/plan"}
            className="back-arrow"
            id="skip"
          >
            <span>Skip</span>
            <FiArrowRight />
          </Link>
        </div> */}

        <div>
          <span className="verify-bottom-text">
            Didn’t get the code?
            <b
              style={{
                color: "#1083A9",
                cursor: "pointer",
                marginLeft: "5px",
              }}
              onClick={handleResendOtp}
            >
              Resend Code
            </b>
          </span>
        </div>
      </form>
    </>
  );
}

export default Otp;
