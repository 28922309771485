import { useDispatch } from "react-redux";
import { logout } from "../../../redux/authentication/reducer";
import "./Logout.scss";
function Logout() {
  const dispatch = useDispatch();
  return (
    <>
      <div className="overlay"></div>
      <div className="logoutContainer">
        <div>
          <p>
            Login session has expired, please
            <b className="logout" onClick={() => dispatch(logout())}>
              login
            </b>
          </p>
        </div>
      </div>
    </>
  );
}

export default Logout;
