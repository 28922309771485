import React, { useState, useEffect } from "react";
import "./subscriber.scss";
import logo from "../../assets/images/logo1.svg";
import logoMobile from "../../assets/images/logo.svg";
import { BiSupport, BiLogOut } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import { TbFirstAidKit } from "react-icons/tb";
import { RiHomeLine } from "react-icons/ri";
import { ReactComponent as Subscribe } from "../../assets/images/dashboard/subscribe.svg";
import { HiOutlineUserGroup } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import useWindowWidth from "../../hooks/useWindowWidth";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/authentication/reducer";
import { getSubscription } from "../../redux/subscriber/reducer";
import Logout from "../../components/subscriber/general/Logout";
const data = [
  {
    name: "Dashboard",
    icon: RiHomeLine,
  },
  {
    name: "Subscription",
    icon: Subscribe,
  },
  {
    name: "Encounters",
    icon: TbFirstAidKit,
  },
  {
    name: "Group Members",
    icon: HiOutlineUserGroup,
  },
  {
    name: "Support",
    icon: BiSupport,
  },
  {
    name: "Setting",
    icon: FiSettings,
  },
];

function Dashboard({ children }) {
  const [toggle, setToggle] = useState(0);
  const { subscription, error } = useSelector((state) => state.subscriber);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const width = useWindowWidth();
  const subscriberId = window.localStorage.getItem("subscriberId");
  const handleClick = (name, index) => {
    if (name === "Dashboard") {
      navigate("/dashboard");
    } else {
      navigate(`/dashboard/${name.toLowerCase()}`);
    }
    setToggle(index);
  };

  const handlLogout = () => {
    dispatch(logout());
  };
  useEffect(() => {
    dispatch(getSubscription(subscriberId));
  }, [dispatch, subscriberId]);
  return (
    <>
      {error && <Logout />}
      <div className="dashboard-container">
        {width > 600 && (
          <div className="dashboard-left  slideRight">
            <img src={logo} alt="" className="non-mobile" />
            <img src={logoMobile} alt="" className="mobile" />
            <div className="dashboard-menu">
              {data.map((item, i) =>
                subscription?.subscriber?.groupCohortType === "Open" ? (
                  item.name !== "Group Members" && (
                    <button
                      key={i}
                      className={
                        toggle === i
                          ? "dashboard-items dashboard-focus"
                          : "dashboard-items"
                      }
                      onClick={() => handleClick(item.name, i)}
                    >
                      <item.icon className="icon" />
                      <span>{item.name}</span>
                    </button>
                  )
                ) : (
                  <button
                    key={i}
                    className={
                      toggle === i
                        ? "dashboard-items dashboard-focus"
                        : "dashboard-items"
                    }
                    onClick={() => handleClick(item.name, i)}
                  >
                    <item.icon className="icon" />
                    <span>{item.name}</span>
                  </button>
                )
              )}
              <button className="btn-logout" onClick={handlLogout}>
                <span>Log Out </span>
                <BiLogOut fontSize="25px" />
              </button>
            </div>
          </div>
        )}
        <div className="dashboard-right">{children}</div>
      </div>
    </>
  );
}

export default Dashboard;
