import { useState } from "react";

function ProviderDetailsCard() {
  const [edit, setEdit] = useState(true);
  const handleUpdate = (e) => {
    e.preventDefault();
    setEdit(!edit);
  };

  return (
    <form className="userdetails-card">
      <div className="userdetails-Col">
        <div className="userdetail">
          <label className="userdetails-heading">Name</label>
          <input
            type="text"
            disabled
            value="Health Nav"
            className={edit ? "userdetails-text disabled" : "userdetails-text"}
          />
        </div>
      </div>
      <div className="userdetails-Col">
        <div className="userdetail">
          <label className="userdetails-heading">Contact name</label>
          <input
            type="text"
            disabled={edit ? true : false}
            value="Victor "
            className={edit ? "userdetails-text disabled" : "userdetails-text"}
          />
        </div>
        <div className="userdetail">
          <label className="userdetails-heading">Contact email</label>
          <input
            type="text"
            disabled
            value="a@mail.com"
            className={edit ? "userdetails-text disabled" : "userdetails-text"}
          />
        </div>
      </div>
      <div className="userdetails-Col">
        <div className="userdetail">
          <label className="userdetails-heading">Address 1</label>
          <input
            type="text"
            disabled
            value="First address, Lekki"
            className={edit ? "userdetails-text disabled" : "userdetails-text"}
          />
        </div>
        <div className="userdetail">
          <label className="userdetails-heading">Address 2</label>
          <input
            type="text"
            disabled
            value="Second address, VI"
            className={edit ? "userdetails-text disabled" : "userdetails-text"}
          />
        </div>
        <div className="userdetail">
          <label className="userdetails-heading">State</label>
          <input
            type="text"
            disabled
            value="First address, Lekki"
            className={edit ? "userdetails-text disabled" : "userdetails-text"}
          />
        </div>
      </div>
      <button className="btn-update" onClick={handleUpdate}>
        Update
      </button>
    </form>
  );
}

export default ProviderDetailsCard;
