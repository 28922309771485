import React, { useState } from "react";

function VoucherCard() {
  const [showList, setShowList] = useState(false);
  return (
    <div className="voucherCard">
      <nav>
        <ul className="voucher-list">
          <li
            className={showList ? "list focus" : "list"}
            onClick={() => setShowList(true)}
          >
            Used
          </li>
          <li
            className={!showList ? "list focus" : "list"}
            onClick={() => setShowList(false)}
          >
            Unused
          </li>
        </ul>
      </nav>
      <div className="voucher-list">
        <div>
          <h3>Voucher code</h3>
          <div className="row">
            <div className="list">4355 4323 3223</div>
            <div className="list">4355 4323 3223</div>
          </div>
        </div>

        <div>
          <h3>Date created</h3>
          <div className="row">
            <div className="list">05-04-2023</div>
            <div className="list">05-04-2023</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VoucherCard;
