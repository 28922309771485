import { useState, useEffect } from "react";
import VoucherPayment from "./VoucherPayment";
import { useDispatch, useSelector } from "react-redux";
import { PaystackButton } from "react-paystack";
import { message } from "antd";
import {
  activateGroupMember,
  activatePlan,
  getSubscription,
  topUp,
} from "../../redux/subscriber/reducer";

const PaymentForm = ({
  plan,
  type,
  subscriptionId,
  subscriberId,
  groupPlan,
  paymentFor,
}) => {
  const userInfo = JSON.parse(window.localStorage.getItem("userFormData"));
  const { subscription, groupDetails, groupInfo } = useSelector(
    (state) => state.subscriber
  );
  const mySubscriberId = window.localStorage.getItem("subscriberId");
  const [paymentType, setPaymentType] = useState(true);
  const [showOption, setShowOption] = useState(true);
  const dispatch = useDispatch();
  const publicKey = process.env.REACT_APP_PAYSTACK_KEY;
  const amount =
    paymentFor === "Activate"
      ? Number(groupPlan?.cost?.replace(",", ""))
      : Number(plan?.cost.replace(",", ""));
  const seed = amount * 12 * 0.1;
  const amountGroup = seed + amount;
  const email = userInfo?.email || subscription.subscriber.user.email;
  const user =
    userInfo?.firstName + " " + userInfo?.lastName ||
    subscription.subscriber.user.username;
  const phone = userInfo?.phone || subscription.subscriber.user.phone;
  const onFreePlan = () => {
    dispatch(
      activatePlan({
        type: "Free",
        cohort: {
          name: "COHORT 0",
          cost: 0.0,
          type: type,
        },
        payment: { content: "SUB" },
        groupInfo,
      })
    );
  };

  const onSuccess = (res, cost) => {
    message.info("Thanks for doing business with us! Come back soon!!");
    if (paymentFor === "Top Up" && subscriptionId) {
      dispatch(
        topUp({
          payload: {
            type: "PayStack",
            payment: {
              ref: res.status,
              cost: cost,
              transactionId: res.reference,
              content: "SUB",
            },
            cohort: {
              name: plan.planName,
              type,
              cost: amount,
            },
          },
          id: subscriptionId,
        })
      );
    }
    if (subscriberId) {
      dispatch(
        activateGroupMember({
          payload: {
            type: "PayStack",
            payment: {
              ref: res.status,
              cost: cost,
              transactionId: res.reference,
              content:
                cost === amountGroup
                  ? "SUB_SEED"
                  : cost === seed
                  ? "SEED"
                  : "SUB",
            },
            cohort: {
              name: groupPlan.planName,
              type: groupPlan.type,
              cost: amount,
            },
            groupInfo: groupDetails,
          },
          id: subscriberId,
        })
      );
    }

    if (
      (paymentFor === "Onboard" && plan.planName !== "Cohort 0") ||
      (paymentFor === "Activate" && !subscriberId)
    ) {
      dispatch(
        activatePlan({
          type: "PayStack",
          payment: {
            ref: res.status,
            cost: cost,
            transactionId: res.reference,
            content:
              cost === amountGroup
                ? "SUB_SEED"
                : cost === seed
                ? "SEED"
                : "SUB",
          },
          cohort: {
            name: paymentFor === "Onboard" ? plan.planName : groupPlan.planName,
            type: type || groupPlan.type,
            cost: amount,
          },
          groupInfo: paymentFor === "Onboard" ? groupInfo : groupDetails,
        })
      );
    }
    window.localStorage.removeItem("userFormData");
  };
  // ACTIVATE_GROUPMEMBER
  const componentProps = {
    email,
    amount: amount * 100,
    phone,
    metadata: {
      name: user,
    },
    publicKey,
    text: "Pay via Card",
    onSuccess: (res) => {
      onSuccess(res, amount);
    },
    onClose: () => message.info("Wait! Don't leave :("),
  };

  const componentPropsSeed = {
    email,
    amount: seed * 100,
    phone,
    metadata: {
      name: user,
    },
    publicKey,
    text: "Pay Seed Only",
    onSuccess: (res) => {
      onSuccess(res, seed);
    },
    onClose: () => message.info("Wait! Don't leave :("),
  };

  const componentPropsGroup = {
    email,
    amount: amountGroup * 100,
    phone,
    metadata: {
      name: user,
    },
    publicKey,
    text: "Pay All",
    onSuccess: (res) => {
      onSuccess(res, amountGroup);
    },
    onClose: () => message.info("Wait! Don't leave :("),
  };
  useEffect(() => {
    dispatch(getSubscription(mySubscriberId));
  }, [dispatch, mySubscriberId]);
  return (
    <>
      <div className="payment-header">
        {paymentFor === "Top Up" || paymentFor === "Sponsor" ? (
          <>
            <h3>Prefered payment option</h3>
            <div className="payment-options">
              <PaystackButton
                {...componentProps}
                onClick={() => setPaymentType(true)}
                className={paymentType && "span-onclick card-pay"}
                id="card-pay"
              />
              <button
                onClick={() => setPaymentType(false)}
                className={!paymentType && "span-onclick voucher-pay"}
                id="voucher-pay"
              >
                Pay via Voucher
              </button>
            </div>
            {!paymentType && (
              <VoucherPayment
                plan={plan}
                type={type || subscription.subscriber.groupCohortType}
              />
            )}
          </>
        ) : (
          (!showOption ||
            type === "Open" ||
            (subscriberId && type === "Closed") ||
            (subscriptionId && type === "Closed")) && (
            <div className="group-payment">
              <p>
                {`You get to pay ₦ 
                ${amountGroup.toLocaleString("en-US")}
                 as your initial payment`}
              </p>
              <span>
                {"SEED (Admin One-off) = ₦ " + seed.toLocaleString("en-US")}
              </span>
              <br />
              <span>
                {"1st Month Contribution = ₦ " + amount.toLocaleString("en-US")}
              </span>

              {plan.name === "Starter-Sachet®" ? (
                <div style={{ textAlign: "center" }} onClick={onFreePlan}>
                  <button
                    style={{ padding: "10px 20px" }}
                    className="span-onclick"
                  >
                    Continue
                  </button>
                </div>
              ) : (
                <div className="payment-options">
                  <PaystackButton
                    {...componentPropsGroup}
                    onClick={() => setPaymentType(true)}
                    className="span-onclick"
                  />
                  <PaystackButton
                    {...componentPropsSeed}
                    onClick={() => setPaymentType(true)}
                    className="span-onclick"
                  />
                </div>
              )}
            </div>
          )
        )}
        {showOption &&
          type === "Closed" &&
          !subscriberId &&
          !subscriptionId && (
            <>
              <div>
                <button
                  style={{
                    padding: "0 0 5px",
                    textDecoration: "underline #2d7183",
                  }}
                  onClick={onFreePlan}
                >
                  Continue with free plan
                </button>
              </div>
              <div>
                <button
                  style={{
                    padding: "0 0 10px",
                    textDecoration: "underline #2d7183",
                  }}
                  onClick={() => setShowOption(false)}
                >
                  Proceed to payment
                </button>
              </div>
            </>
          )}
      </div>
    </>
  );
};

export default PaymentForm;
