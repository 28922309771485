import React from "react";
import { Row, Col } from "antd";
import { useSelector } from "react-redux";
import { useState } from "react";
import PaymentForm from "../../Payment/PaymentForm";
import { plans } from "../../../pages/Plan/Plan";
import moment from "moment";

export const selectPlan = (subscription) => {
  const findPlan = plans.filter((item) => {
    return item?.name?.toLowerCase() === subscription?.planName?.toLowerCase();
  });
  return {
    planName: findPlan[0]?.planName,
    cost: findPlan[0]?.cost ?? "0",
    type: subscription?.planType,
  };
};

export const getPlanByGroup = (groupDetails) => {
  const findPlan = plans.filter((item) => {
    return item?.planName?.toLowerCase() === groupDetails?.plan?.toLowerCase();
  });
  return {
    planName: findPlan[0]?.planName,
    cost: findPlan[0]?.cost,
    type: groupDetails?.type,
  };
};

function SubscriptionCard() {
  const { subscription, groupDetails } = useSelector(
    (state) => state.subscriber
  );
  const [showModal, setShowModal] = useState(false);
  const [pay, setPay] = useState("");
  const handleSub = (e) => {
    setPay(e.target.innerText);
    if (pay === "Top Up") setShowModal(true);
    if (pay === "Activate" && subscription.planType === "Closed") {
      setShowModal(true);
    }
    if (pay === "Activate" && subscription.planType === "Open") {
      window.location.href = "/plans";
    }
  };

  const selectedPlan = selectPlan(subscription);
  const groupPlan = getPlanByGroup(groupDetails);
  return (
    <>
      {showModal && (
        <div className="payment-card">
          <PaymentForm
            plan={selectedPlan}
            type={subscription?.planType}
            subscriptionId={subscription?.id}
            groupPlan={groupPlan}
            paymentFor={pay}
          />
          <div style={{ textAlign: "center" }}>
            <button onClick={() => setShowModal(false)}>Close</button>
          </div>
        </div>
      )}

      <div className="subscription-card">
        <Row className="subscription-card-top">
          <Col>
            <h3>Subscription</h3>
          </Col>
          <Col>
            {subscription?.planType === "Open" && (
              <span onClick={handleSub}>
                {subscription?.planName === "Starter-Sachet®"
                  ? "Activate"
                  : "Top Up"}
              </span>
            )}

            {groupDetails?.type === "Closed" &&
              groupDetails?.plan === "COHORT 0" && (
                <span>
                  {subscription?.subscriber?.isPrincipal
                    ? "Upgrade group plan"
                    : "Contact group admin"}
                </span>
              )}

            {groupDetails?.type === "Closed" &&
              groupDetails?.plan !== "COHORT 0" && (
                <span onClick={handleSub}>
                  {subscription?.planName === "Starter-Sachet®"
                    ? "Activate"
                    : "Top Up"}
                </span>
              )}
          </Col>
        </Row>
        <Row className="subscription-card-middle">
          <div>
            {subscription.totalPaid
              ? "₦" +
                subscription?.amountLeftMonthly[
                  subscription?.amountLeftMonthly?.length - 1
                ]?.paid?.toLocaleString("en-US") +
                ".00"
              : "₦" + subscription.totalPaid?.toLocaleString("en-US")}
          </div>
          <div className="card-subtext">of</div>
          <div className="card-subtext">
            {subscription.monthlyPremium || "0"
              ? "₦" +
                subscription?.monthlyPremium?.toLocaleString("en-US") +
                ".00 paid"
              : "--"}
          </div>
        </Row>
        <Row>
          <div>
            {subscription.totalPaid ? (
              <span>
                <b>last updated:{" "}</b>
                <i>
                  {moment(
                    subscription?.amountLeftMonthly[
                      subscription?.amountLeftMonthly.length - 1
                    ]?.dateUpdated
                  ).format("DD-MMM-YYYY")}
                </i>
              </span>
            ) : (
              <span> </span>
            )}
          </div>
        </Row>
        <span><b>NB: </b>Payments made are added to subscription balance</span>
      </div>
    </>
  );
}

export default SubscriptionCard;
