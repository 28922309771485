import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  addgroupMember,
  getSubscription,
} from "../../../redux/subscriber/reducer";

function AddGroupMember({ setPopup }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const dispatch = useDispatch();
  const handleChange = ({ target: { name, value } }) => {
    name === "firstName" && setFirstName(value);
    name === "lastName" && setLastName(value);
    name === "email" && setEmail(value);
    name === "phone" && setPhone(value);
  };
  const subscriberId = window.localStorage.getItem("subscriberId");

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addgroupMember({ firstName, lastName, phone, email }));
    setEmail("");
    setFirstName("");
    setLastName("");
    setPhone("");
    setPopup(false);
    dispatch(getSubscription(subscriberId));
  };
  return (
    <div className="add-member">
      <h3>Add member</h3>
      <form onSubmit={handleSubmit}>
        <div className="member-form">
          <div>
            <label>First name</label>
            <input
              type="text"
              name="firstName"
              onChange={handleChange}
              pattern="^[A-Za-z0-9\s]{3,24}$"
              value={firstName}
              required
            />
          </div>
          <div>
            <label>Last name</label>
            <input
              type="text"
              name="lastName"
              onChange={handleChange}
              pattern="^[A-Za-z0-9\s]{3,24}$"
              value={lastName}
              required
            />
          </div>
          <div>
            <label>Phone</label>
            <input
              type="tel"
              name="phone"
              onChange={handleChange}
              pattern="^\d{11}$"
              value={phone}
              required
            />
          </div>
          <div>
            <label>Email</label>
            <input
              type="email"
              name="email"
              onChange={handleChange}
              pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
              value={email}
              required
            />
          </div>
          <div className="member-bottom">
            <button>+ Add Member</button>
            <span onClick={() => setPopup(false)}>Cancel</span>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddGroupMember;
