import React from "react";

const Pagination = ({ currentPage, itemsPerPage, totalItems, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination">
        <span>
          {"Showing " +
            currentPage +
            " to " +
            pageNumbers.length +
            " of " +
            totalItems +
            " entries"}
        </span>
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={currentPage === number ? "page-item focus" : "page-item"}
          >
            <span onClick={() => paginate(number)} className="page-link">
              {number}
            </span>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
