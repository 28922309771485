import React from "react";
import { Row, Col } from "antd";
import ProgressBar from "./progress";
import Benefits from "../dashboard/Benefits";
import HistoryCard from "./HistoryCard";
import SubscriptionCard from "./SubscriptionCard";
import BackNav from "../../global/BackNav";
function Subscription() {
  return (
    <div className="subscription-dashboard">
      <BackNav text="Subscription" />
      <Row className="dashboard-body">
        <Col style={{ position: "static" }} span={12}>
          <SubscriptionCard />
        </Col>
        <Col className="" span={12}>
          <ProgressBar />
        </Col>
      </Row>
      <Row className="dashboard-body">
        <Col className="" span={12}>
          <Benefits />
        </Col>
        <Col className="" span={12}>
          <HistoryCard title="Transactions" />
        </Col>
      </Row>
    </div>
  );
}

export default Subscription;
