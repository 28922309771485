import React from "react";
import { useRef } from "react";
import { BiSearch } from "react-icons/bi";
function Search({ setFilteredData, filteredData, data }) {
  const text = useRef();
  const handleSearch = (e) => {
    if (!text.current.value?.length) {
      return setFilteredData(data);
    } else {
      const searchData = filteredData?.filter((item) =>
        item.title.toLowerCase().includes(text.current.value.toLowerCase())
      );
      setFilteredData(searchData);
    }
  };
  return (
    <div className="search">
      <input placeholder="Search" onChange={handleSearch} ref={text} />
      <span className="search-icon">
        <BiSearch />
      </span>
    </div>
  );
}

export default Search;
