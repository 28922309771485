import React from "react";
import { useState } from "react";
import AppointmentTable from "./AppointmentTable";
import HistoryTable from "./HistoryTable";
import "./prescription.scss";
import PrescriptionTable from "./PrescriptionTable";
import PrescriptionMobileCard from "./PrescriptionMobileCard";
import HistoryMobileCard from "./HistoryMobileCard";
import AppointmentMobileCard from "./AppointmentMobileCard";
import BackNav from "../../global/BackNav";
const PrescriptionDataHeading = ["History", "Prescriptions", "Appointments"];
function Prescriptions() {
  const [toggle, setToggle] = useState(0);
  const handleToggle = (i) => {
    setToggle(i);
  };

  return (
    <>
      <BackNav text="Encounter" />
      <div className="prescription-container">
        {/* <h1 className="mobile-header">Encounter</h1> */}
        <div className="header">
          {PrescriptionDataHeading.map((item, i) => (
            <h3
              className={toggle === i && "highlight"}
              onClick={() => handleToggle(i)}
            >
              {item}
            </h3>
          ))}
        </div>
        {toggle === 0 && <HistoryTable />}
        {toggle === 1 && <PrescriptionTable />}
        {toggle === 2 && <AppointmentTable />}
      </div>
      <div className="prescription-options">
        {toggle === 0 && <HistoryMobileCard />}
        {toggle === 1 && <PrescriptionMobileCard />}
        {toggle === 2 && <AppointmentMobileCard />}
      </div>
    </>
  );
}

export default Prescriptions;
