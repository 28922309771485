import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./CreateGroupForm.scss";
import { Spin } from "antd";
import { states } from "../subscriber/setting/healthProvider/HealthProvider";
import { createGroup } from "../../redux/subscriber/reducer";
const Class = [
  "Cooperative",
  "Church/Religious",
  "Education",
  "SMEs",
  "Company",
  "Family/Friends",
  "Government",
];
function CreateGroupForm({
  setCreateGroup,
  plan,
  setIsModalVisible,
  setCalled,
}) {
  const [groupName, setGroupName] = useState("");
  const [groupType] = useState("Closed");
  const groupClassRef = useRef();
  const groupPlanRef = useRef();
  const stateRef = useRef();
  const [LGA, setLGA] = useState("");
  const { loading, error } = useSelector((state) => state.subscriber);
  const dispatch = useDispatch();
  const handleChange = ({ target: { name, value } }) => {
    name === "groupName" && setGroupName(value);
    name === "LGA" && setLGA(value);
  };
  const owner = window.localStorage.getItem("subscriberId");
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      createGroup({
        name: groupName,
        type: groupType,
        groupClass: groupClassRef?.current.value,
        location: LGA,
        plan: groupPlanRef?.current.value,
        state: stateRef?.current.value,
        owner,
      })
    );
    setGroupName("");
    setLGA("");
    setCreateGroup(false);
    setIsModalVisible(true);
    setCalled(true);
  };
  return (
    <>
      <div className="createGroupContainer">
        <h3> New Group Details</h3>
        <p> Please provide these informations to create a group </p>
        {error && <span className="err-message">{error}</span>}
        <form onSubmit={handleSubmit}>
          <div className="createGroupForm">
            <div>
              <label>Group Name</label>
              <input
                type="text"
                name="groupName"
                onChange={handleChange}
                pattern="^[A-Za-z0-9\s]{3,24}$"
                value={groupName}
                required
              />
            </div>
            <div>
              <label>Group Plan</label>
              <select read-only name="groupPlan" ref={groupPlanRef} required>
                <option value={plan.planName}>{plan.name}</option>
              </select>
            </div>
            <div>
              <label>Group Type</label>
              <input
                type="text"
                name="groupType"
                onChange={handleChange}
                pattern="^[A-Za-z0-9\s]{3,16}$"
                value={groupType}
                read-only
                required
              />
            </div>
            <div>
              <label>Group Class</label>
              <select name="groupClass" ref={groupClassRef} required>
                {Class.map((item, i) => (
                  <option value={item} key={i}>
                    {item}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label>LGA, City, Town</label>
              <input
                type="text"
                name="LGA"
                onChange={handleChange}
                pattern="^[A-Za-z0-9\s]{3,16}$"
                value={LGA}
                required
              />
            </div>
            <div>
              <label>State</label>
              <select name="state" ref={stateRef} required>
                {states.map((state, i) => (
                  <option value={state} key={i}>
                    {state}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="createGroupBottom">
            <button id="btn-createGroup" type="submit">
              {loading ? <Spin /> : "Create Account"}
            </button>
            <span className="btn-cancel" onClick={() => setCreateGroup(false)}>
              Cancel
            </span>
          </div>
        </form>
      </div>
    </>
  );
}

export default CreateGroupForm;
