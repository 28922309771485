import { useState } from "react";
import { Row, Col } from "antd";
import { RiArrowDropUpLine, RiArrowDropDownLine } from "react-icons/ri";
import ModalMobileBox from "../general/ModalMobileBox";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const BenefitsList = ({ data, seemore, waitingPeriod }) => {
  const { groupDetails } = useSelector((state) => state.subscriber);
  const [toggle, setToggle] = useState(false);
  const filteredData = data;
  return (
    <>
      {filteredData && (
        <>
          <div className="benefits-header">
            <div>
              <h4
                className="benefits-header-left"
                onClick={() => setToggle(false)}
              >
                Unlimited
              </h4>
              {!toggle && <hr style={{ borderColor: "#1083A9" }} />}
            </div>
            <div>
              <h4
                className="benefits-header-right"
                onClick={() => setToggle(true)}
              >
                Limited
              </h4>
              {toggle && (
                <hr style={{ borderColor: "#1083A9", marginLeft: "20px" }} />
              )}
            </div>
            <hr />
          </div>

          <div
            className="benefits-list"
            style={{ height: seemore ? "100%" : "" }}
          >
            {filteredData.map((benefits, i) => (
              <>
                {benefits.limited && toggle ? (
                  <Row key={i}>
                    <Col>
                      <span>{benefits.title}</span>
                    </Col>
                    <Col>
                      <span className="table-right">
                        {benefits?.privileges > 0 &&
                          benefits?.privileges.toLocaleString("en-US")}{" "}
                        ({benefits?.indicator && benefits?.indicator})
                      </span>
                    </Col>
                  </Row>
                ) : (
                  !benefits.limited &&
                  !toggle && (
                    <Row key={i}>
                      <Col>
                        <span>{benefits.title}</span>
                      </Col>
                    </Row>
                  )
                )}
              </>
            ))}
          </div>
          <div className="no-data">
            <h1 className="no-data-text">
              {waitingPeriod?.on
                ? `You can access your benefits after a waiting period of ${waitingPeriod?.months} months.`
                : groupDetails?.status === "Locked"
                ? `Access to your Benefits would be unlocked when your group is activated`
                : "No Benefits"}
            </h1>
          </div>
        </>
      )}
    </>
  );
};
function Benefits() {
  const { benefits, accessibility } = useSelector(
    (state) => state.subscriber.subscription
  );
  const [showCard, setShowCard] = useState(false);
  const [seemore, setSeemore] = useState(false);
  const [filteredData, setFilteredData] = useState("");
  useEffect(() => {
    setFilteredData(benefits);
  }, [benefits]);
  return (
    <div className="benefits">
      {seemore && (
        <ModalMobileBox
          setSeemore={setSeemore}
          show={seemore}
          title={"Benefits"}
          Component={BenefitsList}
          data={benefits}
          waitingPeriod={accessibility?.waitingPeriod?.on}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
        />
      )}
      <div className="mobile">
        <span className="mobile-header">Benefits</span>
        <span onClick={() => setShowCard(!showCard)}>
          {!showCard ? (
            <span onClick={() => setShowCard(!showCard)}>
              <RiArrowDropDownLine size="28px" />
            </span>
          ) : (
            <span onClick={() => setShowCard(!showCard)}>
              <RiArrowDropUpLine size="28px" />
            </span>
          )}
        </span>
      </div>
      <div
        className={
          !showCard
            ? "benefits-container"
            : "benefits-container show dropdown-animate"
        }
      >
        <h3 style={{ colour: "#2d7183" }} className="non-mobile">
          Benefits
        </h3>
        <BenefitsList
          data={filteredData}
          waitingPeriod={accessibility?.waitingPeriod}
        />
        {showCard && !accessibility?.waitingPeriod?.on && (
          <button
            className="mobile-version btn-mobile"
            onClick={() => setSeemore(true)}
          >
            see more
          </button>
        )}
      </div>
    </div>
  );
}

export default Benefits;
