import React from "react";
const providersDetails = [
  { header: "Date onboarded", subText: "12/04/2022" },
  { header: "Active Subscribers", subText: "2123" },
  { header: "Active Encounters", subText: "1,092" },
  { header: "Total Claims submitted", subText: "₦43,850,000.00" },
  { header: "Total Claims paid", subText: "₦43,850,000.00" },
];
const claimsDetails = [
  { header: "ECN  No", subText: "299282" },
  { header: "Provider", subText: "299282" },
  { header: "Subscriber", subText: "299282" },
  { header: "Date opened ", subText: "Active" },
  { header: "Date closed", subText: "Active" },
  { header: "Date Vetted", subText: "Active" },
  { header: "Amount billed", subText: "Active" },
  { header: "Amount Payed", subText: "Active" },
];
function ProviderCard({ type }) {
  return (
    <div className="provider-card">
      {type === "provider"
        ? providersDetails.map((item, i) => (
            <div key={i} className="provider-card-list">
              <h3>{item.header}</h3>
              <h6>{item.subText}</h6>
            </div>
          ))
        : type === "claim" &&
          claimsDetails.map((item, i) => (
            <div key={i} className="provider-card-list">
              <h3>{item.header}</h3>
              <h6>{item.subText}</h6>
            </div>
          ))}
    </div>
  );
}

export default ProviderCard;
