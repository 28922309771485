import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";
import logo from "../../assets/images/logo1.svg";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
function Nav({ showMenu, show }) {
  return (
    <div className="nav-bar">
      <div className="nav-top">
        <div>
          <img src={logo} alt="" className="logo" />
        </div>
        <div onClick={() => showMenu(false)}>
          <AiOutlineClose size="30" />
        </div>
      </div>
      <ul>
        <li>
          <a href="https://hadiel.com.ng/">
            <span>Home</span>
          </a>
        </li>
        <li>
          <a href="https://hadiel.com.ng/about/">
            <span>About Us</span>
          </a>
        </li>
        <li>
          <a href="https://hadiel.com.ng/solution/">
            <span>Our Solution</span>
          </a>
        </li>
        <li>
          <a href="https://hadiel.com.ng/contact/">
            <span>Contact</span>
          </a>
        </li>
      </ul>
      {show && (
        <div className="nav-end nav-menu">
          <Link to="/login">
            <span className="nav-btn">Sign In</span>
          </Link>
          <div className="nav-bottom">
            <FaFacebookF />
            <FaInstagram />
            <FaLinkedin />
          </div>
        </div>
      )}
    </div>
  );
}
function HeaderNav() {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div className="header-nav">
      <div className="logo-container">
        <img src={logo} alt="" className="logo" />
      </div>
      <HiOutlineMenuAlt4
        className="nav-menu"
        onClick={() => setShowMenu(!showMenu)}
      />
      <div className={!showMenu ? "no-menu" : ""}>
        <Nav showMenu={setShowMenu} show={showMenu} />
      </div>
      <div className="nav-end no-menu">
        <Link to="/login" style={{ textDecoration: "none" }}>
          <span className="nav-btn">Sign In</span>
        </Link>
        <div className="nav-bottom">
          <FaFacebookF />
          <FaInstagram />
          <FaLinkedin />
        </div>
      </div>
    </div>
  );
}
export default HeaderNav;
