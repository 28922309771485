import React from "react";
import SubscriberLogin from "./pages/Authentication/SubscriberLogin";
import Verification from "./pages/Authentication/Verification";
import ResetPassword from "./pages/Authentication/ResetPassword";
import Evaluation from "./pages/Authentication/Evaluation/index";
import SignUp from "./pages/Authentication/SignUp";
import Plan from "./pages/Plan/Plan";
import AdminLogin from "./pages/Admin/AdminLogin";
import AdminDashboard from "./pages/Admin/Admin_Dashboard";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
// import { useSelector } from "react-redux";

import "./assets/css/styles.scss";
import Dashboard from "./pages/Subscribers/Dashboard";
import ForgotPassword from "./pages/Authentication/ForgotPassword";
import CohortPlan from "./pages/Plan/CohortPlan";
import DashboardComponent from "./components/subscriber/dashboard/DashboardComponent";
import DashboardHeader from "./components/subscriber/general/DashboardHeader";
import useWindowWidth from "./hooks/useWindowWidth";
import MobileHome from "./components/subscriber/mobileHome/MobileHome";
import Subscription from "./components/subscriber/subscription/Subscription";
import Prescriptions from "./components/subscriber/prescription/Prescriptions";
import GroupMembers from "./components/subscriber/groupmembers/GroupMembers";
import Support from "./components/subscriber/Support";
import Setting from "./components/subscriber/setting/Setting";
import NotFound from "./pages/NotFound";
// const User = window.localStorage.getItem("user");
const Token = window.localStorage.getItem("token");
const App = () => {
  // let { token, user } = useSelector((state) => state.auth);
  const width = useWindowWidth();
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={Token ? <Navigate to="/dashboard" /> : <SignUp />}
        />
        <Route
          path="/login"
          element={Token ? <Navigate to="/dashboard" /> : <SubscriberLogin />}
        />
        <Route
          path="/admin-login"
          element={Token ? <AdminDashboard /> : <AdminLogin />}
        />
        <Route
          path="/verification"
          element={Token ? <Verification /> : <SubscriberLogin />}
        />
        <Route path="/forgot-Password" element={<ForgotPassword />} />
        <Route path="/evaluation" element={<Evaluation />} />
        <Route path="/admin-dashboard" element={<AdminDashboard />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/plan"
          element={Token ? <CohortPlan /> : <Navigate to="/login" />}
        />
        <Route
          path="/plans"
          element={Token ? <Plan /> : <Navigate to="/login" />}
        />
        <Route path="*" element={<NotFound />} />
        <Route
          path={"/dashboard"}
          element={
            Token ? (
              <Dashboard>
                <DashboardHeader type="normal" />
                {width > 600 ? <DashboardComponent /> : <MobileHome />}
              </Dashboard>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/dashboard/subscription"
          element={
            Token ? (
              <Dashboard>
                <DashboardHeader type="normal" />
                <Subscription />
              </Dashboard>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/dashboard/encounters"
          element={
            Token ? (
              <Dashboard>
                <DashboardHeader type="normal" />
                <Prescriptions />
              </Dashboard>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/dashboard/group members"
          element={
            Token ? (
              <Dashboard>
                <DashboardHeader type="group" />
                <GroupMembers />
              </Dashboard>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/dashboard/support"
          element={
            Token ? (
              <Dashboard>
                <Support />
              </Dashboard>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/dashboard/setting"
          element={
            Token ? (
              <Dashboard>
                <Setting />
              </Dashboard>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
      </Routes>
    </Router>
  );
};

// const SubscriberProtectedRoute = ({ path, element: Child }) => {
//   let { token, user } = useSelector((state) => state.auth);
//   const utoken = token || window.localStorage.getItem("token") || null;

//   if (utoken === null) {
//     return <Redirect to={"/"} />;
//   }

//   if (user.userType !== "SUBSCRIBER") {
//       const path = {
//         SUBSCRIBER: "/subscriber",
//         PROVIDER: "/provider",
//         ADMIN: "/admin",
//       };

//     const link = path[user.userType];
//     const link = "/dashboard";
//     return <Redirect to={link} />;
//   }

//   return (
//     <Route path={path}>
//       <Child />
//     </Route>
//   );
// };

// const AuthRoute = ({ path, element: Child }) => {
//   let { token, user } = useSelector((state) => state.auth);
//   const utoken = token || window.localStorage.getItem("token") || null;
//   const uuser = user || JSON.parse(window.localStorage.getItem("user")) || null;

//   if (utoken !== null) {

//     return <Redirect to={} />;
//   }

//   return (
//     <Route path={path}>
//       <Child />
//     </Route>
//   );
// };
export default App;
