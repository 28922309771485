// import "./profileCard.scss";

function UserBenefitCard() {
  return (
    <div className="UserBenefitCard">
      <nav className="benefits-nav">
        <ul>
          <li className="header-text">Unlimited Benefits</li>
          <li className="header-text">Limited Benefits</li>
        </ul>
      </nav>
      <div className="horizontal-line"></div>
      <div className="benefit-header">
        <ul>
          <li className="header-text">Benefits</li>
          <li className="header-text">Nō of times left to use</li>
        </ul>
      </div>
      <div className="benefit-list">
        <ul>
          <li>Malaria</li>
          <li>2</li>
        </ul>
      </div>
    </div>
  );
}

export default UserBenefitCard;
