import React, { useState, useEffect } from "react";
import "./SummaryCarousel.scss";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";

const CardCarousel = ({ cards }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? cards.length - 1 : prevIndex - 1
    );
    setFadeIn(true);
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === cards.length - 1 ? 0 : prevIndex + 1
    );
    setFadeIn(true);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeIn(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [fadeIn]);

  return (
    <div className="card-carousel">
      <div className="cards">
        <div className={`card ${fadeIn ? "fadeIn" : ""}`}>
          <h5>{cards[currentIndex].heading}</h5>
          <div className="dashboard-summary-item">
            <img src={cards[currentIndex].image} alt="" />
            <div>
              <h3>{cards[currentIndex].name}</h3>
              <h5>{cards[currentIndex].desc}</h5>
            </div>
          </div>
        </div>
      </div>
      <div className="controls">
        <button onClick={handlePrevClick}>
          <MdNavigateBefore fontSize={32} color="white" />
        </button>
        <div className="pagination">
          {cards.map((_, index) => (
            <div
              key={index}
              className={`pagination-dot ${
                index === currentIndex ? "active" : ""
              }`}
            ></div>
          ))}
        </div>
        <button onClick={handleNextClick}>
          <MdNavigateNext fontSize={32} color="white" />
        </button>
      </div>
    </div>
  );
};

export default CardCarousel;
