import { useState } from "react";
import AddBtn from "../buttons/AddBtn";
import Filter from "../Filter";
import SearchBox from "../SearchBox";
import Table from "../Table";
export const dataE = {
  heading: [
    "S/N",
    "Ecn. No",
    "Benefits Used",
    "Date Opened",
    "Date Closed",
    "Amount paid",
    "Status",
  ],
  list: [
    {
      num: "1",
      ecn: "299282",
      benefits: "Malaria, OCD. PWU...",
      dO: "02-10-2023",
      dC: "09-10-2023",
      paid: "N12,9302",
      stat: "active",
    },
  ],
};
function UserEcountersCard() {
  const [ setDetailsPage] = useState(false);
  const [ setProfileDetails] = useState(null);
  return (
    <div className="userEncounterCard">
      <div className="dashboard-top">
        <Filter />
        <SearchBox />
        <AddBtn text="Add Encounter" />
      </div>
      <Table
        data={dataE}
        setDetailsPage={setDetailsPage}
        setProfileDetails={setProfileDetails}
        type="encounters"
      />
    </div>
  );
}

export default UserEcountersCard;
