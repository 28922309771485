import Login from "../../components/auth/Login";

function AdminLogin() {
  return (
    <Login
      heading="Welcome to Hadiel"
      heading1="Health Coporative"
      text="Manage Subscribers and Provider all in one place"
      cardtext="Log into your account to manage your account"
      admin
    />
  );
}

export default AdminLogin;
