import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { activatePlanVoucher } from "../../redux/subscriber/reducer";
export default function VoucherPayment({ plan, type }) {
  const [voucherNum, setVoucherNum] = useState(null);
  const [phone, setPhone] = useState(null);
  const dispatch = useDispatch();
  const { groupDetails } = useSelector((state) => state.subscriber);
  const handleChange = (e) => {
    e.target.name === "card-number" && setVoucherNum(e.target.value);
    e.target.name === "phone" && setPhone(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    message.info("Thanks for doing business with us! Come back soon!!");
    dispatch(
      activatePlanVoucher({
        type: "Voucher",
        payment: { voucher_code: voucherNum, phone },
        cohort: { name: plan.planName, type, cost: plan.cost },
        groupInfo: groupDetails,
      })
    );
  };

  return (
    <>
      <div>
        <form className="payment-form" onSubmit={handleSubmit}>
          <div>
            <label>
              <span className="form-payment-label-voucher">
                <b>Voucher Serial Number</b>
              </span>
              <span className="form-payment-description-voucher">
                Enter the 14 dgit number on your voucher
              </span>
            </label>
            <input
              name="card-number"
              className="input-voucher"
              onChange={handleChange}
            />
          </div>
          <div>
            <label>
              <span className="form-payment-label-voucher">
                <b>User ID/Phone</b>
              </span>
              <span className="form-payment-description-voucher">
                Please enter User Id/Phone number
              </span>
            </label>
            <input name="phone" className="input-voucher" />
          </div>
          <div className="voucher-btn">
            <button className="payment-btn-voucher">Pay</button>
          </div>
          <br />
        </form>
      </div>
    </>
  );
}
