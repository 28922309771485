import { useState } from "react";
import next from "../../../../assets/images/dashboard/next.png";
import UpdateForm from "./UpdateForm";
import { MdOutlineArrowForwardIos, MdKeyboardBackspace } from "react-icons/md";
const securityMenuItem = ["Password", "Phone", "Email"];

function Security({ show }) {
  const [toggle, setToggle] = useState(1);
  const [newPage, setNewpage] = useState(true);
  const handleToggle = (id) => {
    setToggle(id);
    setNewpage(false);
  };
  return (
    <>
      <div className="security-container non-mobile">
        <div className="security-left">
          <h2>Update Security Settings</h2>
          <div className="security-left-items">
            <h5
              className={toggle === 1 ? "focus" : ""}
              onClick={() => handleToggle(1)}
            >
              <span>Password</span>
              <img src={next} alt="" />
            </h5>

            <h5
              className={toggle === 2 ? "focus" : ""}
              onClick={() => handleToggle(2)}
            >
              <span>Phone</span>
              <img src={next} alt="" />
            </h5>

            <h5
              className={toggle === 3 ? "focus" : ""}
              onClick={() => handleToggle(3)}
            >
              <span>Email </span>
              <img src={next} alt="" />
            </h5>
          </div>
        </div>
        <div className="security-right">
          {toggle === 1 && <UpdateForm type="Password" />}
          {toggle === 2 && <UpdateForm type="Phone" />}
          {toggle === 3 && <UpdateForm type="Email" />}
        </div>
      </div>
      <div className=" mobile-version security-container">
        {newPage ? (
          <>
            <div className="mobile" onClick={() => show(true)}>
              <span>
                <MdKeyboardBackspace size="24px" />
              </span>
              <h2>Update Security</h2>
            </div>
            {securityMenuItem.map((item, i) => (
              <div
                className={
                  toggle === i + 1
                    ? "mobile setting-tab-mobile  focus"
                    : "mobile setting-tab-mobile"
                }
                key={i}
                onClick={() => handleToggle(i + 1)}
              >
                <span>{item}</span>
                <span>
                  <MdOutlineArrowForwardIos />
                </span>
              </div>
            ))}
          </>
        ) : (
          <div className="security-right">
            {toggle === 1 && (
              <UpdateForm backTomenu={setNewpage} type="Password" />
            )}
            {toggle === 2 && (
              <UpdateForm backTomenu={setNewpage} type="Phone" />
            )}
            {toggle === 3 && (
              <UpdateForm backTomenu={setNewpage} type="Email" />
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default Security;
