import encounterImg from "../../../assets/images/dashboard/Top Encounter.png";
import amountImg from "../../../assets/images/dashboard/Top Amont paid.png";
import cohortplanImg from "../../../assets/images/dashboard/Top Total med.png";
import SubscriptionCard from "../../subscriber/subscription/SubscriptionCard";
function UserSubscription() {
  const dashboardSummary = [
    {
      heading: "Premium",
      name: "₦25,440.00/ Yr",
      image: cohortplanImg,
      desc: "Benefits last until 20-08-2022",
    },
    {
      heading: "Amount Paid",
      name: "₦1,000.00",
      image: amountImg,
      desc: "Renew by 30/06/2022",
    },
    {
      heading: "Amount used",
      name: "₦21,000.00",
      image: encounterImg,
      desc: "Total as of 30/06/2022",
    },
  ];
  return (
    <div className="userSubscription">
      <div className="header-Summary">
        {dashboardSummary.map((item, i) => {
          return (
            <div key={i}>
              <h5>{item.heading}</h5>
              <div className="dashboard-summary-item">
                <img src={item.image} alt="" />
                <div>
                  <h3>{item.name}</h3>
                  <h5>{item.desc}</h5>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div>
        <SubscriptionCard />
      </div>
    </div>
  );
}

export default UserSubscription;
