import React, { useState } from "react";
import cohortPlan from "../../assets/images/cohort.png";
import avatar from "../../assets/images/avatar.png";
import individual from "../../assets/images/individual.svg";
import group from "../../assets/images/Group.svg";
import "../Authentication/auth.scss";
import HeaderNav from "../../components/auth/Nav";
import NotificationCard from "../../components/subscriber/general/NotificationCard";
import { Link } from "react-router-dom";

const CohortPlan = () => {
  const [notify, setNotify] = useState(false);
  return (
    <>
      {notify && <NotificationCard setNotify={setNotify} />}
      <section className="signUp-container">
        <HeaderNav />
        <div className="signUp-wrapper">
          <div className="signUp-left">
            <h1>Worried about</h1>
            <h1>extended households?</h1>
            <p>Our plans enable you to include anyone and everyone</p>
            <p style={{ marginTop: 0 }}>as a family or group.</p>
            <p className="signUp-left-provider">
              Are you a provider? Click here
            </p>
            <img src={cohortPlan} alt="" width="68%" />
          </div>
          <div className="signUp-right">
            <div className="verify-container">
              <img src={avatar} alt="" className="signUp-right-avater" />
              <p className="verify-text">
                Please select a plan type that suits your need(s)
              </p>
              <div className="cohort-opt">
                <div
                  className="cohort-card"
                  onClick={() => {
                    setNotify(true);
                  }}
                >
                  <div>
                    <img src={individual} alt="" className="img-cohort" />
                  </div>
                  <h3>Join a group</h3>
                  <p>
                    Don't have or want to start a group but don't want to miss
                    out? Join a community where recourses will be put together
                    for better health benefits
                  </p>
                </div>

                <div className="cohort-card1">
                  <Link
                    to="/plans"
                    state="Group"
                    style={{ textDecoration: "none" }}
                  >
                    <div>
                      <img src={group} alt="" className="img-cohort" />
                    </div>
                    <h3>Start a group</h3>
                    <p>
                      Do you already have a group or plan on growing one? Create
                      a community where recourses will be put together for
                      better health benefits?
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CohortPlan;
