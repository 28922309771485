import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { local, header, handleError } from "../../services/helper";

export const registerUser = createAsyncThunk(
  "users/register",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(local + "/signup", payload);
      payload.password = "";
      window.localStorage.setItem("userFormData", JSON.stringify(payload));
      if (response) {
        window.localStorage.setItem("token", response.data.token);
        window.localStorage.setItem(
          "user",
          JSON.stringify({
            userId: response.data.user,
            otpId: response.data.otpId,
          })
        );
        const subscriberId =
          typeof response.data.subscriber === "object"
            ? response.data.subscriber.id
            : typeof response.data.subscriber === "string" &&
              response.data.subscriber;
        window.localStorage.setItem("subscriberId", subscriberId);

        return response.data;
      }
    } catch (error) {
      const e = handleError(error);
      return rejectWithValue(e);
    }
  }
);

export const loginUser = createAsyncThunk(
  "users/login",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(local + "/login", payload);
      if (response) {
        window.localStorage.setItem("token", response.data["token"]);
        window.localStorage.setItem(
          "user",
          JSON.stringify(response.data["user"])
        );
        const subscriberId =
          typeof response.data.subscriber === "object"
            ? response.data.subscriber.id
            : typeof response.data.subscriber === "string" &&
              response.data.subscriber;
        window.localStorage.setItem("subscriberId", subscriberId);
        return response.data;
      }
    } catch (error) {
      const e = handleError(error);
      return rejectWithValue(e);
    }
  }
);

export const ResendOtp = createAsyncThunk(
  "users/resend-otp",
  async (payload, { rejectWithValue }) => {
    try {
      let authHeader = {
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("token"), //the token is a variable which holds the token
        },
      };

      const response = await axios.get(
        `${local}/otp/${payload.initiatedOnId}`,
        authHeader
      );
      if (response) {
        window.localStorage.setItem(
          "user",
          JSON.stringify({
            userId: response.data.user,
            otpId: response.data.otpId,
          })
        );
        return response.data;
      }
    } catch (error) {
      const e = handleError(error);
      return rejectWithValue(e);
    }
  }
);

export const verification = createAsyncThunk(
  "users/verification",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(local + "/otp", payload, header());
      return response.data;
    } catch (error) {
      const e = handleError(error);
      return rejectWithValue(e);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "user/forgot-password",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(local + "/verify-phone", payload);
      if (response.data) {
        window.localStorage.setItem("token", response.data.token);
        window.localStorage.setItem(
          "user",
          JSON.stringify({
            userId: response.data.user,
            otpId: response.data.otpId,
          })
        );

        return response.data;
      }
    } catch (error) {
      const e = handleError(error);
      return rejectWithValue(e);
    }
  }
);

export const changePassword = createAsyncThunk(
  "user/changePassword",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        local + "/change-password",
        payload,
        header()
      );
      return response.data;
    } catch (error) {
      const e = handleError(error);
      return rejectWithValue(e);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.put(local + "/password", payload, header());
      if (response.data.success) {
        window.localStorage.setItem("token", null);
        window.localStorage.setItem("user", null);
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("user");
      }
      return response.data;
    } catch (error) {
      const e = handleError(error);
      return rejectWithValue(e);
    }
  }
);

export const forgotPasswordOtp = createAsyncThunk(
  "user/forgotPasswordOtp",
  async (payload, { rejectWithValue }) => {
    try {
      let authHeader = {
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("token"), //the token is a variable which holds the token
        },
      };
      const response = await axios.post(local + "/otp", payload, authHeader);
      return response.data;
    } catch (error) {
      const e = handleError(error);
      return rejectWithValue(e);
    }
  }
);

const initialState = {
  loading: false,
  success: false,
  error: null,
  user: window.localStorage.getItem("user") || {},
  token: window.localStorage.getItem("token") || null,
  userInfo: {},
};
const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    logout: (state) => {
      window.localStorage.setItem("token", null);
      window.localStorage.setItem("user", null);
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("user");
      state.token = null;
      state.user = {};
      window.location.href = "/login";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.user = payload.user;
        state.token = payload.token;
        if (state.success) window.location.href = "/dashboard";
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.success = false;
        state.error = payload;
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true; // registration successful
        state.user = payload.user;
        state.token = payload.token;
        if (state.success) window.location.href = "verification";
      })
      .addCase(registerUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.success = false;
        state.error = payload;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(forgotPassword.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.user = payload.user;
        state.token = payload.token;
      })
      .addCase(forgotPassword.rejected, (state, { payload }) => {
        state.loading = false;
        state.success = false;
        state.error = payload;
      })
      .addCase(verification.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verification.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        if (state.success) window.location.href = "plan";
      })
      .addCase(verification.rejected, (state, { payload }) => {
        state.loading = false;
        state.success = false;
        state.error = payload;
      })
      .addCase(forgotPasswordOtp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(forgotPasswordOtp.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        if (state.success) window.location.href = "reset-password";
      })
      .addCase(forgotPasswordOtp.rejected, (state, { payload }) => {
        state.loading = false;
        state.success = false;
        state.error = payload;
      })
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        if (state.success) window.location.href = "login";
      })
      .addCase(resetPassword.rejected, (state, { payload }) => {
        state.loading = false;
        state.success = false;
        state.error = payload;
      });
  },
});
export const { logout } = authSlice.actions;
export default authSlice.reducer;
