import React from "react";
import "./CardHeader.scss";
function CardHeader({ name, id, subtitle, subtext }) {
  return (
    <div className="card-header-table">
      <div>
        <h2>{name}</h2>
        <p>{id}</p>
      </div>
      <div>
        <p style={{ textAlign: "right" }}>{subtitle}</p>
        <h2 style={{ textAlign: "right" }}>{subtext}</h2>
      </div>
    </div>
  );
}

export default CardHeader;
