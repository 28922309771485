import authReducer from "./authentication/reducer";
import subscriberReducer from "./subscriber/reducer";
import settingReducer from "./settings/reducer";
import { configureStore } from "@reduxjs/toolkit";

export const store = configureStore({
  devTools: true,
  reducer: {
    auth: authReducer,
    subscriber: subscriberReducer,
    setting: settingReducer,
  },
});
