import React, { useState } from "react";
import signUpImg from "../../assets/images/signup-pic.png";
import avatar from "../../assets/images/avatar.png";
import "./auth.scss";
import { Link } from "react-router-dom";
import { BsPersonFill, BsPhoneFill } from "react-icons/bs";
import { AiTwotoneMail } from "react-icons/ai";
import { RiLockPasswordFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Spin } from "antd";
import HeaderNav from "../../components/auth/Nav";
import { registerUser } from "../../redux/authentication/reducer";

const errorMessage = {
  name: "Name should be 3-16 characters and should not include special character",
  email: "Enter valid email address",
  phone: "Enter valid phone number",
  password: "Password should be at least 8 character.",
};
const SignUp = () => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [email, setEmail] = useState(false);
  const [password, setPassword] = useState(false);
  const [firstName, setFirstName] = useState(false);
  const [lastName, setLastName] = useState(false);
  const [phone, setPhone] = useState(false);
  const [formInput, setFormInput] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
  });
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.auth);

  // Handle input validations
  const handleFocus = (e) => {
    const { name } = e.target;
    const rePassword = /(?=^.{8,}$)(?![.\n]).*$/;
    const reText = /^[A-Za-z0-9\s]{3,24}$/;
    const reEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const rePhone = /^\d{11}$/;
    name === "firstName" && setFirstName(!reText.test(formInput.firstName));
    name === "lastName" && setLastName(!reText.test(formInput.lastName));
    name === "phone" && setPhone(!rePhone.test(formInput.phone));
    name === "email" && setEmail(!reEmail.test(formInput.email));
    name === "password" && setPassword(!rePassword.test(formInput.password));
  };

  // Handle form inputs
  const handleClick = (e) => {
    e.preventDefault();
    dispatch(registerUser(formInput));
  };

  // Store form input in state
  const handleChange = ({ target: { name, value } }) => {
    setFormInput((prev) => {
      return { ...prev, [name]: value };
    });
  };
  return (
    <section className="signUp-container">
      <HeaderNav />
      <div className="signUp-wrapper">
        <div className="signUp-left">
          <h1>Join the health</h1>
          <h1>community today</h1>
          <p>Get a sachet plan for essential health and primary care</p>
          <p className="signUp-left-provider">Are you a provider? Click here</p>
          <img src={signUpImg} alt="" className="signUp-img" />
        </div>
        <div className="signUp-right">
          <div className="signUp-right-container">
            <img src={avatar} alt="" className="signUp-right-avater" />
            <h2>
              Hi, I’m Kenny<span>, your onboarding assistant</span>
            </h2>
            <p>Here to guide you through your signup process</p>
            {error && <span className="err-message">{error}</span>}
            <form className="form" id="signup-form">
              {firstName || lastName ? (
                <span className="err-message">{errorMessage.name}</span>
              ) : phone ? (
                <span className="err-message">{errorMessage.phone}</span>
              ) : (
                email && (
                  <span className="err-message">{errorMessage.email}</span>
                )
              )}
              <div className="form-top">
                <div className="form-item">
                  <div
                    className={firstName ? "err-box input-box" : "input-box"}
                  >
                    <BsPersonFill className="icon" />
                    <input
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      onChange={handleChange}
                      onBlur={handleFocus}
                      value={formInput.firstName}
                      pattern="^[A-Za-z0-9\s]{3,24}$"
                      required
                    />
                  </div>
                </div>
                <div className="form-item">
                  <div className={lastName ? "err-box input-box" : "input-box"}>
                    <BsPersonFill className="icon" />
                    <input
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      onChange={handleChange}
                      onBlur={handleFocus}
                      value={formInput.lastName}
                      pattern="^[A-Za-z0-9\s]{3,24}$"
                      required
                    />
                  </div>
                </div>
                <div className="form-item">
                  <div className={phone ? "err-box input-box" : "input-box"}>
                    <BsPhoneFill className="icon" />
                    <input
                      type="tel"
                      placeholder="Phone"
                      name="phone"
                      onChange={handleChange}
                      onBlur={handleFocus}
                      value={formInput.phone}
                      pattern="^\d{11}$"
                      required
                    />
                  </div>
                </div>
                <div className="form-item">
                  <div className={email ? "err-box input-box" : "input-box"}>
                    <AiTwotoneMail className="icon" />
                    <input
                      type="email"
                      placeholder="Email address"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleFocus}
                      value={formInput.email}
                      pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="form-bottom">
                <div className={password ? "err-box" : ""}>
                  <RiLockPasswordFill className="icon" />
                  <input
                    type={!togglePassword ? "password" : "text"}
                    name="password"
                    onChange={handleChange}
                    onBlur={handleFocus}
                    placeholder="Password"
                    value={formInput.password}
                    minlength="8"
                    required
                  />
                  <i onClick={() => setTogglePassword(!togglePassword)}>
                    {!togglePassword ? (
                      <AiOutlineEyeInvisible
                        style={{ fontSize: "1.6rem", padding: "0px 4px" }}
                      />
                    ) : (
                      <AiOutlineEye
                        style={{ fontSize: "1.6rem", padding: "0px 4px" }}
                      />
                    )}
                  </i>
                </div>
                <span
                  style={{ textAlign: "left", margin: "-20px 0px 10px 15px" }}
                  className="err-message"
                >
                  {errorMessage.password}
                </span>
              </div>
              <button
                loading={loading.toString()}
                className="btn-custom"
                onClick={handleClick}
              >
                {loading ? <Spin /> : "Sign up"}
              </button>
              <p>
                Already have an account?
                <Link
                  to="/login"
                  onClick={() => error && (window.location.href = "/")}
                  className="b-link"
                >
                  <b> Sign in</b>
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
