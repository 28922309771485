import React from "react";
import { Progress } from "antd";
import { Row, Col } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
function ProgressBar() {
  const navigate = useNavigate();
  const handleComparePlan = () => {
    navigate("/plans", {
      state: "comparePlans",
    });
  };
  const { subscription } = useSelector((state) => state.subscriber);
  return (
    <div className="progress-card">
      <Row className="card-top">
        <Col>
          <h3>Current plan</h3>
        </Col>
        <Col>
          <span onClick={handleComparePlan}>View plans</span>
        </Col>
      </Row>
      <Row>
        <span className="progress-bar-text">{subscription.planName}</span>
        <Progress
          percent={Math.round(
            (subscription?.totalPaid / subscription.annualPremium) * 100
          )}
          size="small"
          status="active"
        />
      </Row>

      <div className="card-bottom">Payment progress</div>
    </div>
  );
}

export default ProgressBar;
