import { useState } from "react";
import nopics from "../../assets/images/no-picture.jpg";
import { ReactComponent as Camera } from "../../assets/images/dashboard/cam.svg";
import { useDispatch } from "react-redux";
import { getSubscription, uploadImage } from "../../redux/subscriber/reducer";

function PreViewImage({ profileUrl }) {
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const subscriberId = window.localStorage.getItem("subscriberId");
  const dispatch = useDispatch();
  const handlechange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
  };
  const handleUpload = (e) => {
    if (image) {
      const file = new FormData();
      file.append("photo", image);
      dispatch(
        uploadImage({
          payload: file,
          subscriberId,
        })
      );
      setImage(null);
      setPreviewImage(null);
      setTimeout(() => dispatch(getSubscription(subscriberId)), 1000);
    }
  };
  return (
    <>
      {previewImage && (
        <>
          <div className="overlay"></div>
          <div className="preview">
            <img src={previewImage} alt="" className="preImg" />
            <div className="btn-preview">
              <button
                onClick={() => {
                  setImage(null);
                  setPreviewImage(null);
                }}
              >
                Cancel
              </button>
              <button className="" onClick={handleUpload}>
                Upload
              </button>
            </div>
          </div>
        </>
      )}
      <div className="img-container">
        <img src={profileUrl || nopics} alt="" />
        <form className="cam">
          <input
            style={{ display: "none" }}
            id="file"
            type="file"
            accept=".png,.jpg,.jpeg"
            onInput={handlechange}
          />
          <label htmlFor="file">
            <Camera fontSize={24} color="white" />
          </label>
        </form>
      </div>
    </>
  );
}

export default PreViewImage;
