import React from "react";
import avatar from "../../assets/images/avatar.png";
import otpImg from "../../assets/images/otp-img.png";
import HeaderNav from "../../components/auth/Nav";
import Otp from "../../components/auth/Otp";
import { useSelector } from "react-redux";
import "./auth.scss";

const Verification = () => {
  const { error, loading } = useSelector((state) => state.auth);
  return (
    <section className="signUp-container">
      <HeaderNav />
      <div className="signUp-wrapper">
        <div className="signUp-left">
          <h1>Always on the move?</h1>
          <p>Access care from a facility in your vicinity when</p>
          <p style={{ marginTop: 0 }}>you roam nationwide</p>
          <p className="signUp-left-provider">Are you a provider? Click here</p>
          <img src={otpImg} alt="" className="otp-img" />
        </div>
        <div className="signUp-right">
          <div className="verify-container">
            <img src={avatar} alt="" className="signUp-right-avater" />
            <p className="verify-text">
              <span>
                <b className="bold-t">Hi!,</b> I just sent a code to your phone
                number
              </span>
              <br />
              {error && (
                <>
                  <span className="err-message">{error}</span>
                  <br />
                </>
              )}
              <span>
                <b>Enter the code below</b>
              </span>
            </p>
            <Otp verify="signUP" loading={loading} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Verification;
