import { AiOutlineClose } from "react-icons/ai";
import Search from "./Search";
function ModalMobileBox({
  show,
  setSeemore,
  Component,
  title,
  setFilteredData,
  filteredData,
  data,
}) {
  return (
    <div className="modal-container">
      <div style={{ textAlign: "right" }}>
        <span style={{ fontSize: "22px" }} onClick={() => setSeemore(false)}>
          <AiOutlineClose />
        </span>
      </div>
      <h2 className="mobile-header modal-title"> {title}</h2>
      <div style={{ marginBottom: "20px" }}>
        <Search
          setFilteredData={setFilteredData}
          filteredData={filteredData}
          data={data}
        />
      </div>
      <Component seemore={show} data={filteredData} />
    </div>
  );
}
export default ModalMobileBox;
