import React from "react";
import "./NotificationCard.scss";
import { Link } from "react-router-dom";

function NotificationCard({ setNotify }) {
  return (
    <>
      <div className="overlay"></div>
      <div className="notification">
        <div className="close">
          <button onClick={() => setNotify(false)}>Close</button>
        </div>
        <h3>Notice</h3>
        <p>
          Please note that you will automatically be placed in a group based on
          your selected sachet plan from the next frame. This allocation will
          only happen when you are activating a subscription plan.
        </p>
        <p>
          If you want to build your group, kindly close this prompt and select
          ‘Start a group’ else ‘Continue’
        </p>
        <Link
          to="/plans"
          state="Individual"
          onClick={() => {
            setNotify(false);
          }}
        >
          Continue
        </Link>
      </div>
    </>
  );
}

export default NotificationCard;
