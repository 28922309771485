import GroupMemberMobileCard from "./GroupMemberMobileCard";
import AddGroupMember from "./AddGroupMember";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import noMembersImg from "../../../assets/images/nodata/prescription-nodata.png";
import { useEffect } from "react";
import Tables from "../general/Tables";
import PaymentForm from "../../Payment/PaymentForm";
import { Spin } from "antd";
import { plans } from "../../../pages/Plan/Plan";
import "./GroupMembers.scss";
import BackNav from "../../global/BackNav";
import { getGroupMembers } from "../../../redux/subscriber/reducer";
import Setting from "../setting/Setting";
import { MdOutlineClose } from "react-icons/md";

function GroupMembers() {
  const [popup, setPopup] = useState(false);
  const [pay, setPay] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [groupmember, setGroupmember] = useState(null);
  const [showdetails, setShowdetails] = useState(false);
  const dispatch = useDispatch();
  const { group, groupDetails, subscription, loading } = useSelector(
    (state) => state.subscriber
  );
  useEffect(() => {
    dispatch(getGroupMembers());
  }, [dispatch]);
  const data = {
    heading: [
      "S/N",
      "ID Number",
      "Name",
      "Sex",
      "Phone/Email",
      "Plan Status",
      "Payment",
      "Total Paid",
      "Spent",
      "Is Active",
      "",
      "",
    ],
    list: group,
  };

  const selectPlan = (subscription) => {
    const groupPlanName = plans.filter((item) => {
      return item.name === subscription.subscriber.groupPlanName;
    });
    return {
      planName: groupPlanName[0]?.planName,
      cost: groupPlanName[0]?.cost,
      type: subscription?.planType,
    };
  };
  //handle payment for activating or sponsoring accounts
  const handlePay = (item) => {
    const paymentType = item?.subscriber.hasSubscription
      ? "Sponsor"
      : "Activate";
    setShowModal(true);
    setPay(paymentType);
    setGroupmember(item?.subscriber.id);
  };
  const selectedPlan = selectPlan(subscription);
  const getGroupMemberID = (Id) => {
    setShowdetails(true);
    setGroupmember(Id);
  };
  return (
    <>
      <BackNav text="Group" />
      {showdetails && (
        <>
          <div className="overlay"></div>
          <div className="detailsContainer">
            <div className="close" onClick={() => setShowdetails(false)}>
              <MdOutlineClose />
            </div>
            <Setting groupmemberId={groupmember} />
          </div>
        </>
      )}
      {showModal && (
        <>
          <div className="overlay"></div>
          <div className="payment-card">
            <PaymentForm
              plan={selectedPlan}
              type={groupDetails.type}
              subscriberId={groupmember}
              groupPlan={selectedPlan}
              paymentFor={pay}
            />
            <div style={{ textAlign: "center" }}>
              <button onClick={() => setShowModal(false)}>Close</button>
            </div>
          </div>
        </>
      )}
      <div className="group-member non-mobile">
        {popup && (
          <>
            <div className="overlay"></div>
            <AddGroupMember setPopup={setPopup} />
          </>
        )}

        <div className="member-card-top">
          <span>Members</span>
          {/* {subscription?.subscriber?.isPrincipal &&
            groupDetails.type === "Closed" &&
            groupDetails.plan === "COHORT 0" && (
              <button>Upgrade Group Plan</button>
            )} */}
          {subscription?.subscriber?.isPrincipal &&
            subscription?.planType === "Closed" && (
              <button onClick={() => setPopup(true)}>+Add members</button>
            )}
        </div>
        {group ? (
          <div className="group-member-table">
            <span className="sub-head">
              Group ID - {subscription.subscriber.groupId}
            </span>
            <Tables
              data={data}
              viewer={subscription.id}
              type="groupMembers"
              handlePay={handlePay}
              setShowdetails={getGroupMemberID}
              planType={subscription?.planType}
            />
          </div>
        ) : (
          <div className="no-data">
            {loading ? (
              <div style={{ position: "absolute", top: "50%" }}>
                <Spin />
              </div>
            ) : (
              <>
                <img src={noMembersImg} alt="" />
                <h1 className="no-data-text">No Members</h1>
              </>
            )}
          </div>
        )}
      </div>
      <div className="member-mobile">
        <GroupMemberMobileCard
          group={group}
          groupDetails={groupDetails}
          viewer={subscription}
          handlePay={handlePay}
          showModal={showModal}
          setShowdetails={getGroupMemberID}
          planType={subscription?.planType}
        />
      </div>
    </>
  );
}

export default GroupMembers;
