import { useState } from "react";
import nopics from "../../../assets/images/no-picture.jpg";
import printimg from "../../../assets/images/dashboard/print.svg";
import PrintEcard from "../general/PrintEcard";
import { RiArrowDropUpLine, RiArrowDropDownLine } from "react-icons/ri";
import { useSelector } from "react-redux";
function Ecard() {
  const {
    subscriber: { user, policyNumber, profileUrl },
  } = useSelector((state) => state.subscriber.subscription);
  const [showCard, setShowCard] = useState(false);
  const [seemore, setSeemore] = useState(false);
  const [print, setPrint] = useState(false);
  return (
    <>
      {print && (
        <div style={{ marginTop: "-60px" }}>
          <div className="overlay"></div>
          <PrintEcard setPrint={setPrint} print={print} />
        </div>
      )}
      <div className="ecard">
        <div className="mobile">
          <span className="mobile-header">E-Card</span>
          <span onClick={() => setShowCard(!showCard)}>
            {!showCard ? (
              <span onClick={() => setShowCard(!showCard)}>
                <RiArrowDropDownLine size="28px" />
              </span>
            ) : (
              <span onClick={() => setShowCard(!showCard)}>
                <RiArrowDropUpLine size="28px" />
              </span>
            )}
          </span>
        </div>
        <div
          className={
            !showCard
              ? "ecard-container"
              : "ecard-container show dropdown-animate"
          }
        >
          <span className="mobile">
            <img src={printimg} alt="" />
          </span>
          <div className="img-container">
            <img src={profileUrl || nopics} alt="" className="p-img" />
          </div>
          <div className="ecard-body">
            <div>
              <div className="flex-content">
                <h2> Hadiel E-Card</h2>
                <span className="non-mobile">
                  <img
                    src={printimg}
                    alt=""
                    className="print"
                    onClick={() => setPrint(true)}
                  />
                </span>
              </div>
              <div className="ecard-body-middle">
                <h5>Full Name</h5>
                <span>{user?.username ? user?.username : "--"}</span>
              </div>
              <div>
                <h5>Email</h5>
                <span>{user?.email ? user?.email : "--"}</span>
              </div>
            </div>
            <div
              className={!seemore ? "ecard-end" : "ecard-end mobile-version"}
            >
              <div className="ecard-body-middle">
                <h5>ID Number</h5>
                <span>{policyNumber ? policyNumber : "--"}</span>
              </div>
              <div className="ecard-body-middle">
                <h5>Phone</h5>
                <span>{user?.phone ? "0" + user?.phone : "--"}</span>
              </div>
            </div>
            <button className="btn-mobile" onClick={() => setSeemore(!seemore)}>
              {!seemore ? "see more" : "see less"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Ecard;
