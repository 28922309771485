import React from "react";
import ReactDOM from "react-dom/client";
import "antd/dist/reset.css";
import Route from "./routes";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import { store } from "./redux/store";
import { Provider } from "react-redux";
// axios.defaults.headers.common['Authorization'] = 'AUTH_TOKEN';

axios.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Route />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
