import React from "react";
import { TiTick } from "react-icons/ti";
import { benefits } from "./benefits";

function MobilePlan({
  planName,
  cost,
  cohort,
  divider,
  animate,
  showModal,
  comparePlans,
}) {
  const plan = { name: planName, cost, planName: cohort };
  const cohortType = cohort.split(" ").join("").toLowerCase();
  return (
    <div
      className={
        animate === "left"
          ? "slideLeft"
          : animate === "right"
          ? "slideRight"
          : animate === "right1"
          ? "slideRight1"
          : animate === "left1" && "slideLeft1"
      }
    >
      <div
        className={
          divider ? "mobile-plan table-divider" : "mobile-plan table-d"
        }
      >
        <h3 className="plan-name">{planName}</h3>
        <h3 className="plan-price">{"₦" + cost}</h3>
        <h5 className="plan-duration">Monthly</h5>
        {comparePlans !== "comparePlans" ? (
          <span className="plan-selection" onClick={() => showModal(plan)}>
            select
          </span>
        ) : (
          <></>
        )}
        <ul>
          {benefits.map((item, i) => {
            if (item[cohortType] !== "") {
              return (
                <li key={i}>
                  <span className="benefits-mobile">{item.name}</span>
                  <span className="tick">
                    {item[cohortType] === "TiTick" ? (
                      <TiTick />
                    ) : (
                      item[cohortType] !== ("" && "TiTick") && item[cohortType]
                    )}
                  </span>
                </li>
              );
            } else {
              return null;
            }
          })}
        </ul>
      </div>
    </div>
  );
}

export default MobilePlan;
