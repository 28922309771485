import React from "react";
import noPrescriptionImg from "../../../assets/images/nodata/prescription-nodata.png";
import Tables from "../general/Tables";
// const data = {
//   heading: ["S/N", "ECN No.", "Provider", "Date ", "Time"],
//   list: [
//     {
//       num: "1",
//       ecn: "299282",
//       provider: "ST LUKES",
//       date: "Wed, 31 May, 2023",
//       time: "11.00am",
//     },
//   ],
// };
const data = null;
function AppointmentTable() {
  return (
    <div className="appointment-table">
      {data ? (
        <Tables data={data} type="appointments" />
      ) : (
        <div className="no-data">
          <img src={noPrescriptionImg} alt="" style={{ width: "20%" }} />
          <h1 className="no-data-text">No Appointment</h1>
        </div>
      )}
    </div>
  );
}

export default AppointmentTable;
