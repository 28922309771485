import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Steps } from "antd";
import { useDispatch } from "react-redux";
import { Button, Radio, Result } from "antd";
import { typeOptions, questionsArray, questionsComputation } from "./data";
import EvaluationForm from "./EvaluationForm";

const { Step } = Steps;
const Evaluation = () => {
  const [current, setCurrent] = useState(0);
  const [setLoading] = useState(false);
  const [data, setData] = useState({});
  const [evaluationData, setEvaluationData] = useState(questionsComputation);
  const dispatch = useDispatch();


  const handleChange = ({ target: { name, value } }) => {
    setLoading(false);
    const form = {};
    form[name] = value;
    dispatch({
      type: "HANDLE_CHANGE",
      payload: form,
    });
  };

  const handleChangeEvaluation = ({ target: { name, value } }) => {
    const foo = {};
    foo[name] = value;

    const newData = { ...data, ...foo };

    setData(newData);
    const comp = {};
    comp[questionsArray[name]] = value;
    const compute = { ...evaluationData, ...comp };
    setEvaluationData(compute);

    handleChange({
      target: { name: "data", value: newData },
    });
  };

  const next = () => {
    setCurrent(current + 1);
  };
  const submitForComputaion = () => {
    // dispatch({
    //   type: 'SUBMIT_FOR_COMPUTATION',
    //   payload: evaluationData,
    // });
    next();
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  return (
    <>
      <div className="container registration">
        <h2>
          Evaluation Form
          <br />
          <small>
            Please enter your details below. This should only take a couple of
            minutes.
          </small>
        </h2>
        <div className="form">
          <Steps
            direction="vertical"
            progressDot
            size="small"
            current={current}
          >
            <Step
              title="Who are you planning for?"
              description={
                <WhoHasIt
                  next={next}
                  step={current}
                  handleChangeEvaluation={handleChangeEvaluation}
                  prev={prev}
                />
              }
            />
            <Step
              title="Health Plan Evaluation"
              description={
                <EvaluationForm
                  next={submitForComputaion}
                  step={current}
                  back={prev}
                  state={data}
                  handleChangeEvaluation={handleChangeEvaluation}
                />
              }
            />
            <Step
              title="THANK YOU!"
              description={<EvaluationRates step={current} next={next} />}
            />

            {/* <Step
              title="Create Account"
              description={
                <SignUpForm
                  next={next}
                  step={current}
                  loading={loading}
                  handleChange={handleChange}
                  handleClick={handleClick}
                  prev={prev}
                />
              }
            /> */}
          </Steps>
        </div>
      </div>
    </>
  );
};
const WhoHasIt = ({ step, handleChangeEvaluation, next }) => {
  if (step !== 0) return null;
  return (
    <div className="WhoHasIt">
      <div className="radios">
        <Radio.Group
          name="0"
          options={typeOptions}
          direction="vertical"
          onChange={handleChangeEvaluation}
        />
      </div>

      <Button onClick={next} className="btn primary btn-sm ">
        Next
      </Button>
    </div>
  );
};

const EvaluationRates = ({ step, next }) => {
  if (step !== 2) return null;

  return (
    <Result
      status="success"
      title="Health Evaluation Successful"
      subTitle={`Thank you for filling out the evaluation form`}
      // subTitle={`We recommend our ${Plan} health care plan of ${Pricing} Naira only monthly`}
      extra={[
        <Link
          to="/subscriber"
          className="btn primary btn-sm "
          style={{
            color: "white",
            padding: "10px 20px",
          }}
          onClick={next}
          key="console"
        >
          {" "}
          Get Started
        </Link>,
      ]}
    />
  );
};

export default Evaluation;
