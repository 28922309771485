import React from "react";
import { FiSettings } from "react-icons/fi";
import { TbFirstAidKit } from "react-icons/tb";
import { IoNotificationsOutline } from "react-icons/io5";
import { HiOutlineUserGroup } from "react-icons/hi";
import { BiSupport } from "react-icons/bi";
import { ReactComponent as Subscribe } from "../../../assets/images/dashboard/subscribe.svg";
import { Link } from "react-router-dom";
import "./MobileHome.scss";
function MobileHome() {
  return (
    <div className="mobileHomeContainer">
      <div className="mobileHomeItem">
        <Link to="/dashboard/subscription">
          <Subscribe color="#1EAFAB" className="icon" />
          <h5 className="sub-heading">Subscription</h5>
        </Link>
      </div>
      <div className="mobileHomeItem">
        <Link to="/dashboard/encounters">
          <TbFirstAidKit color="#1EAFAB" className="icon" />
          <h5 className="sub-heading">Encounter</h5>
        </Link>
      </div>
      <div className="mobileHomeItem">
        <Link to="/dashboard/group members">
          <HiOutlineUserGroup color="#1EAFAB" className="icon" />
          <h5 className="sub-heading">Group</h5>
        </Link>
      </div>
      <div className="mobileHomeItem">
        <Link to="/dashboard/support">
          <BiSupport color="#1EAFAB" className="icon" />
          <h5 className="sub-heading">Support</h5>
        </Link>
      </div>
      <div className="mobileHomeItem">
        <Link to="/dashboard/setting">
          <FiSettings color="#1EAFAB" className="icon" />
          <h5 className="sub-heading">Setting</h5>
        </Link>
      </div>
      <div className="mobileHomeItem">
        <IoNotificationsOutline color="#1EAFAB" className="icon" />
        <h5 className="sub-heading">Notification</h5>
      </div>
    </div>
  );
}

export default MobileHome;
