exports.typeOptions = [
  { label: 'For an Individual', value: 'Individual' },
  { label: 'For a Family', value: 'Family' },
  { label: 'For a Group', value: 'Group' },
  { label: 'For a Company', value: 'Company' },
];

exports.questions = {
  '0a': {
    name: 'Are you married',
    values: ['yes', 'no'],
    type: 'select',
    options: true,
    answerOptions: {
      yes: '0b',
      no: '1',
    },
  },
  '0b': {
    name: 'how long have you been married',
    type: 'input',
    options: false,
    next: '1',
  },
  '1': {
    name: 'Gender',
    values: ['female', 'male'],
    type: 'select',
    options: true,
    answerOptions: {
      female: '1b',
      male: '2',
    },
  },
  '1b': {
    name: 'Are you planning to have kids in the next 12 months',
    values: ['yes', 'no'],
    type: 'select',
    options: false,
    next: '1c',
  },
  '1c': {
    name: 'Are you a nursing mother',
    values: ['yes', 'no'],
    type: 'select',
    options: false,
    next: '2',
  },
  '2': {
    name: 'Have you been to the dentist/optician in the last 12 months',
    values: ['yes', 'no'],
    type: 'select',
    options: false,
    next: '3a',
  },
  '3a': {
    name: 'Have you treated for Asthma in the last 12 months',
    values: ['yes', 'no'],
    type: 'select',
    options: false,
    next: '3b',
  },
  '3b': {
    name: 'Have you treated for Hypertension in the last 12 months',
    values: ['yes', 'no'],
    type: 'select',
    options: false,
    next: '3c',
  },
  '3c': {
    name: 'Have you treated for Ulcer in the last 12 months',
    values: ['yes', 'no'],
    type: 'select',
    options: false,
    next: '3d',
  },
  '3d': {
    name: 'Have you treated for Sickle cell in the last 12 months',
    values: ['yes', 'no'],
    type: 'select',
    options: false,
    next: '3e',
  },
  '3e': {
    name: 'Have you treated for Diabetes in the last 12 months',
    values: ['yes', 'no'],
    type: 'select',
    options: false,
    next: '3f',
  },
  '3f': {
    name:
      'Have you treated for Epilepsy or seizure disorders in the last 12 months',
    values: ['yes', 'no'],
    type: 'select',
    options: false,
    next: '4',
  },
  '4': {
    name: 'what is your blood type?',
    values: ['A', 'B', 'O', 'AB'],
    type: 'select',
    options: false,
    next: '5',
  },
  '5': {
    name: 'what is your blood group?',
    values: ['AA', 'AS', 'SS', 'AC', 'SC'],
    type: 'select',
    options: false,
    next: '6',
  },
  '6': {
    name: 'Are you currently managing any chronic health conditions?',
    values: ['yes', 'no'],
    type: 'select',
    options: false,
    next: '7',
  },
  '7': {
    name: 'Are you currently on any medications',
    values: ['yes', 'no'],
    type: 'select',
    options: false,
    next: '8',
  },
  '8': {
    name: 'Do you have any drug allergies?',
    values: ['yes', 'no'],
    type: 'select',
    options: false,
    next: '9',
  },
  '9': {
    name: 'Have you had any surgery in the past 12 months?',
    values: ['yes', 'no'],
    type: 'select',
    options: true,
    answerOptions: {
      yes: '9a',
      no: '10',
    },
  },
  '9a': {
    name: 'Duration of time spent in the hospital in days',
    values: ['<5', '6-10', '11-20', '>20'],
    type: 'select',
    options: false,
    next: '9b',
  },
  '9b': {
    name: 'Any complications from previous surgery',
    values: ['yes', 'no'],
    type: 'select',
    options: false,
    next: '9c',
  },
  '9c': {
    name: 'Were symptoms relieved after procedure',
    values: ['yes', 'no'],
    type: 'select',
    options: false,
    next: '9d',
  },
  '9d': {
    name: 'Are you still receiving treatment for the procedure',
    values: ['yes', 'no'],
    type: 'select',
    options: false,
    next: '10',
  },
  '10': {
    name: 'Any known allergies?',
    values: ['yes', 'no'],
    type: 'select',
    options: false,
    next: '11',
  },
  '11': {
    name: 'Have you visited any specialist in the last 12 months',
    values: ['yes', 'no'],
    type: 'select',
    options: true,
    answerOptions: {
      yes: '11a',
      no: '12',
    },
  },
  '11a': {
    name: 'Mention the specialist visited',

    type: 'input',
    options: false,
    next: '12',
  },
  '12': {
    name: "What's your average monthly income",
    values: ['<50k', '51-100K', '101k-250K', '>250K'],
    type: 'select',
    options: false,
    next: '13',
  },
  '13': {
    name: 'How old are you?',
    values: ['18-24', '25-38', '39-54', '>55'],
    type: 'select',
    options: false,
    final: true,
  },
  '14': {
    name: 'Marital status',
    values: ['Married', 'Single'],
    type: 'select',
    options: false,
    final: false,
    next: '15a',
  },
  '15a': {
    name: 'Number of dependents',
    type: 'input',
    options: false,
    final: false,
    next: '15b',
  },
  '15b': {
    name: 'Any dependent/child within the ages 0 -18 yrs',
    values: ['yes', 'no'],
    type: 'select',
    options: true,
    final: false,
    answerOptions: {
      yes: '15c',
      no: '0b',
    },
  },
  '15c': {
    name: 'Number of dependents below the age of 18',
    type: 'input',
    options: false,
    next: '0b',
  },
  '16': {
    name: 'Group Number',
    values: [
      '<10',
      '10-20',
      '21-50',
      '51-100',
      '101-250',
      '251-500',
      '501-750',
      '751-1000',
      '>1000',
    ],
    type: 'select',
    options: false,
    final: false,
    next: '17',
  },
  '17': {
    name: 'Number of females',
    type: 'input',
    options: false,
    final: false,
    next: '18',
  },
  '18': {
    name: 'Number of Males',
    type: 'input',
    options: false,
    final: false,
    next: '19a',
  },
  '19a': {
    name: 'Select the care package that best suits your group, Dental care?',
    values: ['yes', 'no'],
    type: 'select',
    options: false,
    final: false,
    next: '19b',
  },
  '19b': {
    name: 'Optical care?',
    values: ['yes', 'no'],
    type: 'select',
    options: false,
    final: false,
    next: '19c',
  },
  '19c': {
    name: 'Prostrate/Cervical care?',
    values: ['yes', 'no'],
    type: 'select',
    options: false,
    final: false,
    next: '19d',
  },
  '19d': {
    name: 'Procedures?',
    values: ['minor', 'intermediate', 'major'],
    type: 'select',
    options: false,
    final: false,
    next: '19e',
  },
  '19e': {
    name: 'Diabetes/Asthma/Ulcer/Sickle cell/Seizure disorder?',
    values: ['yes', 'no'],
    type: 'select',
    options: false,
    final: false,
    next: '19f',
  },
  '19f': {
    name: 'Dietician?',
    values: ['yes', 'no'],
    type: 'select',
    options: false,
    final: false,
    next: '19g',
  },
  '19g': {
    name: 'Gym?',
    values: ['yes', 'no'],
    type: 'select',
    options: false,
    final: false,
    next: '19h',
  },
  '19h': {
    name: 'Chronic Treatment?',
    values: ['yes', 'no'],
    type: 'select',
    options: false,
    final: false,
    next: '19i',
  },
  '19i': {
    name: 'Specialist consultation?',
    values: ['yes', 'no'],
    type: 'select',
    options: false,
    final: false,
    next: '19j',
  },
  '19j': {
    name: 'Inpatient care?',
    values: ['yes', 'no'],
    type: 'select',
    options: false,
    final: false,
    next: '19k',
  },
  '19k': {
    name: 'Maternity care?',
    values: ['yes', 'no'],
    type: 'select',
    options: false,
    final: false,
    next: '19l',
  },
  '19l': {
    name: 'Other interest',
    type: 'input',
    options: false,
    final: true,
    next: '1',
  },
};
exports.questionsArray = {
  '0a': 'Are you married',
  '0b': 'how long have you been married',
  '1': 'Gender',
  '1b': 'Are you planning to have kids in the next 12 months',
  '1c': 'Are you a nursing mother',
  '2': 'Have you been to the dentist/optician in the last 12 months',
  '3a': 'Have you treated for Asthma in the last 12 months',
  '3b': 'Have you treated for Hypertension in the last 12 months',
  '3c': 'Have you treated for Ulcer in the last 12 months',
  '3d': 'Have you treated for Sickle cell in the last 12 months',
  '3e': 'Have you treated for Diabetes in the last 12 months',
  '3f':
    'Have you treated for Epilepsy or seizure disorders in the last 12 months',
  '4': 'what is your blood type?',
  '5': 'what is your blood group?',
  '6': 'Are you currently managing any chronic health conditions?',
  '7': 'Are you currently on any medications',
  '8': 'Do you have any drug allergies?',
  '9': 'Have you had any surgery in the past 12 months?',
  '9a': 'Duration of time spent in the hospital in days',
  '9b': 'Any complications from previous surgery',
  '9c': 'Were symptoms relieved after procedure',
  '9d': 'Are you still receiving treatment for the procedure',
  '10': 'Any known allergies?',
  '11': 'Have you visited any specialist in the last 12 months',
  '11a': 'Mention the specialist visited',
  '12': "What's your average monthly income",
  '13': 'How old are you?',
  '14': 'Marital status',
  '15a': 'Number of dependents',
  '15b': 'Any dependent/child within the ages 0 -18 yrs',
  '15c': 'Number of dependents below the age of 18',
  '16': 'Group Number',
  '17': 'Number of females',
  '18': 'Number of Males',
  '19a': 'Select the care package that best suits your group, Dental care?',
  '19b': 'Optical care?',
  '19c': 'Prostrate/Cervical care?',
  '19d': 'Procedures?',
  '19e': 'Diabetes/Asthma/Ulcer/Sickle cell/Seizure disorder?',
  '19f': 'Dietician?',
  '19g': 'Gym?',
  '19h': 'Chronic Treatment?',
  '19i': 'Specialist consultation?',
  '19j': 'Inpatient care?',
  '19k': 'Maternity care?',
  '19l': 'Other interest',
};
exports.questionsComputation = {
  'Are you married': null,
  'how long have you been married': null,
  Gender: null,
  'Are you planning to have kids in the next 12 months': null,
  'Are you a nursing mother': null,
  'Have you been to the dentist/optician in the last 12 months': null,
  'Have you treated for Asthma in the last 12 months': null,
  'Have you treated for Hypertension in the last 12 months': null,
  'Have you treated for Ulcer in the last 12 months': null,
  'Have you treated for Sickle cell in the last 12 months': null,
  'Have you treated for Diabetes in the last 12 months': null,

  'Have you treated for Epilepsy or seizure disorders in the last 12 months': null,
  'what is your blood type?': null,
  'what is your blood group?': null,
  'Are you currently managing any chronic health conditions?': null,
  'Are you currently on any medications': null,
  'Do you have any drug allergies?': null,
  'Have you had any surgery in the past 12 months?': null,
  'Duration of time spent in the hospital in days': null,
  'Any complications from previous surgery': null,
  'Were symptoms relieved after procedure': null,
  'Are you still receiving treatment for the procedure': null,
  'Any known allergies?': null,
  'Have you visited any specialist in the last 12 months': null,
  'Mention the specialist visited': null,
  "What's your average monthly income": null,
  'How old are you?': null,
  'Marital status': null,
  'Number of dependents': null,
  'Any dependent/child within the ages 0 -18 yrs': null,
  'Number of dependents below the age of 18': null,
  'Group Number': null,
  'Number of females': null,
  'Number of Males': null,
  'Select the care package that best suits your group: null, Dental care?': null,
  'Optical care?': null,
  'Prostrate/Cervical care?': null,
  'Procedures?': null,
  'Diabetes/Asthma/Ulcer/Sickle cell/Seizure disorder?': null,
  'Dietician?': null,
  'Gym?': null,
  'Chronic Treatment?': null,
  'Specialist consultation?': null,
  'Inpatient care?': null,
  'Maternity care?': null,
  'Other interest': null,
};
