import React from "react";
import noPrescriptionImg from "../../../assets/images/nodata/prescription-nodata.png";
import Tables from "../general/Tables";
import CardModal from "../general/CardModal";
// const data = {
//   heading: ["S/N", "ECN No.", "Provider", "Date Opened", "Date closed"],
//   list: [
//     {
//       num: "1",
//       ecn: "299282",
//       provider: "ST LUKES",
//       dO: "01 Mar, 2022",
//       dC: "01 Mar, 2022",
//       used: "10",
//       investigation: "1 tablet whatsaoever",
//     },
//   ],
// };
const data = null;
function HistoryTable() {
  return (
    <div className="history-table">
      {data ? (
        <div className="history-container">
          <Tables data={data} type="history" />
          <CardModal />
        </div>
      ) : (
        <div className="no-data">
          <img src={noPrescriptionImg} alt="" style={{ width: "20%" }} />
          <h1 className="no-data-text">No History</h1>
        </div>
      )}
    </div>
  );
}

export default HistoryTable;
