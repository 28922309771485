import Login from "../../components/auth/Login";
const SubscriberLogin = () => {
  return (
    <Login
      heading="Protect your health"
      text="What’s health without life? What’s life without health?"
      cardtext="Log into your account to manage your contributions."
      subscriber
    />
  );
};
export default SubscriberLogin;
