import React from "react";
import img from "../assets/images/404page.jpg";
import HeaderNav from "../components/auth/Nav";
function NotFound() {
  return (
    <section className="signUp-container">
      <HeaderNav />
      <div className="pageContainer">
        <div className="btn-404">404 Error</div>
        <h1 className="heading-404">We’ve lost this page</h1>
        <p className="text-404">
          Sorry the page you are looking for does not exist or has been moved
        </p>
        <img className="img-404" src={img} alt="Page Not found" />
      </div>
    </section>
  );
}

export default NotFound;
