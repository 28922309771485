import React from "react";
import { Row, Col } from "antd";
import Ecard from "./Ecard";
import Activities from "../general/Activities";
import Benefits from "./Benefits";
import HistoryCard from "../subscription/HistoryCard";
function DashboardComponent() {
  return (
    <>
      <Row className="dashboard-body">
        <Col className="" span={13}>
          <Ecard />
        </Col>
        <Col className="" span={11}>
          <Activities />
        </Col>
      </Row>
      <Row className="dashboard-body">
        <Col className="" span={13}>
          <Benefits />
        </Col>
        <Col className="" span={11}>
          <HistoryCard title={"History"} />
        </Col>
      </Row>
    </>
  );
}

export default DashboardComponent;
