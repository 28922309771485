import { useState } from "react";
import Filter from "../general/Filter";
import SearchBox from "../general/SearchBox";
import Table from "../general/Table";
import AddBtn from "../general/buttons/AddBtn";
import ProviderCard from "../AdminProvider/ProviderCard";
import BackNav from "../general/buttons/BackNav";
import UserBenefitCard from "../general/ProfileCard/UserBenefitCard";
import DeleteAndDeactivateBtn from "../general/buttons/DeleteAndDeactivateBtn";
const data = {
  heading: [
    "S/N",
    "Ecn. No",
    "Date Opened",
    "Date closed",
    "Date vetted",
    "Amount billed",
    "Amount paid",
    "Status",
  ],
  list: [
    {
      num: "1",
      ecn: "299282",
      dO: "01 Mar, 2022",
      dC: "01 Mar, 2022",
      dV: "01 Mar, 2022",
      billed: "10",
      paid: "N1,500",
      stat: "Active",
    },
  ],
};

function Claim() {
  const [detailsPage, setDetailsPage] = useState(false);
  const [show, setShow] = useState(1);
  return (
    <section>
      {!detailsPage ? (
        <>
          <div className="dashboard-top">
            <Filter />
            <SearchBox />
            <AddBtn text="Add Claims" />
          </div>
          <div className="claim-body">
            <Table data={data} type="claims" setDetailsPage={setDetailsPage} />
          </div>
        </>
      ) : (
        <div className="claims">
          <div>
            <BackNav text="Claims" setDetailsPage={setDetailsPage} />
            <div className="profileCard">
              <ProviderCard type="claim" />
            </div>
          </div>
          <div>
            <nav className="Details_navbar">
              <ul className="Details_nav">
                <li
                  className={show === 1 ? "focus" : ""}
                  onClick={() => setShow(1)}
                >
                  Benefit used
                </li>
                <li
                  className={show === 2 ? "focus" : ""}
                  onClick={() => setShow(2)}
                >
                  Report
                </li>
                <li
                  className={show === 3 ? "focus" : ""}
                  onClick={() => setShow(3)}
                >
                  Drugs
                </li>
                <li
                  className={show === 4 ? "focus" : ""}
                  onClick={() => setShow(4)}
                >
                  Appiontment
                </li>
                <li
                  className={show === 5 ? "focus" : ""}
                  onClick={() => setShow(5)}
                >
                  Cost
                </li>
              </ul>
              <DeleteAndDeactivateBtn />
            </nav>
            {show === 1 && <UserBenefitCard />}
          </div>
        </div>
      )}
    </section>
  );
}

export default Claim;
