import { useState } from "react";
import noAppointmentImg from "../../../assets/images/nodata/appointment-nodata.png";
import Search from "../general/Search";
import CardHeader from "../general/CardHeader";
import CardModal from "../general/CardModal";
const data = null;
function AppointmentMobileCard() {
  const [showCard, setShowCard] = useState(false);
  return (
    <>
      {data ? (
        <div className="prescription-mobile">
          {showCard && (
            <div>
              <div className="overlay"></div>
              <CardModal popUp setShowCard={setShowCard} />
            </div>
          )}
          <div className="search-mobile">
            <Search />
          </div>
          <div className="card-header-list">
            <CardHeader
              name="St. Lukes"
              id="006522"
              subtitle="Date"
              subtext="Fri. June 17"
            />
          </div>
        </div>
      ) : (
        <div className="no-data">
          <img src={noAppointmentImg} alt="" />
          <h1 className="no-data-text">No Appointment</h1>
        </div>
      )}
    </>
  );
}

export default AppointmentMobileCard;
