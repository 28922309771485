import { useState } from "react";
import { RiArrowDropUpLine, RiArrowDropDownLine } from "react-icons/ri";
import ModalMobileBox from "./ModalMobileBox";
import noActivitiesImg from "../../../assets/images/nodata/history-nodata.png";

const dataSet = [
  { name: "Malaria Test", date: "Wed, 15 June" },
  { name: "Typhoid Test", date: "Wed, 15 June" },
  { name: "Malaria Test", date: "Wed, 15 June" },
  { name: "Malaria Test", date: "Wed, 15 June" },
];

const ActivitiesList = ({ data }) => {
  const filteredData = data;
  return (
    <>
      {filteredData ? (
        filteredData?.map((item, i) => (
          <li className="card-line" key={i}>
            <span className="card-box"></span>
            <span>{item.name}</span>
            <div>{item.date}</div>
          </li>
        ))
      ) : (
        <div className="no-data">
          <img src={noActivitiesImg} alt="" style={{ minHeight: "5rem" }} />
          <h1 className="no-data-text">No Activities</h1>
        </div>
      )}
    </>
  );
};
function Activities() {
  const [showCard, setShowCard] = useState(false);
  const [seemore, setSeemore] = useState(false);
  const [filteredData, setFilteredData] = useState(null);
  return (
    <div className="activities-card">
      {seemore && (
        <ModalMobileBox
          seemore={setSeemore}
          title={"Recent Activities"}
          Component={ActivitiesList}
          data={dataSet}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
        />
      )}
      <div className="mobile">
        <span className="mobile-header">Recent Activities</span>
        {!showCard ? (
          <span onClick={() => setShowCard(!showCard)}>
            <RiArrowDropDownLine size="28px" />
          </span>
        ) : (
          <span onClick={() => setShowCard(!showCard)}>
            <RiArrowDropUpLine size="28px" />
          </span>
        )}
      </div>
      <h3 className="activities-header">Recent Activities</h3>
      <ul
        className={
          !showCard
            ? "activities-left"
            : "activities-left show dropdown-animate"
        }
      >
        <ActivitiesList data={filteredData} />
      </ul>
      {showCard && dataSet && (
        <button
          className="mobile-version btn-mobile"
          onClick={() => setSeemore(true)}
        >
          see more
        </button>
      )}
    </div>
  );
}

export default Activities;
