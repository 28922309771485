import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import avatar from "../../assets/images/avatar.png";
import signInImg from "../../assets/images/SignIn.png";
import "../../pages/Authentication/auth.scss";
import { BsPhoneFill } from "react-icons/bs";
import { RiLockPasswordFill } from "react-icons/ri";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Spin } from "antd";
import HeaderNav from "../../components/auth/Nav";
import { loginUser } from "../../redux/authentication/reducer";

const Login = ({ heading, text, cardtext, subscriber, heading1, admin }) => {
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [formInput, setFormInput] = useState({
    phone: "",
    password: "",
  });
  const [password, setPassword] = useState(false);
  const [phone, setPhone] = useState(false);
  const { error, loading } = useSelector((state) => state.auth);
  // Handle input data on submit
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginUser(formInput));
  };

  // Store form input in state
  const handleChange = ({ target: { name, value } }) => {
    setFormInput((prev) => {
      return { ...prev, [name]: value };
    });
  };

  // Handle input validations
  const handleFocus = (e) => {
    const { name } = e.target;
    const rePassword = /(?=^.{8,}$)(?![.\n]).*$/;
    const rePhone = /^\d{11}$/;
    name === "phone" && setPhone(!rePhone.test(formInput.phone));
    name === "password" && setPassword(!rePassword.test(formInput.password));
  };

  return (
    <section className="signUp-container">
      <HeaderNav />
      <div className="signUp-wrapper">
        <div className="signUp-left">
          <h1>{heading}</h1>
          {admin && <h1>{heading1}</h1>}
          <p>{text}</p>
          {subscriber && (
            <p className="signUp-left-provider">
              Are you a provider? Click here
            </p>
          )}
          <img src={signInImg} alt="" className="signIn-img" />
        </div>
        <div className="signUp-right">
          <div className="login-right-container">
            <img src={avatar} alt="" className="signUp-right-avater" />
            <h2>Welcome back</h2>
            <p>{cardtext}</p>
            {error && <span className="err-message">{error}</span>}
            <form className="form" id="login-form">
              <div className="form-bottom">
                <div className={phone ? "err-box" : ""}>
                  <BsPhoneFill className="icon" />
                  <input
                    placeholder="Phone"
                    required
                    name="phone"
                    onChange={handleChange}
                    onBlur={handleFocus}
                    value={formInput.phone}
                    pattern="^\d{11}$"
                  />
                </div>
                {phone && (
                  <span className="err-message-bottom">
                    Enter valid Phone number
                  </span>
                )}
                <div className={password ? "err-box" : ""}>
                  <RiLockPasswordFill className="icon" />
                  <input
                    type={!togglePassword ? "password" : "text"}
                    name="password"
                    onChange={handleChange}
                    onBlur={handleFocus}
                    placeholder="Password"
                    value={formInput.password}
                    minlength="8"
                    required
                  />
                  <i onClick={() => setTogglePassword(!togglePassword)}>
                    {!togglePassword ? (
                      <AiOutlineEyeInvisible
                        style={{ fontSize: "1.6rem", padding: "0px 4px" }}
                      />
                    ) : (
                      <AiOutlineEye
                        style={{ fontSize: "1.6rem", padding: "0px 4px" }}
                      />
                    )}
                  </i>
                </div>
                {password && (
                  <span className="err-message-bottom">
                    Password should be at least 8 character.
                  </span>
                )}
              </div>
              <Link to="/forgot-Password">
                <span className="forgot-password-text">Forgot/Reset Password?</span>
              </Link>
              <button
                type="submit"
                loading={loading.toString()}
                className="btn-custom"
                onClick={handleSubmit}
              >
                {loading ? <Spin /> : " Sign in"}
              </button>
              <p>
                Don’t have an account?
                <Link
                  to="/"
                  onClick={() => error && (window.location.href = "/")}
                  className="b-link"
                >
                  <b> Sign up</b>
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
