import { useState } from "react";
// import AdminInfo from "./AdminInfo";
// import AdminSecurity from "./AdminSecurity";
import "../../../pages/Subscribers/subscriber.scss";
import Table from "../general/Table";
import ProfileCard from "../../global/ProfileCard";
import UsersDetailsCard from "../general/ProfileCard/UserDetailsCard";
const data = {
  heading: ["S/N", "ID Number", "Name", "Phone/Email", "role", "Status"],
  list: [
    {
      num: "1",
      id: "299282",
      name: "Victor U",
      pE: "Mary@gmail.com | 08011822922",
      role: "12",
      stat: "active",
    },
  ],
};
function AdminSettings() {
  const [show, setShow] = useState(1);
  return (
    <div className="admin-setting">
      <div className="Userdetails">
        <div>
          <div className="profileCard">
            <ProfileCard type="Admin-setting" />
          </div>
        </div>
        <div>
          <nav className="Details_navbar">
            <ul className="Details_nav">
              <li
                className={show === 1 ? "focus" : ""}
                onClick={() => setShow(1)}
              >
                Personal Information
              </li>
              <li
                className={show === 2 ? "focus" : ""}
                onClick={() => setShow(2)}
              >
                User Management
              </li>
              <li
                className={show === 3 ? "focus" : ""}
                onClick={() => setShow(3)}
              >
                Security
              </li>
            </ul>
          </nav>
          {show === 1 && <UsersDetailsCard />}
          {show === 2 && <Table type="settings" data={data} />}
          {/* {show === 3 && <AdminSecurity />} */}
        </div>
      </div>
    </div>
  );
}

export default AdminSettings;
