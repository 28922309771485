import React from "react";
// import { useState } from "react";
import DashboardHeaderTop from "./general/DashboardHeaderTop";

export default function Support() {
  // const [inputText, setInputText] = useState({
  //   fullname: "",
  //   phone: "",
  //   email: "",
  //   subject: "",
  // });
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setInputText((prev) => {
  //     return {
  //       ...prev,
  //       [name]: value,
  //     };
  //   });
  // };
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setInputText({ fullname: "", phone: "", email: "", subject: "" });
  // };
  return (
    <>
      <DashboardHeaderTop />
      <div className="support-container">
        <h1>Contact us</h1>
        <p>For technical enquiries: <span>
          <a href="https://hadiel.retool.com/form/e0bc067d-77ca-4b49-948e-984051d0ebbd" target="_blank" rel="noreferrer">Technical support</a>
        </span></p>
        <br />
        <p>For accessing care and other enquiries</p>
        <br /> <span><li>Our dedicated whatsapp channel: <a href="https://wa.link/fmfbud" target="_blank" rel="noreferrer">+234 703 449 2713</a></li></span>
        <br /><span><li>Our customer support lines- +234 807 276 3768 or +234902 318 2648</li></span>

        {/* <form onSubmit={handleSubmit}>
          <div className="form-container">
            <div>
              <div className="input-container">
                <label>Full Name</label>
                <input
                  type="text"
                  placeholder="Hadiel"
                  name="fullname"
                  pattern="^[A-Za-z0-9]{3,24}$"
                  value={inputText.fullname}
                  onChange={handleChange}
                  // required
                />
              </div>
              <div className="input-container">
                <label>Phone</label>
                <input
                  type="tel"
                  placeholder="Enter phone number"
                  name="phone"
                  pattern="^[A-Za-z0-9]{3,24}$"
                  value={inputText.phone}
                  onChange={handleChange}
                  // required
                />
              </div>
              <div className="input-container">
                <label>Email</label>
                <input
                  type="text"
                  placeholder="Enter email"
                  name="email"
                  pattern="^[A-Za-z0-9]{3,24}$"
                  value={inputText.email}
                  onChange={handleChange}
                  // required
                />
              </div>
              <div className="input-container">
                <label>Subject</label>
                <input
                  type="text"
                  placeholder="Enter text"
                  name="subject"
                  pattern="^[A-Za-z0-9]{3,24}$"
                  value={inputText.subject}
                  onChange={handleChange}
                  // required
                />
              </div>
            </div>
            <div>
              <label>Message</label>
              <textarea />

              <button type="submit">Submit</button>
            </div>
          </div>
        </form> */}
      </div>
    </>
  );
}
