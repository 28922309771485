import { useState } from "react";
import { RiArrowDropUpLine, RiArrowDropDownLine } from "react-icons/ri";
import ModalMobileBox from "../general/ModalMobileBox";
import noHistoryImg from "../../../assets/images/nodata/history-nodata.png";

const dataSet = null;
//  [
//   { name: "Havard Medicals", date: "Wed, 15 June" },
//   { name: "Hadiel Medicals", date: "Wed, 15 June" },
//   { name: "Havard Medicals", date: "Wed, 15 June" },
//   { name: "Havard Medicals", date: "Wed, 15 June" },
// ];
const HistoryList = ({ data }) => {
  const filteredData = data;
  return (
    <>
      {filteredData ? (
        filteredData.map((item, i) => (
          <li className="card-line" key={i}>
            <span className="card-box" style={{ borderRadius: "50%" }}></span>
            <span>{item.name}</span>
            <div>{item.date}</div>
          </li>
        ))
      ) : (
        <div className="no-data">
          <img src={noHistoryImg} alt="" />
          <h1 className="no-data-text">No history</h1>
        </div>
      )}
    </>
  );
};
function HistoryCard({ title }) {
  const [showCard, setShowCard] = useState(false);
  const [seemore, setSeemore] = useState(false);
  const [filteredData, setFilteredData] = useState(null);
  return (
    <div className="history-card">
      {seemore && (
        <ModalMobileBox
          seemore={setSeemore}
          title={title}
          Component={HistoryList}
          data={dataSet}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
        />
      )}
      <div className="mobile">
        <span className="mobile-header">{title}</span>
        {!showCard ? (
          <span onClick={() => setShowCard(!showCard)}>
            <RiArrowDropDownLine size="28px" />
          </span>
        ) : (
          <span onClick={() => setShowCard(!showCard)}>
            <RiArrowDropUpLine size="28px" />
          </span>
        )}
      </div>
      <h3 className="activities-header">{title}</h3>
      <ul
        className={
          !showCard
            ? "activities-left"
            : "activities-left show dropdown-animate"
        }
      >
        <HistoryList data={filteredData} />
      </ul>
      {showCard && dataSet && (
        <button
          className="mobile-version btn-mobile"
          onClick={() => setSeemore(true)}
        >
          see more
        </button>
      )}
    </div>
  );
}

export default HistoryCard;
