import { useState } from "react";
import { MdKeyboardBackspace } from "react-icons/md";
// import { ReactComponent as Arrow } from "../../../../assets/images/dashboard/arrow.svg";
import { useDispatch } from "react-redux";
import { states } from "../healthProvider/HealthProvider";
import { updatePersonalInfo } from "../../../../redux/settings/reducer";
import {
  getSubscription,
  getGroupMembers,
} from "../../../../redux/subscriber/reducer";
function PersonalInfo({ show, groupmemberId }) {
  const [disabled, setDisabled] = useState(false);
  const [forminput, setFormInput] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    sex: "",
    state: "",
    address01: "",
    address02: "",
    emergencyContactName: "",
    emergencyContactPhone: "",
  });
  const subscriberId = window.localStorage.getItem("subscriberId");
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormInput((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const value = Object.values(forminput).filter(
      (value) => value !== "" && value !== null
    );
    value.length === 0 && setDisabled(true);
    value.length !== 0 &&
      dispatch(
        updatePersonalInfo({
          payload: { ...forminput },
          subscriberId: groupmemberId ? groupmemberId : subscriberId,
        })
      );
    setFormInput({
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      sex: "",
      state: "",
      address01: "",
      address02: "",
      emergencyContactName: "",
      emergencyContactPhone: "",
    });
    groupmemberId && dispatch(getGroupMembers());
    dispatch(getSubscription(subscriberId));
    setDisabled(false);
  };
  return (
    <div className="personal">
      <div className="mobile" onClick={() => show(true)}>
        <span>
          <MdKeyboardBackspace size="24px" />
        </span>
        <h2>Personal Information</h2>
      </div>
      <form className="personal-info" onSubmit={handleSubmit}>
        <div className="form-container">
          <div className="form-body">
            <div className="bottom">
              <label>First Name</label>
              <input
                type="text"
                placeholder="First Name"
                name="firstName"
                pattern="^(?=[a-zA-Z\s]{2,24}$)([a-zA-Z]+(?: [a-zA-Z]+)?)"
                // pattern="^[A-Za-z0-9]{3,24} [A-Za-z0-9]{3,24}$"
                value={forminput.firstName}
                onChange={handleChange}
              />
            </div>
            <div className="bottom">
              <label>Last Name</label>
              <input
                type="text"
                placeholder="Last Name"
                name="lastName"
                pattern="^(?=[a-zA-Z\s]{2,24}$)([a-zA-Z]+(?: [a-zA-Z]+)?)"
                // pattern="^[A-Za-z0-9]{3,24}$"
                value={forminput.lastName}
                onChange={handleChange}
              />
            </div>
            <div className="bottom">
              <label>Sex</label>
              <div>
                <select name="sex" onChange={handleChange}>
                  <option value="">Select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
            </div>
            <div className="bottom">
              <label>Date of Birth</label>
              <input
                type="date"
                placeholder="Choose date"
                name="dateOfBirth"
                value={forminput.dateOfBirth}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-body">
            <div className="bottom-right ">
              <label>Address 1</label>
              <input
                type="text"
                placeholder={forminput.address01 || "Enter Address"}
                name="address01"
                value={forminput.address01}
                onChange={handleChange}
              />
            </div>
            <div className="bottom-right">
              <label>Address 2</label>
              <input
                type="text"
                placeholder={forminput.address02 || "Enter Address Ext."}
                name="address02"
                value={forminput.address02}
                onChange={handleChange}
              />
            </div>
            <div className="bottom">
              <label>State</label>
              <select name="state" onChange={handleChange}>
                {states.map((state, i) => (
                  <option value={state} key={i}>
                    {state}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <h3>Emergency Contact</h3>
          <div className="form-body emmergency ">
            <div className="">
              <label>Name</label>
              <input
                type="text"
                placeholder={
                  forminput.emergencyContactName || "First and Last Name"
                }
                name="emergencyContactName"
                value={forminput.emergencyContactName}
                onChange={handleChange}
              />
            </div>
            <div className="">
              <label>Phone</label>
              <input
                type="tel"
                placeholder={
                  forminput.emergencyContactPhone || "Ex. 08112345678"
                }
                name="emergencyContactPhone"
                pattern="^\d{11}$"
                value={forminput.emergencyContactPhone}
                onChange={handleChange}
              />
            </div>
          </div>
          <button type="submit" disabled={disabled}>
            Update
          </button>
        </div>
      </form>
    </div>
  );
}

export default PersonalInfo;
