import { useState } from "react";
import Table from "../general/Table";
import SearchBox from "../general/SearchBox";
import Filter from "../general/Filter";
import AddBtn from "../general/buttons/AddBtn";
import ProfileCard from "../../global/ProfileCard";
import UsersDetailsCard from "../general/ProfileCard/UserDetailsCard";
import BackNav from "../general/buttons/BackNav";
import DeleteAndDeactivateBtn from "../general/buttons/DeleteAndDeactivateBtn";
import VoucherCard from "./VoucherCard";

const data = {
  heading: [
    "S/N",
    "ID Number",
    "Name",
    "Phone/ Email",
    "Location",
    "State",
    "Status",
  ],
  list: [
    {
      num: "1",
      id: "299282",
      name: "Samuel Eli",
      pE: "Mary@gmail.com | 08011822922",
      location: "Ojodu",
      state: "Lagos",
      stat: "Active",
    },
  ],
};
function AdminSalsesRep() {
  const [detailsPage, setDetailsPage] = useState(false);
  const [show, setShow] = useState(1);
  return (
    <section>
      {!detailsPage ? (
        <>
          <div className="dashboard-top">
            <Filter />
            <SearchBox />
            <AddBtn text="Add Sales rep" />
          </div>
          <Table data={data} type="salesReps" setDetailsPage={setDetailsPage} />
        </>
      ) : (
        <div className="sales-content">
          <div>
            <BackNav text="Samuel Eli" setDetailsPage={setDetailsPage} />
            <div className="sales-profile">
              <ProfileCard type="provider" />
            </div>
          </div>
          <div>
            <nav className="Details_navbar header-nav">
              <ul className="Details_nav">
                <li
                  className={show === 1 ? "focus" : ""}
                  onClick={() => setShow(1)}
                >
                  Personal Information
                </li>
                <li
                  className={show === 2 ? "focus" : ""}
                  onClick={() => setShow(2)}
                >
                  Vouchers
                </li>
              </ul>
              <DeleteAndDeactivateBtn />
            </nav>
            <div>{show === 1 && <UsersDetailsCard type="salesReps" />}</div>
            {show === 2 && <VoucherCard />}
          </div>
        </div>
      )}
    </section>
  );
}

export default AdminSalsesRep;
