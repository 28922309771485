import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { local, header, handleError } from "../../services/helper";
import axios from "axios";
import { message } from "antd";

export const getSubscription = createAsyncThunk(
  "users/getSubscription",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${local}/subscriptions/${payload}`,
        header()
      );
      return response.data;
    } catch (error) {
      const e = handleError(error);
      return rejectWithValue(e);
    }
  }
);

export const activatePlan = createAsyncThunk(
  "users/activatePlan",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        local + "/subscriptions",
        payload,
        header()
      );
      if (response.data.success) window.location.href = "/dashboard";
      return response.data;
    } catch (error) {
      const e = handleError(error);
      return rejectWithValue(e);
    }
  }
);

export const topUp = createAsyncThunk(
  "users/topUp",
  async (action, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${local}/subscriptions/${action.id}`,
        action.payload,
        header()
      );
      return response.data;
    } catch (error) {
      const e = handleError(error);
      return rejectWithValue(e);
    }
  }
);

export const activateGroupMember = createAsyncThunk(
  "users/activateGroupMember",
  async (action, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${local}/subscribers/group/subscription/${action.id}`,
        action.payload,
        header()
      );
      return response.data;
    } catch (error) {
      const e = handleError(error);
      return rejectWithValue(e);
    }
  }
);

export const activatePlanVoucher = createAsyncThunk(
  "users/activatePlanVoucher",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${local}/subscriptions`,
        payload,
        header()
      );
      return response.data;
    } catch (error) {
      const e = handleError(error);
      return rejectWithValue(e);
    }
  }
);

export const updateSubscriberProvider = createAsyncThunk(
  "users/updateSubscriberProvider",
  async (action, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${local}/subscribers/${action.subscriberId}/provider`,
        action.payload,
        header()
      );
      if (response?.data?.success) {
        message.info("Provider was uploaded successfully");
      }
      return response.data;
    } catch (error) {
      message.error("Provider not uploaded successfully");
      const e = handleError(error);
      return rejectWithValue(e);
    }
  }
);

export const getProvidersList = createAsyncThunk(
  "users/getProvidersList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${local}/facilities`, header());
      return response.data;
    } catch (error) {
      const e = handleError(error);
      return rejectWithValue(e);
    }
  }
);

export const uploadImage = createAsyncThunk(
  "users/uploadProfileImage",
  async (action, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${local}/${action.subscriberId}/avatar?`,
        action.payload,
        header()
      );
      if (response?.data?.success) {
        message.info("Image was uploaded successfully");
      }
      return response.data;
    } catch (error) {
      message.error("Image upload unsuccessful");
      const e = handleError(error);
      return rejectWithValue(e);
    }
  }
);
export const addgroupMember = createAsyncThunk(
  "users/addgroupMember",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${local}/subscribers/group`,
        payload,
        header()
      );
      if (response?.data?.success) {
        message.info("Group member added successfully");
      }
      return response.data;
    } catch (error) {
      message.error("Group member not added successfully");
      const e = handleError(error);
      return rejectWithValue(e);
    }
  }
);

export const createGroup = createAsyncThunk(
  "users/createGroup",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${local}/group`, payload, header());
      if (response?.data?.success) {
        message.info("Group member added successfully");
      }
      return response.data;
    } catch (error) {
      message.error("Group member not added successfully");
      const e = handleError(error);
      return rejectWithValue(e);
    }
  }
);

export const getGroupMembers = createAsyncThunk(
  "users/getGroupMember",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${local}/subscribers/group`, header());
      return response.data;
    } catch (error) {
      const e = handleError(error);
      return rejectWithValue(e);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "users/verifyOtp",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${local}/new/group/member`,
        payload,
        header()
      );
      if (response?.data?.success) {
        message.success("Successful");
      }
      return response.data;
    } catch (error) {
      message.error("Verification unsuccessful");
      const e = handleError(error);
      return rejectWithValue(e);
    }
  }
);

const initialState = {
  loading: false,
  success: false,
  error: null,
  subscription: {
    active: false,
    subscriber: {
      user: {},
    },
    payments: null,
    benefits: null,
    amountLeftAnnually: [],
    providers: [],
  },
  groupDetails: null,
  group: null,
  groupStats: null,
  groupInfo: {},
  facilities: null,
};

export const subscriberSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSubscription.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true; // registration successful
        state.subscription = payload.subscription;
        state.groupDetails = payload.groupInfo;
      })
      .addCase(getSubscription.rejected, (state, { payload }) => {
        state.loading = false;
        state.success = false;
        state.error = payload;
      })
      .addCase(activatePlan.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(activatePlan.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload) state.success = true; // registration successful
        // if (state.success) window.location.href = "/dashboard";
      })
      .addCase(activatePlan.rejected, (state, { payload }) => {
        state.loading = false;
        state.success = false;
        state.error = payload;
      })
      .addCase(topUp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(topUp.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true; // registration successful
        if (state.success) window.location.href = "/dashboard";
      })
      .addCase(topUp.rejected, (state, { payload }) => {
        state.loading = false;
        state.success = false;
        state.error = payload;
      })
      .addCase(activatePlanVoucher.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(activatePlanVoucher.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true; // registration successful
        if (state.success) window.location.href = "/dashboard";
      })
      .addCase(activatePlanVoucher.rejected, (state, { payload }) => {
        state.loading = false;
        state.success = false;
        state.error = payload;
      })
      .addCase(activateGroupMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(activateGroupMember.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true; // registration successful
        if (state.success) window.location.href = "/dashboard";
      })
      .addCase(activateGroupMember.rejected, (state, { payload }) => {
        state.loading = false;
        state.success = false;
        state.error = payload;
      })
      .addCase(createGroup.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createGroup.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.groupInfo = action.payload.groupInfo;
      })
      .addCase(createGroup.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(getGroupMembers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getGroupMembers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true; // registration successful
        state.group = payload.group;
        state.groupStats = payload.groupStats;
      })
      .addCase(getGroupMembers.rejected, (state, { payload }) => {
        state.loading = false;
        state.success = false;
        state.error = payload;
      })
      .addCase(getProvidersList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProvidersList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true; // registration successful
        state.facilities = payload.facilities;
      })
      .addCase(getProvidersList.rejected, (state, { payload }) => {
        state.loading = false;
        state.success = false;
        state.error = payload;
      });
  },
});

export default subscriberSlice.reducer;
