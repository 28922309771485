import { BiFilter } from "react-icons/bi";
function Filter() {
  return (
    <div className="filter">
      <span>Filter</span>
      <BiFilter />
    </div>
  );
}

export default Filter;
