import Card from "./card";
import { RiCalendar2Fill } from "react-icons/ri";
import Filter from "../general/Filter";
const DashboardAdminComponent = () => {
  return (
    <div className="admin-dashboard">
      <div className="admin-dashboard-body">
        <Filter />
        <div className="sub-header">
          <div>
            <RiCalendar2Fill color="rgba(72, 72, 72, 0.6)" />
          </div>
          <span>August 26th, 2022 - Sept. 26th, 2022 </span>
        </div>
      </div>
      <div className="card-container">
        <Card figure="1,822" subtitle="Total Subscribers" />
        <Card figure="1,022" subtitle="Providers Onboarded" />
        <Card figure="989" subtitle="Sales representatives" />
        <Card figure="3,911" subtitle="Encounter vetted" />
        <Card figure="₦43,850,000.00" subtitle="Total Subscription Amount" />
        <Card figure="₦1,020,000" subtitle="Encounter Billed" />
        <Card figure="₦43,850,000.00" subtitle="Claims paid" />
        <Card figure="₦23,483" subtitle="Subscriber Payments Amount" />
      </div>
    </div>
  );
};
export default DashboardAdminComponent;
