import hospitalImg from "../../../assets/images/dashboard/Top Hospital.png";
import encounterImg from "../../../assets/images/dashboard/Top Encounter.png";
import planImg from "../../../assets/images/dashboard/Top cohort plan.png";
import amountImg from "../../../assets/images/dashboard/Top Amont paid.png";
import cohortplanImg from "../../../assets/images/dashboard/Top Total med.png";
import DashboardHeaderTop from "./DashboardHeaderTop";
import { useState } from "react";
import { MdArrowDropUp } from "react-icons/md";
import { IoCaretForward, IoCaretBack } from "react-icons/io5";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import useWindowWidth from "../../../hooks/useWindowWidth";
import SummaryCarousel from "../general/mobile/SummaryCarousel";
function DashboardHeader({ type }) {
  const { subscription, groupStats } = useSelector((state) => state.subscriber);
  const endDate = moment(subscription?.endDate).format("DD-MMM-YYYY");
  let renewBy;
  if (subscription?.planName !== "Starter-Sachet®") {
    renewBy = moment(subscription?.startDate)
      .add(
        subscription.amountLeftMonthly &&
          subscription?.amountLeftMonthly[
            subscription?.amountLeftMonthly?.length - 1
          ]?.counter,
        "months"
      )
      .format("DD-MMM-YYYY");
  } else {
    renewBy = endDate;
  }

  const sizeLimitText = subscription.planType === 'Basic' ? `/${subscription.subscriber.dependentSize + 1}` : "/20"
  const onboardedText = subscription.planType === 'Basic' ? "onboarded/group size" : "Total onboarded/min required"
  const groupMemberSummary = [
    {
      heading: onboardedText,
      name: groupStats ? groupStats?.totalMembers + sizeLimitText : "0",
      image: planImg,
      desc: "Total onboarded",
    },
    {
      heading: "Group status",
      name: groupStats ? groupStats?.status : "--",
      image: encounterImg,
      desc: "Current Status",
    },
    {
      heading: "Total contribution (current year)",
      name: groupStats
        ? "₦" + groupStats?.totalPaidCurrent?.toLocaleString("en-US")
        : "₦0.00",
      image: cohortplanImg,
      desc: "Total amount paid",
    },
    {
      heading: "Total contribution (all time)",
      name: groupStats
        ? "₦" + groupStats?.totalPaidAllTime?.toLocaleString("en-US")
        : "₦0.00",
      image: amountImg,
      desc: "Total amount used",
    },
    {
      heading: "Expenses (current year)",
      name: groupStats
        ? "₦" + groupStats?.totalExpensesCurrent?.toLocaleString("en-US")
        : "₦0.00",
      image: encounterImg,
      desc: "Current Expenses",
    },
    {
      heading: "Total expenses (all time)",
      name: groupStats
        ? "₦" + groupStats?.totalExpensesAllTime?.toLocaleString("en-US")
        : "₦0.00",
      image: encounterImg,
      desc: "Total Expenses",
    },
    {
      heading: "Next group appointment",
      name: groupStats?.meetings?.date ? groupStats?.meetings?.date : "--",
      image: hospitalImg,
      desc: "Location",
    },
  ];
  const dashboardSummary =
    type === "normal"
      ? [
          {
            heading: "Sachet Plan",
            name: subscription.planName || "--",
            image: planImg,
            desc:
              "₦" +
              subscription?.monthlyPremium?.toLocaleString("en-US") +
              ".00/month/life",
          },
          {
            heading: "Premium",
            name: subscription?.annualPremium
              ? "₦" +
                subscription?.annualPremium?.toLocaleString("en-US") +
                ".00/ Yr"
              : "₦0.00",
            image: cohortplanImg,
            desc:
              "Benefits apply until " +
              moment(subscription?.endDate).format("DD-MMM-YYYY"),
          },
          {
            heading: "Total Amount Paid",
            name: subscription?.totalPaid
              ? "₦" + subscription?.totalPaid?.toLocaleString("en-US") + ".00"
              : "₦0.00",
            image: amountImg,
            desc: subscription.totalPaid
              ? "Renew by " + renewBy
              : "Activate by " + renewBy,
          },
          {
            heading: "Last Encounter",
            name: "--",
            image: encounterImg,
            desc: "--",
          },
          {
            heading: "Upcoming Appointment",
            name: "--",
            image: hospitalImg,
            desc: "--",
          },
        ]
      : groupMemberSummary;

  const [showMore, setShowMore] = useState(5);
  const [start, setStart] = useState(0);
  const width = useWindowWidth();
  useEffect(() => {
    width <= 600 ? setShowMore(2) : setShowMore(5);
  }, [width, type]);
  return (
    <>
      <div>
        <DashboardHeaderTop />
        <div className="dashboard-summary">
          {type === "normal" && width > 600
            ? dashboardSummary.slice(0, showMore).map((item, i) => {
                return (
                  <div key={i}>
                    <h5>{item.heading}</h5>
                    <div className="dashboard-summary-item">
                      <img src={item.image} alt="" />
                      <div>
                        <h3>{item.name}</h3>
                        <h5>{item.desc}</h5>
                      </div>
                    </div>
                  </div>
                );
              })
            : type !== "normal" &&
              width > 600 &&
              dashboardSummary.slice(start, showMore).map((item, i) => {
                return (
                  <div key={i}>
                    <h5>{item.heading}</h5>
                    <div className="dashboard-summary-item">
                      <img src={item.image} alt="" />
                      <div>
                        <h3>{item.name}</h3>
                        <h5>{item.desc}</h5>
                      </div>
                    </div>
                  </div>
                );
              })}
          {showMore === 2 && width > 600 ? (
            <button
              className="dashboard-summary-btn"
              onClick={() => setShowMore(5)}
            >
              See more
            </button>
          ) : (
            showMore !== 2 &&
            width > 600 && (
              <span
                className="floating-btn"
                onClick={() => {
                  setShowMore(2);
                  type !== "normal" && setStart(0);
                }}
              >
                <MdArrowDropUp size="32" />
              </span>
            )
          )}
          {type !== "normal" && width > 600 && (
            <div className="group-summary-btn">
              {showMore !== 7 ? (
                <button
                  className="floating-btn"
                  onClick={() => {
                    setStart(2);
                    setShowMore(7);
                  }}
                >
                  <IoCaretForward />
                </button>
              ) : (
                <button
                  className="floating-btn"
                  onClick={() => {
                    setStart(0);
                    setShowMore(5);
                  }}
                >
                  <IoCaretBack />
                </button>
              )}
            </div>
          )}
          {type === "normal" && width <= 600 ? (
            <>
              <SummaryCarousel cards={dashboardSummary} />
            </>
          ) : (
            type !== "normal" &&
            width <= 600 && <SummaryCarousel cards={dashboardSummary} />
          )}
        </div>
      </div>
    </>
  );
}

export default DashboardHeader;
