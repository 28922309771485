import { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { MdKeyboardBackspace } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  updateEmail,
  updatePassword,
  updatePhoneNumber,
  verifyOtp,
  sendOtpPhone,
  sendOtpEmail,
} from "../../../../redux/settings/reducer";
import { getSubscription } from "../../../../redux/subscriber/reducer";
import { ResendOtp } from "../../../../redux/authentication/reducer";

function UpdateForm({ type, backTomenu }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [updatePage, setUpdatePage] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [err, setError] = useState("");
  const dispatch = useDispatch();
  const user = localStorage.getItem("user");
  const params = user !== "undefined" ? JSON.parse(user) : "";
  const subscriberId = localStorage.getItem("subscriberId");
  const { error } = useSelector((state) => state.setting);
  const handleChange = (e) => {
    if (!updatePage) {
      if (type === "Email") setPhone(e.target.value);
      if (type === "Phone" || type === "Password") setEmail(e.target.value);
      if (e.target.name === "otp") setOtp(e.target.value);
    } else {
      if (type === "Password") {
        e.target.name === "password"
          ? setPassword(e.target.value)
          : setConfirmPassword(e.target.value);
      }
      if (type === "Phone") setPhone(e.target.value);
      if (type === "Email") setEmail(e.target.value);
    }
  };
  const handleSubmitOtp = (e) => {
    e.preventDefault();
    setPhone("");
    setEmail("");
    dispatch(
      verifyOtp({
        otpId: params?.otpId,
        initiatedOnId: params?.userId,
        otp: otp,
      })
    );
    setUpdatePage(!updatePage);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== "") {
      if (password === confirmPassword) {
        setError("");
        dispatch(updatePassword({ password, confirmPassword }));
        setPassword("");
        setConfirmPassword("");
      } else {
        setError("The entered passwords are not thesame");
      }
    }
    if (phone !== "") dispatch(updatePhoneNumber({ phone }));
    if (email !== "") dispatch(updateEmail({ email }));
    setPhone("");
    setEmail("");
    err !== "" ? setUpdatePage(false) : setUpdatePage(true);
    dispatch(getSubscription(subscriberId));
  };

  // Resend Otp
  const handleResendOtp = () => {
    dispatch(
      ResendOtp({
        initiatedOnId: params.userId,
      })
    );
  };
  return (
    <>
      {!updatePage && (
        <>
          <span className="mobile" onClick={() => backTomenu(true)}>
            <MdKeyboardBackspace fontSize="20px" />
            <span>Back</span>
          </span>
          <h2>Change {type}</h2>
          <form onSubmit={handleSubmitOtp}>
            <div className="form-container">
              <div className="form-body">
                <input
                  type={type !== "Email" ? "tel" : "email"}
                  name={type !== "Email" ? "email" : "phone"}
                  placeholder={type !== "Email" ? "Email" : "Phone"}
                  onChange={handleChange}
                  required
                />
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    type !== "Email"
                      ? dispatch(sendOtpEmail({ email }))
                      : dispatch(sendOtpPhone({ phone }))
                  }
                >
                  Send OTP
                </span>
                <p> We will send an OTP to your phone/ email</p>
              </div>
              <div className="form-body">
                <input
                  type="text"
                  name="otp"
                  placeholder="Enter OTP"
                  onChange={handleChange}
                  required
                />
                <p className="resend-otp" onClick={handleResendOtp}>
                  Resend OTP
                </p>
              </div>
              <button>Submit</button>
            </div>
          </form>
        </>
      )}
      {updatePage && !error && (
        <div>
          <div className="back">
            <span onClick={() => setUpdatePage(!updatePage)}>
              <MdKeyboardBackspace />
            </span>
            <h2>Change {type}</h2>
          </div>
          <h1 style={{ fontSize: "14px" }}>
            Enter new {type !== "Phone" ? type : type + " number"}
          </h1>
          {/* <p>Please enter a new password</p> */}
          <form onSubmit={handleSubmit}>
            {type !== "Password" && (
              <div className="form-body">
                {type === "Phone" ? (
                  <input
                    name={type.toLowerCase()}
                    type="tel"
                    placeholder={type !== "Phone" ? type : type + " number"}
                    onChange={handleChange}
                    pattern="^\d{11}$"
                    required
                  />
                ) : (
                  <input
                    name={type.toLowerCase()}
                    type="email"
                    placeholder={type}
                    onChange={handleChange}
                    pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                    required
                  />
                )}
              </div>
            )}
            {type === "Password" && (
              <>
                <div className="form-body">
                  <input
                    name="password"
                    type={!showPassword ? type.toLowerCase() : "text"}
                    placeholder={type}
                    onChange={handleChange}
                    minlength="8"
                    required
                  />
                  <span onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? (
                      <AiOutlineEye />
                    ) : (
                      <AiOutlineEyeInvisible />
                    )}
                  </span>
                </div>
                <div className="form-body">
                  <input
                    name="confirmPassword"
                    type={!showPassword ? type.toLowerCase() : "text"}
                    placeholder={"Confirm " + type}
                    onChange={handleChange}
                    minlength="8"
                    required
                  />
                  <span onClick={() => setShowPassword1(!showPassword1)}>
                    {showPassword ? (
                      <AiOutlineEye />
                    ) : (
                      <AiOutlineEyeInvisible />
                    )}
                  </span>
                </div>
                <div
                  style={{ marginTop: "-20px", marginLeft: "-10px" }}
                  className="err-message"
                >
                  {err}
                </div>
              </>
            )}
            <button>Submit</button>
          </form>
        </div>
      )}
    </>
  );
}

export default UpdateForm;
