import React from "react";

function DeleteAndDeactivateBtn() {
  return (
    <div className="btn-container">
      <button className="btn-deactivate">Deactivate</button>
      <button className="btn-del">Delete</button>
    </div>
  );
}

export default DeleteAndDeactivateBtn;
