import { TiTick, TiTimes } from "react-icons/ti";
import React, { useState, useEffect } from "react";
import { benefits } from "./benefits";
import "./plan.scss";
import PaymentForm from "../../components/Payment/PaymentForm";
import MobilePlan from "./MobilePlan";
import CreateGroupForm from "../../components/Plan/CreateGroupForm";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
  MdOutlineArrowForward,
} from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSubscription } from "../../redux/subscriber/reducer";
import { logout } from "../../redux/authentication/reducer";

export const plans = [
  { name: "Starter-Sachet®", cost: "0.0", planName: "Cohort 0" },
  // { name: "Mini-Sachet®", cost: "2,120", planName: "Cohort 1" },
  { name: "Standard-Sachet®", cost: "3,290", planName: "Cohort 2" },
  { name: "Super-Sachet®", cost: "3,990", planName: "Cohort 3" },
  { name: "Premium-Sachet®", cost: "5,883", planName: "Cohort 4" },
  { name: "Maxi-Sachet®", cost: "7,883", planName: "Cohort 5" },
  { name: "Bouquet-Sachet®", cost: "10,688", planName: "Cohort 6" },
  // { name: "Grand-Sachet®", cost: "15,000", planName: "Cohort 7" },
  // { name: "Golden-Sachet®", cost: "20,833", planName: "Cohort 8" },
];
const Plan = ({ entry }) => {
  const location = useLocation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [navigate, setNavigate] = useState(0);
  const [animate, setAnimate] = useState(null);
  const [createGroup, setCreateGroup] = useState(false);
  const [called, setCalled] = useState(false);
  const showModal = (plan) => {
    setSelectedPlan(plan);
    location.state === "Group"
      ? setCreateGroup(true)
      : setIsModalVisible(!isModalVisible);
  };
  const subscriberId = window.localStorage.getItem("subscriberId");
  const { subscription, error } = useSelector((state) => state.subscriber);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSubscription(subscriberId));
  }, [dispatch, subscriberId]);
  return (
    <section className="plan-container">
      {(createGroup || (error && called)) && (
        <CreateGroupForm
          setCreateGroup={setCreateGroup}
          setCalled={setCalled}
          plan={selectedPlan}
          setIsModalVisible={setIsModalVisible}
        />
      )}
      {isModalVisible && !error && (
        <div className="payment-card">
          <PaymentForm
            plan={selectedPlan}
            type={location.state === "Group" ? "Closed" : "Open"}
            paymentFor={"Onboard"}
          />
          <div>
            <button onClick={() => setIsModalVisible(false)}>Close</button>
          </div>
        </div>
      )}
      <div className={(isModalVisible || createGroup) && "overlay"}></div>
      <div>
        <h1 className="plan-h1">Best Plans for you</h1>
        <p>
          <b></b> please select a plan
        </p>

        {location.state !== "Group" && (
          <p className="skip-payment">
            {subscription?.planName ? (
              <>
                <Link to="/dashboard">Continue to dashboard</Link>
                <MdOutlineArrowForward />
              </>
            ) : (
              <>
                <Link onClick={() => logout()}>Back</Link>
              </>
            )}
          </p>
        )}
        <div className="plan-table-container">
          <table className="table">
            <thead>
              <tr>
                <th className="table-left-header">
                  <span className="left-header-title">Benefits</span>
                </th>
                {plans.map((plan, i) => (
                  <th
                    className={(i + 1) % 2 === 0 ? "table-divider " : ""}
                    key={i}
                  >
                    <span className="plan-name">{plan.name}</span>
                    <span className="plan-price">{"N" + plan.cost}</span>
                    <span className="plan-duration">Monthly</span>
                    {location.state !== "comparePlans" ? (
                      <span
                        className="plan-selection"
                        onClick={() => showModal(plan)}
                      >
                        select
                      </span>
                    ) : (
                      <></>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {benefits.map((item, i) => (
                <tr key={i}>
                  <td className="table-left">
                    <span>{item.name}</span>
                  </td>
                  <td className="">
                    {item.cohort0 === "TiTick" ? (
                      <TiTick />
                    ) : item.cohort0.length !== 0 ? (
                      <span>{item.cohort0}</span>
                    ) : (
                      <TiTimes />
                    )}
                  </td>
                  <td className="table-divider">
                    {item.cohort2 === "TiTick" ? (
                      <TiTick />
                    ) : item.cohort2.length !== 0 ? (
                      <span>{item.cohort2}</span>
                    ) : (
                      <TiTimes />
                    )}
                  </td>
                  <td>
                    {item.cohort3 === "TiTick" ? (
                      <TiTick />
                    ) : item.cohort3.length !== 0 ? (
                      <span>{item.cohort3}</span>
                    ) : (
                      <TiTimes />
                    )}
                  </td>
                  <td className="table-divider">
                    {item.cohort4 === "TiTick" ? (
                      <TiTick />
                    ) : item.cohort4.length !== 0 ? (
                      <span>{item.cohort4}</span>
                    ) : (
                      <TiTimes />
                    )}
                  </td>
                  <td>
                    {item.cohort5 === "TiTick" ? (
                      <TiTick />
                    ) : item.cohort5.length !== 0 ? (
                      <span>{item.cohort5}</span>
                    ) : (
                      <TiTimes />
                    )}
                  </td>
                  <td className="table-divider">
                    {item.cohort6 === "TiTick" ? (
                      <TiTick />
                    ) : item.cohort6.length !== 0 ? (
                      <span>{item.cohort6}</span>
                    ) : (
                      <TiTimes />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="mobile-layout">
        <div
          className="navigate-back"
          onClick={() => {
            setNavigate(navigate - 1);
            setAnimate(navigate % 2 === 0 ? "left" : "left1");
            // if (location.state === "Group") navigate === 2 && setNavigate(4);
            navigate === 0 && setNavigate(4);
          }}
        >
          <MdOutlineArrowBackIos />
        </div>
        <div
          className="navigate-forward"
          onClick={() => {
            setNavigate(navigate + 1);
            setAnimate(navigate % 2 === 0 ? "right" : "right1");
            // if (location.state === "Group") navigate === 4 && setNavigate(0);
            navigate === 5 && setNavigate(0);
          }}
        >
          <MdOutlineArrowForwardIos />
        </div>
        <MobilePlan
          planName={plans[navigate].name}
          cost={plans[navigate].cost}
          cohort={plans[navigate].planName}
          animate={animate}
          showModal={showModal}
          divider={navigate % 2 === 0 ? true : false}
          comparePlans={location.state}
        />
      </div>
    </section>
  );
};

export default Plan;
