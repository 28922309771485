import React, { useState } from "react";
import Pagination from "../../global/Pagination";

function Table({ data, setDetailsPage, setProfileDetails, type }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);

  // Get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.list.slice(indexOfFirstItem, indexOfLastItem);
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  // handle click
  const handleClick = (item) => {
    setDetailsPage(true);
    setProfileDetails(item);
  };
  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            {data.heading.map((item, i) => (
              <th key={i}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, i) => {
            return type === "subscription" ? (
              <tr key={i} onClick={() => handleClick(item)}>
                <td>{i}</td>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.pE}</td>
                <td>{item.location}</td>
                <td>{item.ecn}</td>
                <td>{item.stat}</td>
              </tr>
            ) : type === "encounters" ? (
              <tr key={i} onClick={() => handleClick(item)}>
                <td>{i}</td>
                <td>{item.ecn}</td>
                <td>{item.benefits}</td>
                <td>{item.dO}</td>
                <td>{item.dC}</td>
                <td>{item.paid}</td>
                <td>{item.stat}</td>
              </tr>
            ) : type === "providers" ? (
              <tr key={i} onClick={() => handleClick(item)}>
                <td>{i}</td>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.pE}</td>
                <td>{item.nos}</td>
                <td>{item.noe}</td>
                <td>{item.stat}</td>
              </tr>
            ) : type === "claims" ? (
              <tr key={i} onClick={() => handleClick(item)}>
                <td>{i}</td>
                <td>{item.id || item.ecn}</td>
                <td>{item.name || item.dO}</td>
                <td>{item.pE || item.dC}</td>
                <td>{item.location || item.noe || item.dV}</td>
                <td>{item.ecn || item.nos || item.billed}</td>
                {item.paid && <td>{item.paid}</td>}
                <td>{item.stat}</td>
              </tr>
            ) : type === "salesReps" ? (
              <tr key={i} onClick={() => handleClick(item)}>
                <td>{i}</td>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.pE}</td>
                <td>{item.location}</td>
                <td>{item.state}</td>
                <td>{item.stat}</td>
              </tr>
            ) : (
              type === "settings" && (
                <tr key={i} onClick={() => handleClick(item)}>
                  <td>{i}</td>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.pE}</td>
                  <td>{item.role}</td>
                  <td>{item.stat}</td>
                </tr>
              )
            );
          })}
        </tbody>
        <tfoot>
          <Pagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={data.list.length}
            paginate={paginate}
          />
        </tfoot>
      </table>
    </div>
  );
}

export default Table;
