import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import avatar from "../../assets/images/avatar.png";
import forgotPasswordImg from "../../assets/images/signUp_img.png";
import "./auth.scss";
import { BsPhoneFill } from "react-icons/bs";
import Otp from "../../components/auth/Otp";
import { Spin } from "antd";
import HeaderNav from "../../components/auth/Nav";
import { BiArrowBack } from "react-icons/bi";
import { forgotPassword } from "../../redux/authentication/reducer";

const ForgotPassword = () => {
  // const [loading, setLoading] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [phone, setPhone] = useState("");
  const [focused, setFocused] = useState(false);
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.auth);
  const handleClick = (e) => {
    e.preventDefault();
    dispatch(forgotPassword({ phone }));
    setShowOtp(true);
  };

  // Store form input in state
  const handleChange = ({ target: { value } }) => {
    setPhone(value);
  };

  // Handle input validations
  const handleFocus = ({ target: { name } }) => {
    const rePhone = /^\d{11}$/;
    if (phone === "") {
      setFocused(true);
    } else {
      setFocused(name === "phone" && rePhone.test(phone) ? false : true);
    }
  };
  return (
    <section className="signUp-container">
      <HeaderNav />
      <div className="signUp-wrapper">
        <div className="signUp-left">
          <h1>End-to-End</h1>
          <h1>Transparency</h1>
          <p>Know what you have access to, and how you use it.</p>
          <p className="signUp-left-provider">Are you a provider? Click here</p>
          <img src={forgotPasswordImg} alt="" className="signIn-img" />
        </div>
        <div className="signUp-right">
          {!showOtp || error ? (
            <div className="forgot-password-container">
              <img src={avatar} alt="" className="signUp-right-avater" />
              <h2 className="header">Forgot Password?</h2>
              <p>Please enter Phone number associated with account</p>
              {error && <span className="err-message">{error}</span>}
              <form className="form">
                <div className="form-bottom">
                  <div className={focused ? "err-box" : ""}>
                    <BsPhoneFill className="icon" />
                    <input
                      placeholder="Phone"
                      name="phone"
                      onChange={handleChange}
                      onBlur={handleFocus}
                      value={phone}
                      pattern="^\d{11}$"
                      required
                    />
                  </div>
                  {focused.phone && (
                    <span className="err-message-bottom">
                      Enter valid phone number
                    </span>
                  )}
                </div>

                <button
                  loading={loading.toString()}
                  className="btn-custom"
                  onClick={handleClick}
                >
                  {loading ? <Spin /> : "Submit"}
                </button>
                <div className="back-arrow">
                  <Link to="/login" className="back-arrow">
                    <BiArrowBack /> <span>Back</span>
                  </Link>
                </div>
                <p>
                  Don’t have an account?
                  <Link
                    to="/"
                    onClick={() => error && (window.location.href = "/")}
                    className="b-link"
                  >
                    <b> Sign up</b>
                  </Link>
                </p>
              </form>
            </div>
          ) : (
            <div className="verify-container">
              <div>
                <img src={avatar} alt="" className="signUp-right-avater" />
                <h2>Enter OTP</h2>
                <p>
                  A four digit code has been sent to {phone}, and email address
                </p>
                <p>The code expires in 10 mins</p>
              </div>
              <Otp verify="forgotPassword" />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
