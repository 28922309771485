import nopics from "../../../assets/images/no-picture.jpg";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
//import logoMobile from "../../../assets/images/logo.svg";
import { FiMenu } from "react-icons/fi";
import { RiHomeLine } from "react-icons/ri";
import { FiSettings } from "react-icons/fi";
import { TbFirstAidKit } from "react-icons/tb";
import { HiOutlineUserGroup } from "react-icons/hi";
import { BiSupport, BiLogOut } from "react-icons/bi";
import { ReactComponent as Subscribe } from "../../../assets/images/dashboard/subscribe.svg";
import { AiOutlineClose } from "react-icons/ai";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { Link } from "react-router-dom";
import { logout } from "../../../redux/authentication/reducer";
const date = new Date();
const DashboardNav = ({ user, id, profileUrl }) => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const handleShow = () => {
    setShow(false);
  };
  const handlLogout = () => {
    dispatch(logout());
  };
  return (
    <div className="dasboardNav">
      {!show ? (
        <div onClick={() => setShow(true)}>
          <FiMenu fontSize={28} />
        </div>
      ) : (
        <div className="dasboardNavContainer">
          <div className="dashboardProfile">
            <div className="profile">
              <img
                style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                src={profileUrl || nopics}
                alt={profileUrl ? "profileUrl" : "nopics"}
              />
              <div>
                <h3>{user?.username}</h3>
                <p>{id}</p>
              </div>
            </div>
            <button onClick={handleShow}>
              <AiOutlineClose fontSize={24} />
            </button>
          </div>
          <div className="divider"></div>
          <div className="dasboardNavItems">
            <RiHomeLine />
            <Link to="/dashboard" onClick={handleShow}>
              Dashboard
            </Link>
          </div>
          <div className="dasboardNavItems">
            <Subscribe />
            <Link to="/dashboard/subscription" onClick={handleShow}>
              Subscription
            </Link>
          </div>
          <div className="dasboardNavItems">
            <TbFirstAidKit />{" "}
            <Link to="/dashboard/encounters" onClick={handleShow}>
              Encounter
            </Link>
          </div>
          <div className="dasboardNavItems">
            <HiOutlineUserGroup />
            <Link to="/dashboard/group members" onClick={handleShow}>
              Group
            </Link>
          </div>
          <div className="dasboardNavItems">
            <BiSupport />
            <Link to="/dashboard/support" onClick={handleShow}>
              Support
            </Link>
          </div>
          <div className="dasboardNavItems">
            <FiSettings />
            <Link to="/dashboard/setting" onClick={handleShow}>
              Setting
            </Link>
          </div>
          <div className="dasboardNavItems">
            <BiLogOut />
            <span onClick={handlLogout}>Log out</span>
          </div>
        </div>
      )}
    </div>
  );
};
function DashboardHeaderTop() {
  const [greeting, setGreeting] = useState("Good Day");
  const width = useWindowWidth();
  const { user, profileUrl, policyNumber } = useSelector(
    (state) => state?.subscriber?.subscription?.subscriber
  );
  useEffect(() => {
    if (date.getHours() < 12) setGreeting("Good Morning");
    if (date.getHours() >= 12 || date.getHours() < 17)
      setGreeting("Good Afternoon");
    if (date.getHours() >= 17 || date.getHours() === 0)
      setGreeting("Good Evening");
  }, []);
  // const name = user.username && user.username.split(" ");
  return (
    <header className="dashboard-header">
      <h1>{greeting + ", " + (user?.username ? user?.username : "--")} !</h1>
      {width <= 600 ? (
        <DashboardNav user={user} id={policyNumber} profileUrl={profileUrl} />
      ) : (
        <div className="dashboard-header-end">
          <img
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
            src={profileUrl || nopics}
            alt=""
          />
        </div>
      )}
    </header>
  );
}

export default DashboardHeaderTop;
