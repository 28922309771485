import React from "react";
import cohortplanImg from "../../../assets/images/dashboard/Top Total med.png";
import encounterImg from "../../../assets/images/dashboard/Top Encounter.png";
import planImg from "../../../assets/images/dashboard/Top cohort plan.png";
import amountImg from "../../../assets/images/dashboard/Top Amont paid.png";

const dashboardSummary = [
  {
    heading: "Active Subscribers",
    image: planImg,
    desc: "23,483",
  },
  {
    heading: "Active Providers",
    image: cohortplanImg,
    desc: "23,030",
  },
  {
    heading: "Claims open",
    image: amountImg,
    desc: "3,991",
  },
  {
    heading: "Claims vetted",
    image: encounterImg,
    desc: "4,281",
  },
];

function DashboardAdminHeader() {
  return (
    <>
      <div>
        <div className="admin-header">
          {dashboardSummary.map((item, i) => {
            return (
              <div className="admin-header-item-container" key={i}>
                <h5>{item.heading}</h5>
                <div className="admin-header-item">
                  <img src={item.image} alt="" />
                  <div>
                    <h3>{item.desc}</h3>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default DashboardAdminHeader;
