import React from "react";
import { BiArrowBack } from "react-icons/bi";
import "./BackNav.scss";
import { Link } from "react-router-dom";
function BackNav({ text }) {
  return (
    <Link to="/dashboard" className="backNav">
      <BiArrowBack fontSize="24px" />
      <h4>{text}</h4>
    </Link>
  );
}

export default BackNav;
