import { BiSearch } from "react-icons/bi";
function SearchBox() {
  return (
    <div className="search">
      <BiSearch />
      <input placeholder="Search" />
    </div>
  );
}

export default SearchBox;
