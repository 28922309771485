import React, { useState } from "react";
import Pagination from "../../global/Pagination";
import { AiOutlineEyeInvisible } from "react-icons/ai";

function Tables({ data, type, viewer, handlePay, setShowdetails, planType }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);
  const [numOfItemsPerPage, setNumOfItemsPerPage] = useState(1);
  // Get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.list.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (pageNumber === 1) setNumOfItemsPerPage(1);
    if (pageNumber === 2) setNumOfItemsPerPage(7);
    if (pageNumber === 3) setNumOfItemsPerPage(13);
    if (pageNumber === 4) setNumOfItemsPerPage(19);
  };

  return (
    <>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              {data.heading.map((item, i) => (
                <th key={i}>{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, i) => {
              return type === "groupMembers" ? (
                <tr key={i}>
                  <td>{i + numOfItemsPerPage}</td>
                  <td>
                    {item.subscriber.isPrincipal && item.planType === "Closed"
                      ? item.subscriber.policyNumber + "-**"
                      : item.subscriber.policyNumber}
                  </td>
                  <td>
                    <b>
                      {item?.subscriber?.lastName +
                        " " +
                        item?.subscriber?.firstName}
                    </b>
                  </td>
                  <td>{item?.subscriber?.sex || "-"}</td>
                  <td>
                    {item?.subscriber?.user ? (
                      <>
                        <span style={{ fontSize: 10 }}>
                          {"0" + item?.subscriber?.user?.phone}
                        </span>
                        <br></br>
                        <span style={{ fontSize: 10 }}>
                          {item?.subscriber?.user?.email}
                        </span>
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {item?.planName === "Starter-Sachet®"
                      ? "Inactive"
                      : "Activated"}
                  </td>
                  <td>
                    {item?.durationCovered >= item?.durationUsedUp
                      ? "Up to date"
                      : "delayed"}
                  </td>
                  <td>{item?.totalPaid || "0.0"}</td>
                  <td>{item?.totalExpenses || "0.0"}</td>
                  <td>{item?.active ? "Yes" : "No"}</td>
                  <td>
                    {item?.id !== viewer && (
                      <span
                        style={{ color: "red" }}
                        className="member-pay"
                        onClick={() => handlePay(item)}
                      >
                        {item?.subscriber?.hasSubscription
                          ? "Sponsor"
                          : "Activate"}
                      </span>
                    )}
                  </td>
                  {planType === "Basic" && item?.subscriber.groupRelationship === "Dependent" && (
                    <td onClick={() => setShowdetails(item.subscriber._id)}>
                      <AiOutlineEyeInvisible style={{ fontSize: "20px" }} />
                    </td>
                  )}
                </tr>
              ) : type === "history" ? (
                <tr key={i}>
                  <td>{i}</td>
                  <td>{item.ecn}</td>
                  <td>{item.provider}</td>
                  <td>{item.dO}</td>
                  <td>{item.dC}</td>
                  {/* <td>{item.used}</td>
                <td>{item.investigation}</td> */}
                </tr>
              ) : type === "prescriptions" ? (
                <tr key={i}>
                  <td>{i}</td>
                  <td>{item.ecn}</td>
                  <td>{item.provider}</td>
                  <td>{item.drug}</td>
                  <td>{item.dd}</td>
                  <td>{item.cost}</td>
                </tr>
              ) : (
                type === "appointments" && (
                  <tr key={i}>
                    <td>{i}</td>
                    <td>{item.ecn}</td>
                    <td>{item.provider}</td>
                    <td>{item.date}</td>
                    <td>{item.time}</td>
                  </tr>
                )
              );
            })}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={data.list.length}
          paginate={paginate}
        />
      </div>
    </>
  );
}

export default Tables;
