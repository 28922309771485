import { useEffect, useState } from "react";
import noMembersImg from "../../../assets/images/nodata/prescription-nodata.png";
import CardHeader from "../general/CardHeader";
import CardModal from "../general/CardModal";
import Search from "../general/Search";
import AddGroupMember from "./AddGroupMember";
import { Spin } from "antd";

function GroupMemberMobileCard({
  group,
  groupDetails,
  viewer,
  handlePay,
  planType,
  setShowdetails,
}) {
  const [showCard, setShowCard] = useState(false);
  const [details, setdetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [popup, setPopup] = useState(false);
  const handleShowCard = (item) => {
    setShowCard(true);
    setdetails(item);
  };
  useEffect(() => {
    setTimeout(() => setLoading(false), 10000);
  }, []);
  return (
    <div>
      <div className="mobile">
        <span className="mobile-header">Members</span>
        <br />
        <span className="sub-head">Group ID - {groupDetails?.groupId}</span>
        <br />
        {group ? (
          <div className="card">
            {popup && (
              <>
                <div className="overlay"></div>
                <AddGroupMember setPopup={setPopup} />
              </>
            )}
            <div className="member-top dropdown-animate">
              {viewer?.subscriber?.isPrincipal &&
                viewer?.planType === "Closed" && (
                  <button onClick={() => setPopup(true)}>+Add members</button>
                )}
            </div>
            {showCard && (
              <div>
                <div className="overlay"></div>
                <CardModal
                  popUp
                  setShowCard={setShowCard}
                  group={details}
                  viewer={viewer}
                  handlePay={handlePay}
                  planType={planType}
                  setShowdetails={setShowdetails}
                />
              </div>
            )}
            <div className="search-mobile">
              <Search />
            </div>
            {group.map((item, i) => (
              <div
                className="card-header-list"
                key={i}
                onClick={() => handleShowCard(item)}
              >
                <CardHeader
                  name={
                    item?.subscriber?.lastName +
                    " " +
                    item?.subscriber?.firstName
                  }
                  id={
                    item.subscriber.isPrincipal && item.planType === "Closed"
                      ? item.subscriber.policyNumber + "-admin"
                      : item.subscriber.policyNumber
                  }
                  subtitle="Paid"
                  subtext={item?.totalPaid || "-"}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="no-data">
            {loading ? (
              <div style={{ position: "absolute", top: "80%" }}>
                <Spin />
              </div>
            ) : (
              <>
                <img src={noMembersImg} alt="" />
                <h1 className="no-data-text">No Members</h1>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default GroupMemberMobileCard;
