import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import forgotPasswordImg from "../../assets/images/signUp_img.png";
import "./auth.scss";
import { RiLockPasswordFill } from "react-icons/ri";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Spin } from "antd";
import HeaderNav from "../../components/auth/Nav";
import avatar from "../../assets/images/avatar.png";
import { resetPassword } from "../../redux/authentication/reducer";

const ResetPassword = () => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [err, setErr] = useState("");
  const [formInput, setFormInput] = useState({
    confirmPassword: "",
    password: "",
  });
  const [focused, setFocused] = useState({
    confirmPassword: false,
    password: false,
  });
  const { error, loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const handleClick = (e) => {
    e.preventDefault();
    if (formInput.confirmPassword !== formInput.password) {
      setErr("Password must be the same");
    } else {
      dispatch(resetPassword(formInput));
      setFormInput({
        confirmPassword: "",
        password: "",
      });
      setErr("");
    }
    error && (window.location.href = "/login");
  };

  // Store form input in state
  const handleChange = ({ target: { name, value } }) => {
    setFormInput((prev) => {
      return { ...prev, [name]: value };
    });
  };

  // Handle input validations
  const handleFocus = (e) => {
    const rePassword = /(?=^.{8,}$)(?![.\n]).*$/;

    const { name } = e.target;
    if (formInput[name] === "") {
      setFocused(() => {
        focused[name] = true;
        return {
          ...focused,
        };
      });
    } else {
      setFocused(() => {
        focused[name] =
          (name === "password" && rePassword.test(formInput.password)) ||
          (name === "confirmPassword" &&
            rePassword.test(formInput.confirmPassword))
            ? false
            : true;
        return {
          ...focused,
        };
      });
    }
  };
  return (
    <section className="signUp-container">
      <HeaderNav />
      <div className="signUp-wrapper">
        <div className="signUp-left">
          <h1>End-to-End</h1>
          <h1>Transparency</h1>
          <p>Know what you have access to, and how you use it.</p>
          <p className="signUp-left-provider">Are you a provider? Click here</p>
          <img src={forgotPasswordImg} alt="" className="signIn-img" />
        </div>
        <div className="signUp-right">
          <div className="forgot-password-container">
            <img src={avatar} alt="" className="signUp-right-avater" />
            <h1 className="header-reset-password">Reset Password?</h1>
            {error && <span className="err-message">{error}</span>}
            <form className="form">
              <div className="form-bottom">
                <div className={focused.password && "err-box"}>
                  <RiLockPasswordFill className="icon" />
                  <input
                    type={!togglePassword ? "password" : "text"}
                    name="password"
                    onChange={handleChange}
                    onBlur={handleFocus}
                    placeholder="Password"
                    value={formInput.password}
                    minlength="8"
                    required
                  />
                  <i onClick={() => setTogglePassword(!togglePassword)}>
                    {!togglePassword ? (
                      <AiOutlineEyeInvisible
                        style={{ fontSize: "1.6rem", padding: "0px 4px" }}
                      />
                    ) : (
                      <AiOutlineEye
                        style={{ fontSize: "1.6rem", padding: "0px 4px" }}
                      />
                    )}
                  </i>
                </div>
                {focused.password && (
                  <span className="err-message-bottom">
                    Password should be at least 8 character.
                  </span>
                )}
                <div className={focused.confirmPassword && "err-box"}>
                  <RiLockPasswordFill className="icon" />
                  <input
                    type="text"
                    name="confirmPassword"
                    onChange={handleChange}
                    onBlur={handleFocus}
                    placeholder="Confirm Password"
                    value={formInput.confirmPassword}
                    minlength="8"
                    required
                  />
                </div>
                {focused.confirmPassword && (
                  <span className="err-message-bottom">
                    Password should be at least 8 character.
                  </span>
                )}
                {err !== "" && (
                  <span className="err-message-bottom">{err}</span>
                )}
              </div>

              <button
                loading={loading.toString()}
                className="btn-custom"
                onClick={handleClick}
              >
                {loading ? <Spin /> : "Submit"}
              </button>
              <p>
                <Link to="/login">
                  <span>Sign In</span>
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ResetPassword;
