import DashboardHeaderTop from "../general/DashboardHeaderTop";
import PersonalInfo from "./personalInfo/PersonalCard";
import { useState } from "react";
import HealthProvider from "./healthProvider/HealthProvider";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import SecurityProfileCard from "../../global/ProfileCard";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { BiArrowBack } from "react-icons/bi";
import Security from "./security/Security";
const settingMenu = ["Personal Information", "Health Provider", "Security"];
function Setting({ groupmemberId }) {
  const [update, setUpdate] = useState(false);
  const [toggle, setToggle] = useState(1);
  const [show, setShow] = useState(true);
  const handleToggle = (id) => {
    setToggle(id);
    setShow(false);
  };
  const width = useWindowWidth();
  return (
    <div>
      {!groupmemberId && <DashboardHeaderTop />}
      <div className="setting-wrapper">
        <div className="non-mobile setting">
          {width >= 981 && (
            <SecurityProfileCard
              update={setUpdate}
              type="subscriber"
              groupmemberId={groupmemberId}
            />
          )}
          <div>
            <div className="setting-nav">
              {settingMenu.map((item, i) => (
                <div
                  className={
                    toggle === i + 1 ? "focus setting-tab" : "setting-tab"
                  }
                  onClick={() => handleToggle(i + 1)}
                  key={i}
                >
                  {groupmemberId && item !== "Security" && <h3>{item}</h3>}
                  {!groupmemberId && <h3>{item}</h3>}
                </div>
              ))}
            </div>
            <div className="setting-container">
              {toggle === 1 && <PersonalInfo groupmemberId={groupmemberId} />}
              {toggle === 2 && <HealthProvider groupmemberId={groupmemberId} />}
              {toggle === 3 && <Security />}
            </div>
          </div>
        </div>

        {!update ? (
          <div className="mobile">
            <SecurityProfileCard
              update={setUpdate}
              type="subscriber"
              groupmemberId={groupmemberId}
            />
          </div>
        ) : (
          <>
            {show && (
              <div className="backNav" onClick={() => setUpdate(!update)}>
                <BiArrowBack fontSize="24px" />
                <h4>Back</h4>
              </div>
            )}
            <div className="setting-nav mobile">
              {show ? (
                <>
                  {settingMenu.map((item, i) => (
                    <div
                      className={
                        toggle === i + 1
                          ? "setting-tab-moblie focus"
                          : "setting-tab-mobile"
                      }
                      onClick={() => handleToggle(i + 1)}
                      key={i}
                    >
                      {groupmemberId && item !== "Security" && (
                        <>
                          <h3>{item}</h3>
                          <span>
                            <MdOutlineArrowForwardIos />
                          </span>
                        </>
                      )}
                      {!groupmemberId && (
                        <>
                          <h3>{item}</h3>
                          <span>
                            <MdOutlineArrowForwardIos />
                          </span>
                        </>
                      )}
                    </div>
                  ))}
                </>
              ) : (
                <div className="setting-container">
                  {toggle === 1 && (
                    <PersonalInfo
                      show={setShow}
                      groupmemberId={groupmemberId}
                    />
                  )}
                  {toggle === 2 && (
                    <HealthProvider
                      show={setShow}
                      groupmemberId={groupmemberId}
                    />
                  )}
                  {toggle === 3 && <Security show={setShow} />}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Setting;
