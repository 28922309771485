import { useState } from "react";
import Table from "../general/Table";
import SearchBox from "../general/SearchBox";
import Filter from "../general/Filter";
import { dataE } from "../general/ProfileCard/UserEcountersCard";
import AddBtn from "../general/buttons/AddBtn";
import ProviderCard from "./ProviderCard";
import { data } from "../AdminSubscription/AdminSubscription";
import BackNav from "../general/buttons/BackNav";
import DeleteAndDeactivateBtn from "../general/buttons/DeleteAndDeactivateBtn";
import ProviderDetailsCard from "./ProviderDetailsCard";
const dataP = {
  heading: [
    "S/N",
    "ID Number",
    "Name",
    "Phone/ Email",
    "No. of Subscribers",
    "No. of Encounters",
    "Status",
  ],
  list: [
    {
      num: "Hospital Name",
      id: "Sarah Lenon",
      name: "24, Anjorin, Lagos",
      pE: "Mary@gmail.com | 08011822922",
      noe: "12",
      nos: "12",
      stat: "Active",
    },
  ],
};
function AdminProviders() {
  const [detailsPage, setDetailsPage] = useState(false);
  const [show, setShow] = useState(1);
  return (
    <section>
      {!detailsPage ? (
        <>
          <div className="dashboard-top">
            <Filter />
            <SearchBox />
            <AddBtn text="Add Provider" />
          </div>
          <Table
            data={dataP}
            setDetailsPage={setDetailsPage}
            type="providers"
          />
        </>
      ) : (
        <div className="providerdetails">
          <div>
            <nav className="Details_navbar header-nav">
              <BackNav text="Health Nav" setDetailsPage={setDetailsPage} />
              <ul className="Details_nav">
                <li
                  className={show === 1 ? "focus" : ""}
                  onClick={() => setShow(1)}
                >
                  Personal Information
                </li>
                <li
                  className={show === 2 ? "focus" : ""}
                  onClick={() => setShow(2)}
                >
                  Subscribers
                </li>
                <li
                  className={show === 3 ? "focus" : ""}
                  onClick={() => setShow(3)}
                >
                  Encounters
                </li>
              </ul>
              <DeleteAndDeactivateBtn />
            </nav>
            <div className="provider-content">
              {show === 1 && <ProviderCard type="provider" />}
              {show === 1 && <ProviderDetailsCard />}
            </div>
            {show === 2 && <Table data={data} type="subscription" />}
            {show === 3 && <Table data={dataE} type="encounters" />}
          </div>
        </div>
      )}
    </section>
  );
}

export default AdminProviders;
