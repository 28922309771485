export const benefits = [
  {
    name: "Malaria",
    cohort0: "",
    cohort1: "TiTick",
    cohort2: "TiTick",
    cohort3: "TiTick",
    cohort4: "TiTick",
    cohort5: "TiTick",
    cohort6: "TiTick",
    // cohort7: "TiTick",
    // cohort8: "TiTick",
  },
  {
    name: "URTI/Acute Respiratory Tract Infection",
    cohort0: "",
    cohort1: "TiTick",
    cohort2: "TiTick",
    cohort3: "TiTick",
    cohort4: "TiTick",
    cohort5: "TiTick",
    cohort6: "TiTick",
    // cohort7: "TiTick",
    // cohort8: "TiTick",
  },
  {
    name: "Enteric fever",
    cohort0: "",
    cohort1: "TiTick",
    cohort2: "TiTick",
    cohort3: "TiTick",
    cohort4: "TiTick",
    cohort5: "TiTick",
    cohort6: "TiTick",
    // cohort7: "TiTick",
    // cohort8: "TiTick",
  },
  {
    name: "Gastroenteritis",
    cohort0: "",
    cohort1: "TiTick",
    cohort2: "TiTick",
    cohort3: "TiTick",
    cohort4: "TiTick",
    cohort5: "TiTick",
    cohort6: "TiTick",
    // cohort7: "TiTick",
    // cohort8: "TiTick",
  },
  {
    name: "Minor cuts and bruises",
    cohort0: "",
    cohort1: "TiTick",
    cohort2: "TiTick",
    cohort3: "TiTick",
    cohort4: "TiTick",
    cohort5: "TiTick",
    cohort6: "TiTick",
    // cohort7: "TiTick",
    // cohort8: "TiTick",
  },
  {
    name: "Out-Patient Care",
    cohort0: "",
    cohort1: "TiTick",
    cohort2: "TiTick",
    cohort3: "TiTick",
    cohort4: "TiTick",
    cohort5: "TiTick",
    cohort6: "TiTick",
    // cohort7: "TiTick",
    // cohort8: "TiTick",
  },
  {
    name: "Point of Care testing (MP, Urinalysis, RBS, PCV)",
    cohort0: "",
    cohort1: "TiTick",
    cohort2: "TiTick",
    cohort3: "TiTick",
    cohort4: "TiTick",
    cohort5: "TiTick",
    cohort6: "TiTick",
    // cohort7: "TiTick",
    // cohort8: "TiTick",
  },
  {
    name: "Antimalarial Drugs",
    cohort0: "",
    cohort1: "TiTick",
    cohort2: "TiTick",
    cohort3: "TiTick",
    cohort4: "TiTick",
    cohort5: "TiTick",
    cohort6: "TiTick",
    // cohort7: "TiTick",
    // cohort8: "TiTick",
  },
  {
    name: "Community Events and Webinar",

    cohort0: "TiTick",
    cohort1: "TiTick",
    cohort2: "TiTick",
    cohort3: "TiTick",
    cohort4: "TiTick",
    cohort5: "TiTick",
    cohort6: "TiTick",
    // cohort7: "TiTick",
    // cohort8: "TiTick",
  },
  {
    name: "Doctor-in-pocket = WhatsApp & Tele-consultations",
    cohort0: "TiTick",
    cohort1: "TiTick",
    cohort2: "TiTick",
    cohort3: "TiTick",
    cohort4: "TiTick",
    cohort5: "TiTick",
    cohort6: "TiTick",
    // cohort7: "TiTick",
    // cohort8: "TiTick",
  },
  {
    name: "Medications",
    cohort0: "",
    cohort1: "TiTick",
    cohort2: "TiTick",
    cohort3: "TiTick",
    cohort4: "TiTick",
    cohort5: "TiTick",
    cohort6: "TiTick",
    // cohort7: "TiTick",
    // cohort8: "TiTick",
  },
  {
    name: "GP consultation",
    cohort0: "",
    cohort1: "4 sessions per annum (T&C apply)",
    cohort2: "6 sessions per annum",
    cohort3: "6 sessions per annum",
    cohort4: "8 sessions per annum",
    cohort5: "10 sessions per annum",
    cohort6: "12 sessions per annum",
    // cohort7: "12 sessions per annum",
    // cohort8: "Unlimited",
  },
  {
    name: "Asthma/Sicklecell anemia",
    cohort0: "",
    cohort1: "(Nebulization/ Ventolin inhaler)",
    cohort2: "(N20,000)",
    cohort3: "(N30,000)",
    cohort4: "(N35,000)",
    cohort5: "(N40,000)",
    cohort6: "(N50,000)",
    // cohort7: "(N60,000)",
    // cohort8: "(N100,000)",
  },
  {
    name: "Inpatient care",
    cohort0: "",
    cohort1: "",
    cohort2: "(48 hrs)",
    cohort3: "(72 hrs)",
    cohort4: "(7 days)",
    cohort5: "(14 days)",
    cohort6: "(14 days)",
    // cohort7: "(14 days)",
    // cohort8: "(14 days)",
  },
  {
    name: "Urinary tract infection",
    cohort0: "",
    cohort1: "TiTick",
    cohort2: "TiTick",
    cohort3: "",
    cohort4: "",
    cohort5: "",
    cohort6: "",
    // cohort7: "",
    // cohort8: "",
  },
  {
    name: "Simple skin diseases such - T.Vesicolor, T.Capitas",
    cohort0: "",
    cohort1: "TiTick",
    cohort2: "TiTick",
    cohort3: "",
    cohort4: "",
    cohort5: "",
    cohort6: "",
    // cohort7: "",
    // cohort8: "",
  },
  {
    name: "Worm infestation (Helminthiasis)",
    cohort0: "",
    cohort1: "TiTick",
    cohort2: "TiTick",
    cohort3: "",
    cohort4: "",
    cohort5: "",
    cohort6: "",
    // cohort7: "",
    // cohort8: "",
  },
  {
    name: "Dog bite, Snake bite, Scorpion sting",
    cohort0: "",
    cohort1: "TiTick",
    cohort2: "TiTick",
    cohort3: "",
    cohort4: "",
    cohort5: "",
    cohort6: "",
    // cohort7: "",
    // cohort8: "",
  },
  {
    name: "Other uncomplicated bacterial, fungal, parasitic and viral illnesses",
    cohort0: "",
    cohort1: "",
    cohort2: "TiTick",
    cohort3: "",
    cohort4: "",
    cohort5: "",
    cohort6: "",
    // cohort7: "",
    // cohort8: "",
  },
  {
    name: "Management of acute PID and vaginosis",
    cohort0: "",
    cohort1: "",
    cohort2: "TiTick",
    cohort3: "",
    cohort4: "",
    cohort5: "",
    cohort6: "",
    // cohort7: "",
    // cohort8: "",
  },
  {
    name: "Specialist consultations",
    cohort0: "",
    cohort1: "",
    cohort2: "",
    cohort3: "(4 sessions per annum)",
    cohort4: "(6 sessions per annum)",
    cohort5: "(8 sessions per annum)",
    cohort6: "(10 sessions per annum)",
    // cohort7: "(12 sessions per annum)",
    // cohort8: "(12 sessions per annum)",
  },
  {
    name: "Minor procedures",
    cohort0: "",
    cohort1: "",
    cohort2: "",
    cohort3: "TiTick",
    cohort4: "TiTick",
    cohort5: "TiTick",
    cohort6: "TiTick",
    // cohort7: "TiTick",
    // cohort8: "TiTick",
  },
  {
    name: "HIV/AIDS(at govt accredited centre)",
    cohort0: "",
    cohort1: "",
    cohort2: "",
    cohort3: "TiTick",
    cohort4: "TiTick",
    cohort5: "TiTick",
    cohort6: "TiTick",
    // cohort7: "TiTick",
    // cohort8: "TiTick",
  },
  {
    name: "Intermediate procedures",
    cohort0: "",
    cohort1: "",
    cohort2: "",
    cohort3: "",
    cohort4: "TiTick",
    cohort5: "TiTick",
    cohort6: "TiTick",
    // cohort7: "TiTick",
    // cohort8: "TiTick",
  },
  {
    name: "Imaging/radiology",
    cohort0: "",
    cohort1: "",
    cohort2: "",
    cohort3: "",
    cohort4: "(N20,000)",
    cohort5: "(N20,000)",
    cohort6: "(N20,000)",
    // cohort7: "(N20,000)",
    // cohort8: "(N20,000)",
  },
  {
    name: "Hypertension",
    cohort0: "",
    cohort1: "",
    cohort2: "",
    cohort3: "",
    cohort4: "",
    cohort5: "TiTick",
    cohort6: "TiTick",
    // cohort7: "TiTick",
    // cohort8: "TiTick",
  },
  {
    name: "Pharmacy benefits",
    cohort0: "",
    cohort1: "",
    cohort2: "",
    cohort3: "",
    cohort4: "",
    cohort5: "(N2,000/month)",
    cohort6: "(N3,000/month)",
    // cohort7: "(N5,000/month)",
    // cohort8: "(N6,500/month)",
  },
  {
    name: "Physiotheraphy",
    cohort0: "",
    cohort1: "",
    cohort2: "",
    cohort3: "",
    cohort4: "",
    cohort5: "(5 sessions per annum)",
    cohort6: "(7 sessions per annum)",
    // cohort7: "(10 sessions per annum)",
    // cohort8: "(12 sessions per annum)",
  },
  {
    name: "Optical/Dental",
    cohort0: "",
    cohort1: "",
    cohort2: "",
    cohort3: "",
    cohort4: "",
    cohort5: "(N15,000)",
    cohort6: "(N20,000)",
    // cohort7: "(N35,000)",
    // cohort8: "(N40,000)",
  },
  {
    name: "Chronic Conditions limit",
    cohort0: "",
    cohort1: "",
    cohort2: "",
    cohort3: "",
    cohort4: "",
    cohort5: "(N40,000 per annum)",
    cohort6: "(N75,000 per annum)",
    // cohort7: "(N100,000 per annum)",
    // cohort8: "(N120,000 per annum)",
  },
  {
    name: "Dietician",
    cohort0: "",
    cohort1: "",
    cohort2: "",
    cohort3: "",
    cohort4: "",
    cohort5: "",
    cohort6: "TiTick",
    // cohort7: "TiTick",
    // cohort8: "TiTick",
  },
  {
    name: "Gym",
    cohort0: "",
    cohort1: "",
    cohort2: "",
    cohort3: "",
    cohort4: "",
    cohort5: "",
    cohort6: "(2 months)",
    // cohort7: "(3 months)",
    // cohort8: "(6 months)",
  },
  {
    name: "Major procedures",
    cohort0: "",
    cohort1: "",
    cohort2: "",
    cohort3: "",
    cohort4: "",
    cohort5: "",
    cohort6: "TiTick",
    // cohort7: "TiTick",
    // cohort8: "TiTick",
  },
  {
    name: "Prostate/Cervical Care",
    cohort0: "",
    cohort1: "",
    cohort2: "",
    cohort3: "",
    cohort4: "",
    cohort5: "",
    cohort6: "",
    // cohort7: "TiTick",
    // cohort8: "TiTick",
  },
];

export const benefits1 = [
  {
    name: "Paediatric Cohort",
    price: "N6,500",
    select: "select",
    1: "An infant to a registered mother is covered for a period of 12 weeks after delivery ",
    2: "Health education, growth monitoring, nutritional advice and routine immunization",
    3: "Management of childhood vaccine preventable illnesses",
    4: "Malaria and other acute febrile illnesses ",
    5: "Other uncomplicated bacterial, fungal, parasitic and viral illnesses",
    6: "Acute respiratory tract infections",
    7: "Urinary tract infections",
    8: "Simple skin diseases ",
    9: "",
    10: "",
    11: "",
    12: "",
    13: "",
    14: "",
  },
];
