import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import React, { useEffect } from "react";
// import logo from "../../../assets/images/profileLogo.png";
// import back from "../../../assets/images/profileBack.png";
import logo from "../../../assets/images/cardlogoHeader.png";
import back from "../../../assets/images/cardlogo.png";
import grid from "../../../assets/images/grid.png";
import "./PrintEcard.scss";
import useWindowWidth from "../../../hooks/useWindowWidth";
const PrintEcard = ({ setPrint, print }) => {
  const componentRef = React.useRef();
  const { subscription } = useSelector((state) => state.subscriber);
  const {
    subscriber: { user, policyNumber, profileUrl, sex },
  } = subscription;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const width = useWindowWidth();
  useEffect(() => {
    width <= 600 && handlePrint();
    width <= 600 && setPrint(false);
  }, [handlePrint, setPrint, width]);

  return (
    <div className="printCard">
      <div ref={componentRef}>
        <div className="profileUserContainer">
          <div className="profileUserDetials">
            <div className="block"></div>
            <img className="profileLogo" src={logo} alt="Hadiel-logo" />
            <div>
              <p className="detailsSpace">
                <span>ID Number:</span>
                <span>{policyNumber}</span>
              </p>
              <p className="detailsSpace">
                <span>Gender:</span>
                <span>{sex}</span>
              </p>
              <p className="detailsSpace">
                <span>Phone: </span>
                <span>{user.phone}</span>
              </p>
              <p className="detailsSpace">
                <span>Email:</span>
                <span>{user.email}</span>
              </p>
              <p className="detailsSpace">
                <span>Plan:</span>
                <span>{subscription.planName}</span>
              </p>
              <p className="detailsSpace">
                <span>Provider: </span>
                <span>
                  {subscription?.subscriber?.primaryCareProvider?.name}
                </span>
              </p>
            </div>
          </div>
          <div className="profileUserPic">
            <div className="picFrame">
              <img src={profileUrl} alt="Profile" />
            </div>
            <h1 className="nameTag">{user.username}</h1>
          </div>
        </div>
        <div className="ecardBackContainer">
          <div className="ecardBack">
            <img src={grid} alt="grid" />
            <img className="cardBack" src={back} alt="profile-logo" />
            <p className="web">www.hadiel.com.ng</p>
          </div>
        </div>
      </div>
      <div className="printBtn">
        <button onClick={() => setPrint(false)}>Cancel</button>
        <button onClick={handlePrint}>Print as PDF</button>
      </div>
    </div>
  );
};
export default PrintEcard;
